import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { Message } from '@arco-design/web-vue';

// localStorage.setItem('arco-locale', 'zh-CN'); // 需要改
export default function useLocale() {
  const i18 = useI18n();
  const router = useRouter()
  const currentLocale = computed(() => {
    return i18.locale.value;
  });
  const changeLocale = (value: string) => {
    i18.locale.value = value;
    localStorage.setItem('arco-locale', value);
    Message.success(i18.t('navbar.action.locale'));
    router.go(0)
  };
  return {
    currentLocale,
    changeLocale,
  };
}
