import axios from 'axios';
import qs from 'qs';

// 是否需要人机验证
export function checkCaptcha() {
  return axios.get('/auth/risk/check-captcha');
}
const clientId = 'web.wallet';
const clientSecret = 'rgcmYxq68wgafn7xsiePUkfqGvtBkCQw';
// 登录
export function login(params: any, data: any) {
  const query = `?method=${params.method}&response_type=id_token&scope=server&grant_type=password`;
  return axios.post(`/auth/oauth2/token${query}`, qs.stringify(data), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

// 最后和双因素验证方式
export function verification() {
  return axios.get('/auth/two-factor/verification-methods');
}

// 发送验证码
export function sendCode(data: object) {
  return axios.post('/auth/two-factor/sendCode', qs.stringify(data), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

// 双因素验证端口
export function verificationLogin(params: any, data: any) {
  const query = `?method=${params.method}&session_id=${params.sessionId}&response_type=id_token&scope=server&grant_type=upgrade`;
  return axios.post(`/auth/oauth2/token${query}`, qs.stringify(data), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}
// 扫码登录端口
export function scanLogin(data: any) {
  const query = `?scope=server&grant_type=authorization_code`;
  return axios.post(`/auth/oauth2/token${query}`, qs.stringify(data), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

// 直接进入端口
// export function trustEntry() {
//   axios.interceptors.request.use(
//   (config: any) => {
//       config.headers.Authorization = `Basic ${  btoa(`${clientId  }:${  clientSecret}`)}`;
//       config.headers['Content-Type'] =  'application/x-www-form-urlencoded'
//     return config;
//   }
// );
//   const query = `?response_type=id_token&scope=server&grant_type=refresh_token`
//   return axios.post(`/auth/oauth2/token${query}`);
// }

// 发送验证码-注册
export function sendCodeRegister(data: object) {
  return axios.post('/uc/account/verification/public/sendCode', data);
}
// 注册
export function userRegister(data: object) {
  return axios.post('/uc/auth/user_register', data);
}

// 忘记密码-第一步
export function preCheck(data: object) {
  return axios.post('/uc/security/resetpwd/precheck', data);
}
// 忘记密码-获取验证码
export function forgetSendCode(data: object) {
  return axios.post('/uc/account/verification/public/sendCode', data);
}
// 忘记密码-安全验证
export function forgetPwdCodeValidation(data: object) {
  return axios.post('/uc/security/forgetPwdCodeValidation', data);
}
// 忘记密码-重置密码
export function resetPwd(data: object) {
  return axios.post('/uc/customer/resetPwd', data);
}
// 扫码登陆 qrCode
export function getQrCode(data: object) {
  return axios.post('/auth/code/get', data);
}
// 扫码登陆 状态
export function getScanCode(scanCode: any) {
  return axios.get(`/auth/code/status/${scanCode}`);
}

// 获取支持的国家
export function getCountries() {
  return axios.get(`uc/common/country/list`);
}
