<template>
  <div id="pagesBox">
    <a-pagination
      :current="pages"
      :total="totalAll"
      :page-size="pageSize"
      @change="handleChangeCuller"
    />
  </div>
</template>

<script lang="ts" setup>
  import { ref } from 'vue';

  const props = defineProps({
    pages: {
      type: Number,
      default: 1,
    },
    totalAll: {
      type: Number,
      default: 20,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
  });

  const emits = defineEmits(['changeCuller']);
  const handleChangeCuller = (curr: number) => {
    emits('changeCuller', curr);
  };
</script>

<style scoped lang="less">
  #pagesBox {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: flex-end;
    margin: 36px 12px;
  }
</style>
