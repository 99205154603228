export default {
  'home.coin': '加密資產 盡在掌控',
  'home.wallet': '加密貨幣錢包/幣趣支付/一鍵買幣',
  'home.there': '盡在',
  'home.Basic': 'BasicEx 幣趣',
  'home.userName': '電子郵箱/手機號碼註冊',
  'home.down': '立即下載',
  'home.free': '免費',
  'home.collection': '收付款',
  'home.frees': '0手續費',
  'home.sell': '購買/出售BCNY，方便快捷。',
  'home.pay': '幣趣用戶轉帳/收款，均無需繳納任何費用。',
  'home.register': '註冊錢包',
  'home.payment': '幣趣支付',
  'home.introduce': '介紹',
  'home.tip1':
    '幣趣支付是幣趣推出的一種無接觸、無國界的加密貨幣支付系統，安全可靠，值得信賴。',
  'home.tip2':
    '為您的公司開啟支付服務，以USDT和其他主流幣種開展線上和線下銷售。',
  'home.see': '查看更多',
  'home.basicWallet': 'BasicEx 幣趣錢包',
  'home.chain': '多鏈錢包',
  'home.manage': '輕鬆管理多鏈貨幣資產',
  'home.interface': '支付金流',
  'home.api': '全平台API/SDK智慧化支付金流',
  'home.C2C': 'C2C市場',
  'home.buy': '一鍵買/賣加密貨幣資產',
  'home.help': '需要幫助',
  'home.chat1': '24/7聊天支援',
  'home.chat2': '獲取24/7的聊天支援，友好的客服代表竭誠為您提供服務。',
  'home.chat3': '線上聊天',
  'home.chat4': '常見問題',
  'home.chat5': '查看常見問題，獲取特定功能的詳細說明。',
  'home.chat6': '瞭解更多',
  // footer
  'footer.about.text1': '關於我們',
  'footer.about.text2': '職業機會',
  'footer.about.text3': '商務聯絡',
  'footer.about.text4': '服務協議',
  'footer.about.text5': '隱私說明',
  'footer.about.text6': '風險提示',
  'footer.about.text7': '公告中心',
  'footer.about.text8': 'Cookie偏好設定',
  'footer.product.text1': 'C2C交易',
  'footer.service.text1': '成為商戶',
  'footer.service.text2': '承兌商加盟',
  'footer.service.text3': '代理推廣',
  'footer.help.text1': '幫助中心',
  'footer.help.text2': '線上客服',
  'footer.help.text3': '建議與反饋',
  'footer.help.text4': '幣趣法務',
  'footer.self.text1': '重置密碼',
  'footer.self.text2': '解禁帳戶',
  'footer.self.text3': '重置手機驗證',
  'footer.self.text4': '修改郵箱',
  'footer.self.text5': '重置 Google authenticator',
  'footer.self.text6': '儲值未到賬',
  'footer.self.text7': '請求删除帳戶',
  'footer.product.text': '產品',
  'footer.service.text': '服務',
  'footer.help.text': '幫助',
  'footer.self.text': '自助服務',
  'down.down1': '隨時隨地開啟',
  'down.down2': '交易',
  'down.down3': '下載幣趣客戶端，所有交易盡在手中。',
  'down.down4': 'iOS內測版',
  'down.down4-1': '安裝內測版，需先',
  'down.down5': '下載自Android',
  'down.down6': '下載自Google Play',
  'down.down7': '谷歌',
  'down.down8': '驗證器',
  'down.down9':
    '谷歌驗證器會為您生成兩步驗證碼。借助兩步驗證，讓您的幣趣帳戶更加安全。',
  'down.down10': '下載自App Store',
  'down.down11': '下載自App Store',
  'down.down12': '下載自Google Play',
  'down.down13': 'iOS和Android掃碼下載',
  'down.footerText1': '美國MSB執照號碼',
};
