import { createI18n } from 'vue-i18n';
import en from './en-US';
import cn from './zh-CN';
import tw from './zh-TW';

export const LOCALE_OPTIONS = [
  { label: 'English', value: 'en' },
  { label: '繁體中文', value: 'zh-TW' },
  { label: '简体中文', value: 'zh-CN' },
];

const htmlElement = document.getElementsByTagName('html')[0] || 'zh-CN';
if (!localStorage.getItem('arco-locale')) {
  localStorage.setItem('arco-locale', htmlElement.lang);
}

const defaultLocale = localStorage.getItem('arco-locale') || htmlElement.lang;
const i18n = createI18n({
  locale: defaultLocale,
  fallbackLocale: 'zh-TW',
  allowComposition: true,
  messages: {
    'en': en,
    'zh-CN': cn,
    'zh-TW': tw,
  },
});

export default i18n;
