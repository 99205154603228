import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';

const DASHBOARD: AppRouteRecordRaw = {
  path: '/overview',
  name: 'Overview',
  component: DEFAULT_LAYOUT,
  redirect: '/overview/index',
  meta: {
    locale: 'navbar.index.xr75kp',
    requiresAuth: true,
    icon: 'icon-overview',
    order: -1,
    hideInMenu: false,
    activeMenu: 'Overview',
    title: '總覽',
  },
  children: [
    {
      path: 'index',
      name: 'Index',
      component: () => import('@/views/dashboard/overview/index.vue'),
      meta: {
        locale: 'menu.overview',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    // {
    //   path: 'recharge',
    //   name: 'Recharge',
    //   component: () => import('@/views/dashboard/overview/recharge.vue'),
    //   meta: {
    //     locale: 'menu.overview',
    //     requiresAuth: true,
    //     roles: ['*'],
    //     hideInMenu: true,
    //   },
    // },
  ],
};

export default DASHBOARD;
