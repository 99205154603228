import { defineStore } from 'pinia';
import {
  logout as userLogout,
  LoginData,
  loginRRe,
  personalInfor,
  getVerificationMethodApi,
} from '@/api/user';
import {
  login as userLogin,
  verificationLogin as verification,
  scanLogin,
} from '@/api/login';
import { setToken, clearToken } from '@/utils/auth';
import { removeRouteListener } from '@/utils/route-listener';
import { useCookies } from 'vue3-cookies';
import { UserState } from './types';
import useAppStore from '../app';

const { cookies } = useCookies();
const useUserStore = defineStore('user', {
  state: (): UserState => ({
    whiteListSafe: undefined,
    isQuickCoin: undefined,
    isWhiteList: undefined,
    antiFishingCode: undefined,
    avatar: undefined,
    email: undefined,
    id: undefined,
    idNumber: undefined,
    isNotification: undefined,
    isFirst: undefined,
    isSetPing: undefined,
    isForbiddenPin: undefined,
    loginTime: undefined,
    nickName: undefined,
    phone: undefined,
    realName: undefined,
    realNameStatus: undefined,
    status: undefined,
    transStatus: undefined,
    userCode: undefined,
    userLanguage: undefined,
    userName: undefined,
    userType: undefined,
    methods: [],
    role: undefined,
  }),

  getters: {
    userInfo(state: UserState): UserState {
      return { ...state };
    },
  },

  actions: {
    setInfo(partial: Partial<UserState>) {
      this.$patch(partial);
    },

    // Reset user's information
    resetInfo() {
      this.$reset();
    },

    // Get user's information
    async info() {
      try {
        const { data } = await personalInfor();
        this.setInfo(data);
      } catch (err) {
        // throw err;
      }
    },

    // Login
    async login(loginForm: any, params: any) {
      try {
        const res = (await userLogin(loginForm, params)) as any;
        if (res.access_token) {
          // 这里设置token
          const expiresTime = '11h';
          setToken(res.access_token, expiresTime);
        } else {
          clearToken();
        }
        return res;
      } catch (err) {
        clearToken();
        throw err;
      }
    },
    async loginVer(loginForm: any, params: any) {
      try {
        const res = (await verification(loginForm, params)) as any;
        if (res.access_token) {
          // 这里设置token
          const expiresTime = '11h';
          setToken(res.access_token, expiresTime);
        } else {
          clearToken();
        }
        return res;
      } catch (err) {
        clearToken();
        throw err;
      }
    },
    async scanLoginVer(params: any) {
      try {
        const res = (await scanLogin(params)) as any;
        if (res.access_token) {
          // 这里设置token
          const expiresTime = '11h';
          setToken(res.access_token, expiresTime);
        } else {
          clearToken();
        }
        return res;
      } catch (err) {
        clearToken();
        throw err;
      }
    },
    logoutCallBack() {
      const appStore = useAppStore();
      this.resetInfo();
      clearToken();
      removeRouteListener();
      appStore.clearServerMenu();
    },
    // Logout
    async logout() {
      try {
        await userLogout();
      } finally {
        this.logoutCallBack();
      }
    },
  },
});

export default useUserStore;
