export default {
  'overview.index.y55nvv': '您尚未通过身份认证!',
  'overview.index.vm3ll4': '立即认证',
  'overview.index.7630kj': '恭喜您，已经通过初级身份认证!',
  'overview.index.6gv796': '恭喜您，已经通过高级身份认证!',
  'overview.index.w42owo': '总资产估值',
  'overview.index.nbd725':
    '您的账户下目前没有任何资产，请先完成身份认证然后再为账户充值。',
  'overview.index.6b7683': '您的账户下目前没有任何资产，请先充值。',
  'overview.index.49nwm5': '买币',
  'overview.index.d48b5i': '一键购买加密货币',
  'overview.index.1850v5': '快捷区/自选区任意选择',
  'overview.index.9t7e25': '立即购买',
  'overview.index.fd5w2s': '充值',
  'overview.index.r376u3': '已持有货币充值到币趣',
  'overview.index.66x2y5': '从外部钱包提币至币趣',
  'overview.index.d0et7a': '立即充值',
  'overview.index.y774k3': '收款',
  'overview.index.2yn505': '从其他币趣账户接收转账',
  'overview.index.8ybxdp': '0手续费收/付款',
  'overview.index.b46qtd': '立即收款',
  'components.assets-value.18yr1j': ' USDT 冻结金额:',
  'components.assets-value.2b3517': '可用余额:',
  'components.assets-value.8478m6': '转账',
  'components.assets-value.687r4q': '提现',
  'components.assets-value.w22527': '日',
  'components.assets-value.ge43pz':
    '我们将在每日的香港时间16点之前更新资产数据。',
  'components.assets-value.zghh33': '收入',
  'components.assets-value.a1dr9x': '支出',
  'components.assets-value.ly1k52': '冻结金额包含待结算金额与系统冻结金额',
  'components.assets-value.j70i73': '向币趣用户发送加密货币，享0手续费',
  'components.assets-value.jj3hg4': '收入',
  'components.assets-value.i6j148': '支出',
  'components.header-info.12t323': '币趣ID',
  'components.header-info.um3476': '用户类型',
  'components.header-info.4m03sm': '上次登录时间',
  'components.header-info.3787t3':
    '请注意：昵称仅支持更改一次，请在更改前务必仔细确认！',
  'components.header-info.pj4n5u': '未认证',
  'components.header-info.zqw278': '初级认证',
  'components.header-info.jd9962': '高级认证',
  'components.header-info.43h887': '个人用户',
  'components.header-info.yqi9ko': '企业用户',
  'components.header-info.e2tp8h': '编辑昵称',
  'components.header-info.1f7mrx': '为您的个人资料设置自定义昵称。',
  'components.header-info.5noe2d': '昵称',
  'components.header-info.p63d76': '请输入您要修改的昵称',
  'components.header-info.xwpc25': '昵称不能为空',
  'components.revence-data.9713y6': '收支详情',
  'components.revence-data.npd4wy': '更多',
  'components.revence-data.ce4131': '提币申请已提交',
  'components.revence-data.9752t3': '系统处理中',
  'components.revence-data.62xf33': '已完成',
  'components.revence-data.2iv5ux': '状态',
  'components.revence-data.3y5g5i': '币种',
  'components.revence-data.4wmme1': '提现金额',
  'components.revence-data.tyfp3z': '手续费',
  'components.revence-data.s8967j': '转账网络',
  'components.revence-data.do87js': '可用余额',
  'components.revence-data.q5vlq3': '冻结金额',
  'components.revence-data.46f9tr': '地址',
  'components.revence-data.tle9fw':
    '请注意，我们会在提币完成时给你发送邮件通知，为什么我的提现',
  'components.revence-data.zfuugj': '还没到账？',
  'components.revence-data.rfkndq': '金额',
  'components.revence-data.61xy2u': '从',
  'components.revence-data.354789': '至',
  'components.revence-data.2uv9jf': '创建时间',
  'components.revence-data.rfyt9g': '订单编号',
  'components.revence-data.3hb0uk': '备注',
  'components.revence-data.35h0kp': '成功',
  'components.revence-data.7h5jsb': '时间',
  'components.revence-data.j8k864': '充值数量',
  'components.revence-data.543v48': '处理中',
  'components.revence-data.5s4r08': '审批中',
  'components.revence-data.dyxzu8': '已取消',
  'components.revence-data.868ebs': '失败',
  'components.revence-data.ltoesk': '待付款',
  'components.revence-data.g1qbzi': '已付款，待确认',
  'components.revence-data.kq9g21': '申诉中',
  'components.revence-data.jfjmj4': '暂无数据',
  'components.revence-data.268t86': '充值详情',
  'components.revence-data.k51q8g': '提现详情',
  'components.revence-data.4l1jq3': '转账详情',
  'components.revence-data.9sc1cn': '类型',
  'components.revence-data.hpr4p5': '数量',
  'accaction.index.87m503': '账户安全',
  'accaction.index.5565bk': '近期账户活动',
  'accaction.index.p55715': '账户异常？',
  'accaction.index.v7gjvv': '禁用账户',
  'accaction.index.y3gm71': '登录活动',
  'accaction.index.ey2758': '安全操作记录',
  'accaction.index.qznrs3': '来源',
  'accaction.index.75e7l9': 'IP地址',
  'address.batchaddaddress.6b7h2u': '批量添加地址',
  'address.batchaddaddress.lx0837': '添加',
  'address.batchaddaddress.5g5at6': '取消',
  'address.batchaddaddress.0c092d': '保存',
  'address.batchaddaddress.7u2hjf': '您填写的地址尚未保存，是否要取消保存。',
  'address.batchaddaddress.s47gk6': '继续编辑',
  'address.batchaddaddress.s319i3': '地址备注',
  'address.batchaddaddress.kttf6p': '地址来源',
  'address.batchaddaddress.qe79l9': '操作',
  'address.batchaddaddress.8313nb': '无需填写',
  'address.index.6p7di6': '地址管理',
  'address.index.1n20p9': '白名单限制已关闭',
  'address.index.59zoxy': '白名单设置',
  'address.index.af4395': '全部',
  'address.index.tf8k5s': '白名单',
  'address.index.pqk179': '搜索',
  'address.index.jrqg1h': '批量导出',
  'address.index.xih4ay': '添加地址',
  'address.index.t6l0wx': '已选中',
  'address.index.o0w7e4': '个地址，您希望：',
  'address.index.39qj58': '加入白名单',
  'address.index.83dxt5': '移除白名单',
  'address.index.s01fov': '删除',
  'address.index.80651m': '添加提现地址',
  'address.index.86kz67': '保存为通用地址，可以适用于多个币种提现',
  'address.index.vwcg4v': '白名单地址',
  'address.index.n6u1h8':
    '开启白名单功能后，您将只能向白名单地址列表中的地址提币。',
  'address.index.5tmj7t': '地址搜索',
  'address.index.u5b9jk': '非白名单地址',
  'address.index.x4cqzf': '不能为空！',
  'address.index.2k9010': '请输入4-20个字符的备注，例如：小明的币趣账户',
  'address.index.eii4u3': '提币地址',
  'address.index.bdqqx0': '请输入提币地址',
  'address.index.871rhx':
    '开启白名单后将限制您的账户只能向白名单地址提币，从而有助于保护您的资产安全。',
  'address.index.31c20i': '您确定要删除该地址吗？',
  'address.index.7uq2y9': '地址簿',
  'address.index.r539eg': '转账网络不能为空',
  'address.index.kw2845': '通用地址',
  'address.index.fp19tw': '普通地址',
  'changepassword.index.u8w3oi': '修改密码',
  'changepassword.index.8473bq': '提交',
  'changepassword.index.6qts82': '密码修改成功',
  'changepassword.index.7bh14e': '您的密码已修改，请使用新密码登录',
  'changepassword.index.awho4e': '登录',
  'changepassword.index.157jed': '旧密码',
  'changepassword.index.2rye5l': '请输入',
  'changepassword.index.7yf04o': '新密码',
  'changepassword.index.51j37z': '确认新密码',
  'changepassword.index.6382f8':
    '密码需要8位以上字符组成,必须包含数字及字母大小写',
  'changepassword.index.o2u857': '两次密码不一致',
  'changepassword.index.71mx3u': '密码错误!',
  'device.index.338736': '设备:',
  'device.index.6f1n27': '设备管理',
  'device.index.b30ayt': '这些设备已被授权允许登录您的账户',
  'device.index.r0pnkc': '设备',
  'device.index.3o1ncj': '登录所在地',
  'disableacc.index.i6lc27': '账户活动',
  'disableacc.index.e6oy6i': '禁用您的账户将导致以下结果：',
  'disableacc.index.o45g18':
    '如果您决定重新激活您的账户，请在禁用两个小时后登录账户进行解禁流程',
  'disableacc.index.u61qfr': '删除此账户',
  'disableacc.index.t3d7tt': '确认要删除该账户吗?',
  'disableacc.index.sk863i': '所有交易和登录功能都将禁用',
  'disableacc.index.893l4o': '删除所有信任设备',
  'disableacc.index.56bm0l': '所有待处理的提现将被取消',
  'disableacc.index.n4932t': '所有的当前委托都将被取消',
  'disableacc.index.803x4k': '已验证的身份信息不会被删除',
  'email.bindemail.6oti7n': '绑定邮箱验证',
  'email.bindemail.77it3v': '获取验证码',
  'email.bindemail.n0705g': 's 后获取',
  'email.bindemail.2k8gfj': '绑定邮箱验证码',
  'email.bindemail.6275ov': '新邮箱',
  'email.bindemail.vx440f': '邮箱号不能为空!',
  'email.bindemail.4r361n': '请输入新邮箱号码',
  'email.bindemail.5y253x': '邮箱验证码',
  'email.bindemail.d7l1pb': '邮箱验证码不能为空！',
  'email.bindemail.q0840y': '请输入新邮箱验证码',
  'email.changeemail.rm3mkt': '更改邮箱验证',
  'email.changeemail.sui27i': '更改邮箱验证码',
  'email.changeemail.fpep15': '新邮箱验证码',
  'email.unbindemail.igv777': '解绑邮箱验证',
  'email.unbindemail.1467zm': '安全验证',
  'email.unbindemail.6un89v': '返回',
  'fish.changefish.09en1y': '更改防钓鱼码',
  'fish.changefish.1f4oi3': '钓鱼码会出现在哪？',
  'fish.changefish.x47u89': '请输入 4-20 个字符，不包括特殊符号。',
  'fish.changefish.dteu66': '确定',
  'fish.changefish.x30e85':
    '不要将您的密码、谷歌或短信验证码告诉任何人，包括币趣客服。',
  'fish.changefish.545pu5': '旧防钓鱼码',
  'fish.changefish.lkyri4': '新防钓鱼码',
  'fish.changefish.bry0ys': '不能包含中文和特殊字符！',
  'fish.changefish.go8188': '需要4-20位以上字符组成',
  'fish.setfinsh.61569g': '设置防钓鱼码',
  'fish.setfinsh.o8230x': '什么是防钓鱼码？',
  'fish.setfinsh.6t45bq':
    '防钓鱼码是你自己设置的一串字符，能够帮助你识别防冒币趣的网站或者邮件',
  'fish.setfinsh.7cf26c': '防钓鱼码会出现在哪？',
  'fish.setfinsh.1rske9':
    '设置好防钓鱼码后，每一封币趣发给您的邮件都会带有这串字符。',
  'fish.setfinsh.29c19t': '创建防钓鱼码',
  'fish.setfinsh.bk3ekb': '防钓鱼码',
  'components.alert.172xn1':
    '为了保护您的资产安全，更改或解绑验证后该账户将禁用支付服务、提币和C2C卖币24小时。',
  'google.bindgoogle.bj1319': '绑定谷歌验证',
  'google.bindgoogle.t70ckm': '下载并安装身份验证器App',
  'google.bindgoogle.1c6pys': '如何使用谷歌身份验证器?',
  'google.bindgoogle.y8n99f': '下一步',
  'google.bindgoogle.7v5exp': '用谷歌验证App扫描这个二维码',
  'google.bindgoogle.orfs9q':
    '如果您无法扫描这个二维码，请在App中手动输入这串字符',
  'google.bindgoogle.cl439i': '上一步',
  'google.bindgoogle.yvvt3q': '将密钥备份后保存在安全位置',
  'google.bindgoogle.l8ru6j':
    '若您丢失了手机，此密钥可用来恢复您的身份验证器。',
  'google.bindgoogle.7vi621': '否则，客服帮您重置币趣/谷歌验证器至少需要7天。',
  'google.bindgoogle.p80e6s': '绑定谷歌验证器前需要验证您的账户',
  'google.bindgoogle.255n21': '请输入谷歌验证器的6位验证码',
  'google.bindgoogle.l4i130': '身份验证器已更改',
  'google.bindgoogle.32j9s6': '您现在可以使用新的身份验证器登录。',
  'google.bindgoogle.k8240u': '币趣/谷歌验证码',
  'google.bindgoogle.5wml8v': '下载App',
  'google.bindgoogle.35sm5m': '扫描新二维码',
  'google.bindgoogle.4z51qi': '备份新密钥',
  'google.bindgoogle.55623i': '启用新身份验证器',
  'google.bindgoogle.5nl6v0': '验证码必须为6位数字',
  'google.changegoogle.bp2ib0': '更改谷歌身份验证器',
  'google.changegoogle.0150qu': '解绑旧身份验证器前需要验证您的账户',
  'google.changegoogle.892net': '解绑旧身份验证器',
  'google.unbindgoogle.067sm6': '解绑旧谷歌验证器',
  'phone.bindphone.6y56ed': '绑定手机验证',
  'phone.bindphone.14257g': '请输入短信验证码',
  'phone.bindphone.rgfl35': '手机号',
  'phone.bindphone.cjdlml': '请输入手机号',
  'phone.bindphone.xel50u': '手机验证码',
  'phone.bindphone.3c27v0': '手机号不能为空!',
  'phone.changephone.12r3e5': '更改手机验证',
  'phone.changephone.e5x5v7':
    '为了保护您的资产安全，更改或解绑验证后该账户将禁用支付服务、提币和C2C卖币24 小时。',
  'phone.changephone.7wjyni': '请输入发送到',
  'phone.changephone.780e3h': '的6位验证码',
  'phone.changephone.k33121': '更改手机验证码',
  'phone.changephone.r34o47': '新手机号',
  'phone.changephone.4end8x': '手机号不可为空！',
  'phone.unbindphone.4yg1w8': '解绑手机验证',
  'reset.index.6089f8': '重置安全验证',
  'reset.index.li0671': '请选取不可用的安全项：',
  'reset.index.qihc2i': '确定重置',
  'reset.index.j3x4d5': '的6位短信验证码',
  'reset.index.wu2244': '您正在申请以下重置操作：',
  'reset.index.albrnw': '新手机号验证',
  'reset.index.315820': '新手机验证码',
  'reset.index.vs4ka9': '请输入您要启用验证的新邮箱',
  'reset.index.92fyx1': '重置手机号',
  'reset.index.3qyt3q': '重置邮箱',
  'reset.index.307sy0': '关闭谷歌验证器',
  'reset.index.3gs1p2': '谷歌验证器不可用，申请重置。',
  'reset.index.6odl42': '请至少选择一种不可用方式!',
  'reset.index.41hjjt': '手机号{0}不可用，申请重置。',
  'reset.index.jsuwbx': '邮箱{0}不可用，申请重置。',
  'accountsecurity.index.jeh767': '双重验证(2FA)',
  'accountsecurity.index.6i7fi7': '遇到问题？',
  'accountsecurity.index.3vr5uk': '绑定',
  'accountsecurity.index.yh5rux': '更改',
  'accountsecurity.index.g4x63c': '解绑',
  'accountsecurity.index.qlf4hm': '高级安全',
  'accountsecurity.index.1h64q4': '开启',
  'accountsecurity.index.l73sj8': '关闭',
  'accountsecurity.index.i33811': '最近登录',
  'accountsecurity.index.mc345k': '快捷提币',
  'accountsecurity.index.wi2qnw': '24小时免验证额度：',
  'accountsecurity.index.9m6b36':
    '为了您的资金安全，即使您启用了快捷提币，我们仍可能会根据您的账户安全性要求您进行安全认证。',
  'accountsecurity.index.j7591y': '新增白名单地址禁止提现时间限制',
  'accountsecurity.index.4nrynd':
    '如果关闭此功能，您的提现功能将会被禁止24小时',
  'accountsecurity.index.j7bqkc':
    '关闭此功能后，新添加的白名单地址可立即进行提现地址。',
  'accountsecurity.index.797yxm': '请考虑您的账户安全等级后关闭此功能。',
  'accountsecurity.index.688s59':
    '提币等相关操作需要开启两项安全验证，仅保留一项将影响提币！',
  'accountsecurity.index.98r7qd': '未绑定',
  'accountsecurity.index.to6o94': '谷歌验证器(推荐)',
  'accountsecurity.index.zm5d58': '手机验证',
  'accountsecurity.index.1fmg25': '邮箱验证',
  'accountsecurity.index.2qgw1x': '提现白名单',
  'accountsecurity.index.r8g22r': '白名单地址安全限制',
  'accountsecurity.index.mxdyyw': '登录密码',
  'accountsecurity.index.b47e47': '管理',
  'accountsecurity.index.s7684g': '单笔免验证额度',
  'accountsecurity.index.2w8i2e': '24小时',
  'accountsecurity.index.705k7v': '48小时',
  'accountsecurity.index.4ee8so': '72小时',
  'accountsecurity.index.528m37': '身份认证',
  'accountsecurity.index.yx274i': '保护您的交易和账户安全',
  'accountsecurity.index.21goo8': '启用',
  'accountsecurity.index.2t85w6': '用于管理您的账户登录密码',
  'accountsecurity.index.z43335': '提币设置',
  'accountsecurity.index.7bfk11':
    '开启本功能后，当您向白名单地址进行小额提币时可以免除安全验证',
  'accountsecurity.index.530ut2':
    '一旦启用次功能，您的账户将只能提现到您的白名单地址',
  'accountsecurity.index.i27mc3':
    '通过设置防钓鱼码，您能够辨别您收到的邮件是否来自于币趣',
  'accountsecurity.index.q786rj': '设备已被授权允许登录的账户',
  'accountsecurity.index.6968fe': '快捷提币已开启!',
  'accountsecurity.index.13dw21': '白名单开启成功！',
  'accountsecurity.index.g2c5k3': '白名单已关闭！',
  'accountsecurity.index.4p2g2d': '您确定要更改',
  'accountsecurity.index.ks8y15': '您确定要解绑',
  'accountsecurity.index.s123zs': '谷歌验证器吗？',
  'accountsecurity.index.747q6p': '手机验证吗？',
  'accountsecurity.index.bu8j46': '邮箱验证吗？',
  'accountsecurity.index.zxk4z3':
    '为了保护您的资产安全，更改或解绑验证后该账户将禁用支付服务、提币和 C2C 卖币 24 小时。',
  'accountsecurity.index.k9sv5l': '开启提现白名单',
  'accountsecurity.index.5v4733':
    '一旦启用此功能，您的账户将只能提现到您的白名单地址。',
  'accountsecurity.index.4n64be': '请确认您是否要“关闭白名单地址安全限制”？',
  'accountsecurity.index.b1sj33':
    '被解绑或被更改的邮箱在30天内不允许注册，并且在30天内不允许绑定或更改到其他帐户。',
  'accountsecurity.index.2399b8':
    '您将能够提现到任何提款地址，这可能会导致更大的风险。您确定要禁用您的提款白名单吗？',
  'accountsecurity.index.6c8350': '确认要删除该设备吗?',
  'accountsecurity.index.44c15i':
    '禁用后，请在确保账户安全的情况下再解禁您的账户.',
  'accountsecurity.index.x8b688': '关闭快捷提币',
  'accountsecurity.index.yoo2x8': '关闭提现白名单',
  'accountsecurity.index.77we05': '请确认您是否要“开启白名单地址安全限制”？',
  'accountsecurity.index.5c1bpu': '禁止提现时间更改',
  'accountsecurity.index.p95b65':
    '根据您配置的时长，对于新添加的白名单地址会被禁止提现相应的时间',
  'accountsecurity.index.r5318m': '解绑手机前请先绑定邮箱',
  'accountsecurity.index.y27sd3': '解绑邮箱前请先绑定手机',
  'accountsecurity.index.8t87mw':
    '已开启“白名单地址安全限制”，对于新添加的白名单地址会被禁止提现{0}小时。',
  'identityauthentication.enterpriseerr.f1lrt4': '企业身份验证失败',
  'identityauthentication.enterpriseerr.z33fdl':
    '请重新发起认证流程，并提前核对您的证件是否真实有效，且清晰可见。',
  'identityauthentication.enterpriseerr.71f92d': '重新认证',
  'identityauthentication.enterpriseidcard.h5u4s1': '企业身份认证',
  'identityauthentication.enterpriseidcard.cu8i9m': '企业基本信息',
  'identityauthentication.enterpriseidcard.qvft88': '居住地区',
  'identityauthentication.enterpriseidcard.ln74wk':
    '选择您现在所居住的国家或地区。您只需要回答当地法律要求回应的问题。',
  'identityauthentication.enterpriseidcard.0hze6m': '国籍',
  'identityauthentication.enterpriseidcard.816vg3': '姓名',
  'identityauthentication.enterpriseidcard.mwg4s7': '选择证件',
  'identityauthentication.enterpriseidcard.n927hl': '证件号',
  'identityauthentication.enterpriseidcard.6u8b6m':
    '为什么我需要验证我的身份？',
  'identityauthentication.enterpriseidcard.dtocnj':
    '验证身份可让您获得更高的提币及交易限额。24小时内的最高提款限额为50K USDT。',
  'identityauthentication.enterpriseidcard.4kk33v': '中国',
  'identityauthentication.enterpriseidcard.d81gjt': '请输入姓名',
  'identityauthentication.enterpriseidcard.840u6c': '身份证',
  'identityauthentication.enterpriseidcard.9vrkn4': '请输入证件号',
  'identityauthentication.enterpriseidcard.q64mg8': '美国',
  'identityauthentication.enterprisesuccess.j1u6ev': '企业身份验证已完成',
  'identityauthentication.enterprisesuccess.8coosb':
    '您已通过企业KYC认证,可享有无限加密货币交易额度',
  'identityauthentication.enterprisesuccess.8qnd58': '开始交易',
  'identityauthentication.enterpriseverification.ns7yt4': '文件验证',
  'identityauthentication.enterpriseverification.88kdei': '上传营业执照',
  'identityauthentication.enterpriseverification.ey7dp3':
    '请上传小于10MB的.jpg/.jpeg/.png格式文件',
  'identityauthentication.enterpriseverification.s64287': '使用摄像头',
  'identityauthentication.enterpriseverification.hpuo9s': '上传法人身份证',
  'identityauthentication.enterpriseverification.e3mkb4': '身份证正面(人像面)',
  'identityauthentication.enterpriseverification.cd94nk': '身份证背面(国徽面)',
  'identityauthentication.enterpriseverification.0hxcb7':
    '确保证件符合已下所有标准：',
  'identityauthentication.enterpriseverification.587dk6': '可读取且清晰明亮',
  'identityauthentication.enterpriseverification.5yiwid': '不反光，不模糊',
  'identityauthentication.enterpriseverification.3qir5b':
    '无黑白图像，未被编辑',
  'identityauthentication.enterpriseverification.f5d5nf':
    '请将证件至于纯色背景下',
  'identityauthentication.idcard.rb3w44': '个人身份验证',
  'identityauthentication.idcard.0qc2n1': '国家/地区',
  'identityauthentication.idcard.83ui8q': '请前往币趣手机APP进行身份认证',
  'identityauthentication.idcard.7nqj14':
    '您最多可以交易加密货币50K USDT.如需更多限额，请',
  'identityauthentication.idcard.51831f': '前往币趣APP进行高级视频验证',
  'identityauthentication.idcard.n8e533': '下载币趣APP',
  'identityauthentication.idcard.xjw8i8': '请选择或搜索国家/地区',
  'identityauthentication.idcard.0gcncv': '请选择证件类型',
  'identityauthentication.idcard.d6pvi4': '请填写所有必填项',
  'identityauthentication.idsuccess.5o2663': '个人信息验证已完成',
  'identityauthentication.index.c3zv6w': '身份认证后，即刻开启交易！',
  'identityauthentication.index.t582wo': '个人身份认证',
  'identityauthentication.index.1hjssv': '以个人身份进行交易，提供个人信息。',
  'identityauthentication.index.y28m4x': '我可以同时创建个人账户和企业账户吗？',
  'identityauthentication.index.6752e4':
    '抱歉，请您慎重选择您的账户类型，暂不支持多种账户类型',
  'identityauthentication.personal.ve2gkh':
    '推荐升级高级认证，可提升交易限额。',
  'identityauthentication.personal.ht7x5k':
    '您已通过高级认证，可享有全部服务。',
  'identityauthentication.personal.b1468c': '初级认证-个人信息',
  'identityauthentication.personal.c64mk9': '普通提币额度，24小时限额 50K USDT',
  'identityauthentication.personal.2d1typ': 'C2C交易限额 50K USDT',
  'identityauthentication.personal.1b931w': '立即验证',
  'identityauthentication.personal.877chb': '已验证',
  'identityauthentication.personal.c3i45p': '高级认证-视频认证',
  'identityauthentication.personal.cl5cd4': '增加提币额度，24小时限额 8M USDT',
  'identityauthentication.personal.23n4n6': '增加C2C交易限额 8M USDT',
  'identityauthentication.personal.bc8i1z': '为什么我必须进行身份认证？',
  'identityauthentication.personal.t13x7g':
    '实名认证是为了遵守当地的金融法规或相关政策。实名认证更有助于保护您的帐户安全，避免部分欺诈手段；',
  'identityauthentication.personal.e4025p': '认证是否安全？',
  'identityauthentication.personal.642u24':
    '视频认证仅通过接口进行，币趣不会保留您的任何个人身份信息，另外，我们也将会对您的信息进行严格保密。',
  'identityauthentication.personal.63dlpu': '暂时不支持哪些国家和地区？',
  'identityauthentication.personal.067qd0':
    '由于合规和监管要求，暂时不支持哪些国家和地区——古巴、伊朗、朝鲜、克里米亚、马来西亚、叙利亚、美国[包括所有美国领土像波多黎各、美属萨摩亚、关岛、北马里亚纳岛,和美国维尔京群岛(圣约翰的圣克鲁斯岛和圣托马斯)]、孟加拉国、玻利维亚、马耳他、加拿大魁北克省、加拿大安大略省、新加坡。',
  'identityauthentication.personal.1xnj58': '请前往币趣手机APP进行视频认证',
  'identityauthentication.remake.83au88': '经过识别，您的照片:',
  'identityauthentication.remake.ky8q8l': '可读，清晰不模糊',
  'identityauthentication.remake.i1ev9d': '光线充足，不暗不反光',
  'identityauthentication.remake.mp31d8': '请勿使用黑白图像',
  'identityauthentication.remake.ui8830': '请同时确保：',
  'identityauthentication.remake.2v4801': '证件未经编辑',
  'identityauthentication.remake.lto47s': '请将证件置于纯色背景下',
  'identityauthentication.remake.721tfj': '重拍',
  'identityauthentication.uploaded.zh4393': '证件照已上传',
  'identityauthentication.verification.60c2cl': '上传个人身份证',
  'alipay.index.pu3gld': '添加收款方式',
  'alipay.index.r7zk37': '支付宝',
  'alipay.index.wp509f': '上传',
  'alipay.index.184371': '(支持JPG/JPEG/PNG/BMP格式, 小于1MB)',
  'alipay.index.34512m': '温馨提示',
  'alipay.index.83il88':
    '当您出售数字货币时,您选择的收款方式将向买方展示。请确认信息填写准确无误。请使用与您本人币趣平台KYC身份认证一致的收款账户。',
  'alipay.index.k4b512': '确认',
  'alipay.index.ar7ke7': '支付宝账号',
  'alipay.index.5gw17n': '支付宝账号不能为空',
  'alipay.index.d434d5': '请输入您的支付宝账号',
  'alipay.index.i6773c': '收款二维码(选填)',
  'alipay.index.31jn63': '请填写必填项',
  'alipay.index.68ff1b': '文件大小超出限制，请修改后重新上传',
  'alipay.index.g0167s': '上传成功',
  'alipay.index.i1b1c3': '上传失败',
  'bankcard.index.122668': '银行卡',
  'bankcard.index.j3lm0r': '特别提示！',
  'bankcard.index.36w4tq':
    '请确保添加您的银行卡号以进行即时付款/收款。请勿包含其他银行或付款方式的详细信息。您必须添加所选银行的付款/收款信息。',
  'bankcard.index.v3v417': '银行账号/卡号',
  'bankcard.index.oh2r4b': '银行账号/卡号不能为空',
  'bankcard.index.55v5l4': '请输入银行账号/卡号',
  'bankcard.index.kjx038': '银行名称',
  'bankcard.index.6tj74o': '银行名称不能为空',
  'bankcard.index.439w4o': '请输入您的银行名称',
  'bankcard.index.7863u2': '开户支行(选填)',
  'bankcard.index.su0qyl': '请输入您的开户支行',
  'wechat.index.4q6p3g': '微信',
  'wechat.index.1jw088':
    '当您出售数字货币时，您选择的收款方式将向买方展示。请确认信息填写准确无误。请使用与您本人币趣平台KYC身份认证一致的收款账户。',
  'wechat.index.323592': '微信账号',
  'wechat.index.l3prca': '请输入您的微信号',
  'paymentmethod.index.m2t022': '收款方式',
  'paymentmethod.index.1a7s4d':
    '收款方式：您添加的C2C收款方式将在C2C交易出售数字货币时向买方展示作为您的收款方式，请务必使用您本人的实名账户确保买方可以顺利给您转账。',
  'paymentmethod.index.0opj97':
    '管理buy crypto页面“your credit and debit card”的支付方式',
  'paymentmethod.index.y9sc13': '银行卡转账',
  'paymentmethod.index.xqm1v7': '认证姓名',
  'paymentmethod.index.jdpo9n': '卡号',
  'paymentmethod.index.26qcyf': '开户行',
  'paymentmethod.index.v1al7g': '编辑',
  'paymentmethod.index.6h4u03': '支付宝转账',
  'paymentmethod.index.33vdu3': '账号',
  'paymentmethod.index.nb557l': '二维码',
  'paymentmethod.index.2v2sr4': '无',
  'paymentmethod.index.64j352': '微信转账',
  'paymentmethod.index.ipl281': '您还没有任何收款方式',
  'paymentmethod.index.8o4t13': '暂无收款方式',
  'paymentmethod.index.4o3t7g': '确认删除此收款方式吗？',
  'paymentmethod.index.4yuz54': 'C2C收款方式',
  'paymentmethod.index.kr8k8g': '快捷买币',
  'paymentmethod.index.g7423o': '请先完成身份认证',
  'paymentmethod.index.8r14e6': '当前收款方式有进行中的订单，不可编辑',
  'paymentmethod.index.r0jme1': '当前收款方式有进行中的订单，不可删除',
  'setupp.index.eg8e11': '设置',
  'setupp.index.452t1d': '我的个人资料',
  'setupp.index.qd87c0': '头像',
  'setupp.index.233vb3': '请选择一个头像以个性化您的帐户',
  'setupp.index.vj214g': '活动通知',
  'setupp.index.8p3tf4': '系统消息',
  'setupp.index.51f41n': '币趣资讯',
  'setupp.index.5cgiog': '交易通知',
  'setupp.index.82461m': '全选',
  'setupp.index.7xr0nm': '通知语言',
  'setupp.index.w81d03': '请选择语言',
  'setupp.index.m79c1f': '选择头像',
  'setupp.index.353554': '选择一个头像用作您的个人资料照片',
  'setupp.index.y74j4e': '选择用于电子邮件和应用推送通知的语言',
  'setupp.index.xdhjw5': '站内信',
  'setupp.index.ps0nd8': '一旦启用，您将在app和网站内收到相关通知。',
  'forget.step2.2tb10c': '发送验证码',
  'forget.step2.t728co': '后发送重新',
  'forget.step2.xktgq7': '生成的6位验证码',
  'forget.step2.23m35e': '切换至其他验证方式',
  'forget.step2.127tlh': '选择一种验证器',
  'forget.step3.234452':
    '*为了您的资产安全，更改或解绑验证后该账户将禁用支付服务、提币和C2C卖币24小时。',
  'balance.flash.avg5ow': '币趣闪兑',
  'balance.flash.16e5dd': '兑换记录',
  'balance.flash.7yxk65': '余额：',
  'balance.flash.5pdf6a': '当前参考价格:',
  'balance.flash.y91k7m': '价格在后',
  'balance.flash.r56244': '更新',
  'balance.flash.979l87': '刷新',
  'balance.flash.p7ss2f': '请输入金额',
  'balance.flash.ks5utw': '立即兑换',
  'balance.flash.vw5c2g': '常见问题',
  'balance.flash.7hx1b2': '有没有手续费？',
  'balance.flash.5bi77n':
    '币趣闪兑不仅没有手续费，而且兑换汇率高出市场价格，让用户最大限度享受到福利。',
  'balance.flash.i5c7dy': '支持什么币种？',
  'balance.flash.dtr85o': '您正在进行闪兑，参考价格',
  'balance.flash.d8616e': ',当前价格有效期还剩',
  'balance.flash.2mo1kq': ',是否确认?',
  'balance.flash.o1osbj': '当前价格已失效，请重新发起闪兑。',
  'balance.index.6ixcdv': '余额',
  'balance.index.65v969': '代表总余额的USDT包括加密货币和法币余额',
  'balance.index.y78v22': '预估余额',
  'balance.index.5xh61i': '闪兑',
  'balance.index.k2bw93': '加密货币余额',
  'balance.index.6855lf': '总资产金额',
  'balance.index.m1300v': '可用金额',
  'balance.success.pr8hn6': '兑换成功',
  'balance.success.586403': '支付金额',
  'balance.success.2x4hq2': '换取金额',
  'balance.success.h3u572': '参考单价',
  'collection.index.mrieh4': '使用币趣钱包收款',
  'collection.index.4o71tn': '通过多种收款方式接收加密货币付款',
  'collection.index.2we6y1': '选择收款方式',
  'collection.index.4yb2x1': '二维码或者用户ID',
  'collection.index.n0ph26': '转账0手续费',
  'collection.index.9783d8': '无论收款或付款均无任何费用',
  'collection.index.24ge97': '与发送方共享',
  'collection.index.soy1v7': '分享生成的收款二维码',
  'collection.index.wbmgaq': '查看交易状态',
  'collection.index.e36sj0': '您和发送方都可以在交易历史记录中查看这笔交易',
  'collection.index.865l53': '立即接收',
  'collection.index.b3s2h6': '扫描并转账',
  'collection.index.6jm0yl': '我的币趣ID',
  'collection.index.38jmo5': '用户名',
  'components.faq.2w458d': '视频教程',
  'components.faq.j4f5i0': '如何向我的币趣账户充值数字货币',
  'components.faq.vcw5n4': '为什么我充的币一直不到账？',
  'components.faq.25etk3': '填错、漏填Memo/tag的时候，如何找回我的资产？',
  'components.faq.356p23': '如何购买数字货币？',
  'components.faq.jy036g': '查看全部币种充提状态',
  'components.figures.6q1l29': '复制充币地址',
  'components.figures.wg89uc':
    '选择您要充值的币种及其区块网络并在本页面复制充值地址',
  'components.figures.3t4e1h': '发起提币',
  'components.figures.u0vqw3': '在对方平台发起提币',
  'components.figures.5o9w2x': '网络确认',
  'components.figures.4xw78l': '等待区块网络确认您的转账',
  'components.figures.0r434g': '充币成功',
  'components.figures.agse9k': '区块确认后，币趣将为您上账',
  'components.selectcoin.8926wx': '请选择提现币种',
  'components.selectcoin.16q188': '选择币种',
  'components.selectnet.tn1k41': '选择转账网络',
  'components.selectnet.7f375j':
    '请确保您本次提现的收款平台支持您要提出的币种和您提币使用的网络。如果您还不能确定，建议您先和收币平台确认后再提币。',
  'components.selectnet.5jt6om': '预计到账时间 ≈',
  'components.selectnet.5c6ffp': '分钟',
  'components.selectnet.0r7e3h': '费用说明',
  'recharge.edit.sbdp33': '进行中',
  'recharge.index.4iivy9': '数字货币充值',
  'recharge.index.08t186':
    '如果您想使用使用法币（例如人民币、欧元等）购买数字货币，可以点击',
  'recharge.index.fx7y4j': '充值地址',
  'recharge.index.luaa4b': '请点击按钮获取充值地址。',
  'recharge.index.2h1v0v': '获取地址',
  'recharge.index.43a846': '使用交易平台APP或钱包APP扫码',
  'recharge.index.6gs53g': '此地址只接收',
  'recharge.index.4x3psz': '请再次确认您选择的主网络是',
  'recharge.index.x58g67': '请不要将NFT发送到该地址',
  'recharge.index.d02594': '预计到账',
  'recharge.index.26c7b1': '次网络确认',
  'recharge.index.qdj1i5': '预计解锁',
  'recharge.index.jz6i9u': '最小充币数量',
  'recharge.index.0c907p': '合约地址',
  'recharge.index.g77o3j': '此地址只可接收',
  'recharge.index.dfp1wo': '请不要将 NFT 发送到该地址',
  'recharge.index.608blw': '充值未到账？',
  'recharge.index.1q3qvs': '查看详情',
  'recharge.index.18kyp2':
    '如果您在充值过程中遇到以下问题，您可以通过“充值未到账查询”功能查询当前充值状态或自助申请找回您的资产。',
  'recharge.index.jln747': '充值后长时间未到账',
  'recharge.index.l9ps88': '充值时忘填或错填Tag/Memo',
  'recharge.index.x2s98t': '充值了币趣未上线的币种',
  'recharge.index.1s1gn0': '立即查询',
  'recharge.index.73fh6d': '近期充币记录',
  'recharge.index.bdc7lj': '充值未到账？点这里',
  'recharge.index.18p77l': '资产',
  'recharge.index.a5711v': '查看全部',
  'recharge.index.776234': '请求中 请稍后',
  'recharge.index.e09065': '请先选择转账网络',
  'transferaccounts.index.2s3533': '邮箱不能为空',
  'transferaccounts.index.lf002x': '手机号不能为空',
  'transferaccounts.index.6pr76n': '用户名不能为空',
  'transferaccounts.index.183816': '币趣ID不能为空',
  'transferaccounts.index.80v1jn': '转账金额不能为空',
  'transferaccounts.index.47n170': '请输入金额,可精确至{0}位小数',
  'transferaccounts.index.99wa8r': '添加备注',
  'transferaccounts.index.q4dl6q': '备注(选填)',
  'transferaccounts.index.22w46q': '添加常用收款人',
  'transferaccounts.index.72b1l1': '设置支付PIN码',
  'transferaccounts.index.x677ux': '创建支付PIN码',
  'transferaccounts.index.r3p3hi': 'PIN码需满足6位数字组合',
  'transferaccounts.index.x608ud': '单一数字不可重复3次或以上',
  'transferaccounts.index.77hv2u': '请勿使用4位连续数字组合',
  'transferaccounts.index.8pm8yh': '请再次输入PIN码',
  'transferaccounts.index.gvyrgf': '重置PIN码',
  'transferaccounts.index.r63k73': '当前PIN码',
  'transferaccounts.index.4vn63o': '新支付PIN码',
  'transferaccounts.index.ak108t': '禁用支付PIN码',
  'transferaccounts.index.w91yi6': '接收设置',
  'transferaccounts.index.261157': '通过邮箱地址或手机号接收资金',
  'transferaccounts.index.1akdi9':
    '其他用户可通过收款方的电子邮箱或手机号向收款方的资金钱包转账资产',
  'transferaccounts.index.w97lk9': '支付PIN码',
  'transferaccounts.index.96f22u': '忘记PIN码',
  'transferaccounts.index.358pq1': '交易历史记录',
  'transferaccounts.index.7918ln': '币趣用户之间转账免手续费,即时到账。',
  'transferaccounts.index.34p09t': '收款人',
  'transferaccounts.index.jjo13u':
    '通过邮箱、手机号码、用户名、币趣 ID向币趣用户转账数字货币。',
  'transferaccounts.index.b15688': '收款人可以在用户中心的用户ID找到其币趣ID',
  'transferaccounts.index.hwd69o': '继续',
  'transferaccounts.index.qf3w26': '添加收款人',
  'transferaccounts.index.842g41': '暂无联系人',
  'transferaccounts.index.1486f1': '邮箱',
  'transferaccounts.index.85687o': '手机号码',
  'transferaccounts.index.jb2g31': '转账备注(选填)',
  'transferaccounts.index.8r1kbp': '支付方式',
  'transferaccounts.index.4rj478': '钱包账户',
  'transferaccounts.index.8kr6wj': '可用余额：',
  'transferaccounts.index.qrk32f': '余额不足',
  'transferaccounts.index.p1czps': '支付币种',
  'transferaccounts.index.28y74i': '转账成功',
  'transferaccounts.index.g5b418': '再次发送',
  'transferaccounts.index.76452i': '请输入备注',
  'transferaccounts.index.u22dj7': '您确定要删除此常用联系人吗?',
  'transferaccounts.index.om6z9f': '收款人邮箱',
  'transferaccounts.index.8597h6': '收款人手机号',
  'transferaccounts.index.3j55lk': '收款人用户名',
  'transferaccounts.index.f23b7k': '收款人币趣ID',
  'transferaccounts.index.41p237':
    '为了您的交易安全与便捷，请设置一个6位数字的支付PIN码',
  'transferaccounts.index.elpdch':
    '您将要离开这个页面,您输入的付款信息会被删除,确认离开?',
  'transferaccounts.index.8g712z': '继续禁用支付PIN码？',
  'transferaccounts.index.2w5z6g':
    '禁用支付PIN码后，您将必须使用2FA来验证每一笔交易。您后续可以在支付-设置中开启支付PIN码。',
  'transferaccounts.index.b4f4w2': '常用收款人',
  'transferaccounts.index.m8280y': '输入转账备注(对方可见)',
  'transferaccounts.index.5v553e': '您的转账功能已被禁用',
  'withdrawal.index.71560x': '提现费用',
  'withdrawal.index.6s0k2s': '手续费',
  'withdrawal.index.l86n00': '最小提现金额{0},可精确至{1}位小数',
  'withdrawal.index.3245em': '数字货币提现',
  'withdrawal.index.u06exc': '转账到',
  'withdrawal.index.21sf6z': '币趣用户',
  'withdrawal.index.t8rcx5': '白名单已开启您的账号仅能提现到白名单地址。',
  'withdrawal.index.e1j15c': '添加白名单地址',
  'withdrawal.index.45v07r': '这是一个币趣用户ID:',
  'withdrawal.index.6i9x3o': ',立即转账,零手续费。',
  'withdrawal.index.at7j1o': '最小提币数量',
  'withdrawal.index.fd2d7c': '提币手续费',
  'withdrawal.index.8r166r': '24H剩余提现额度',
  'withdrawal.index.73ox38': '当日剩余额度',
  'withdrawal.index.88j284': '账户余额',
  'withdrawal.index.7wx846': '到账数量',
  'withdrawal.index.g5sz4l': '网络转账费用包含在内',
  'withdrawal.index.5uv3o4': '网络转账费用包含在内',
  'withdrawal.index.ic0r63': '从地址簿中选择',
  'withdrawal.index.746054':
    '请务必在提现时仔细确认备注、地址、币种，以及公链，否则将有可能导致资金损失！',
  'withdrawal.index.q32r62': '添加新提现地址',
  'withdrawal.index.sm4s27':
    '您已开启白名单功能，当前仅能向标记为白名单的地址提币',
  'withdrawal.index.wiqrui':
    '快捷提币已开启，提币至白名单地址时可以免除安全验证。',
  'withdrawal.index.0eil5v': '到账',
  'withdrawal.index.mx1436':
    '请确保您输入了正确的提币地址并且您选择的转账网络与地址相匹配',
  'withdrawal.index.p1m1k5': '提币订单创建后不可取消',
  'withdrawal.index.04k48l': '提现申请审核中',
  'withdrawal.index.d3dv5h':
    '您的提现申请已进入审核中，预计审核时间12小时，请耐心等待。您可在提现记录页面查看本次提现的状态。',
  'withdrawal.index.4nx0jo': '开启快捷提币可以快速提币至白名单地址',
  'withdrawal.index.jd6j7h':
    '开启快捷提币后，您可以在提币至白名单时免除安全验证。您可以在“账户-账户安全”页面开启此功能',
  'withdrawal.index.q9pca9': '立即开启',
  'withdrawal.index.6375ey': '请输入地址',
  'withdrawal.index.k1ar3u': '点击这里，进入币趣用户提现界面，零手续费',
  'withdrawal.index.t8utw1': '您的提现功能已被禁用',
  'withdrawal.index.297v9q':
    '修改密码后未超过24h不可提现，请24h后再进行提现操作',
  'withdrawal.index.203a4f': '请输入提现金额',
  'withdrawal.index.0j7gm8': '提现金额不能大于账户余额',
  'withdrawal.index.cs673u': '提现金额不能大于当日剩余额度',
  'withdrawal.index.d8s17u': '请输入正确的提币地址',
  'withdrawal.index.i8n26t': '请输入正确的地址',
  'withdrawal.user.5i08r6': '数字币地址转账',
  'withdrawal.user.81vy10': '币趣用户转账',
  'components.figureswith.r4794m': '在币趣平台创建提币订单',
  'components.figureswith.795hkl': '获取提币地址',
  'components.figureswith.o48601': '将收款人地址粘贴至提币页面的地址输入框',
  'components.figureswith.1v1uox': '等待区块网络确认提币信息',
  'components.figureswith.q3d8b4': '提现成功',
  'components.figureswith.741j33': '你提现的资产成功已发送至收币地址',
  'components.footer.hkr883': '近期提币记录',
  'components.footer.4wp718': '数字货币未到账？',
  'components.footer.hp7v7v': '隐藏错误提示',
  'components.footer.71g73b': '交易对象',
  'components.footer.8kz3w7': '交易详情',
  'components.footer.4bd6qh': '转账金额',
  'components.footer.2p29y6': '收款人账户',
  'components.footer.7niq62': '审核中',
  'components.noneaddressbook.h57py3':
    '将常用地址保存在地址簿，可以在将来提币时直接使用。为每个地址添加标签方便识别。',
  'components.usetype.gap2oj': '请输入正确的{0}',
  'components.usetype.193qyk': '请输入金额，可精确至{0}位小数',
  'components.usetype.c12082': '确认转账',
  'components.usetype.578640': '发送到',
  'components.usetype.781tn7': '提币数量',
  'components.usetype.83383l': '出金账户',
  'components.usetype.isnuw4': '请确认收款人信息是正确的',
  'components.usetype.6w5d58': '资金将立即转成并不支持退款',
  'components.usetype.dy26cx': '接收方可以在资金账户查看余额',
  'components.usetype.kyxc2u':
    '无法转账到此账户，请确保这是个有效并完成认证的账户，且收款方开启了支付-设置-允许通过邮箱或手机号向我转账',
  'components.usetype.41254s': '请输入备注(选填)',
  'components.usetype.jo4wvy': '您的币趣用户之间的转账功能已被禁用',
  'components.usetype.wyeia0': '请输入转账金额',
  'components.usetype.teccw9': '转账金额不能大于账户余额',
  'wallethistory.index.9f1e8k': '钱包历史记录',
  'wallethistory.index.3p8jpj': '方向',
  'wallethistory.index.3i4539': '自定义时间',
  'wallethistory.index.v1f4xc': '申请已提交',
  'wallethistory.index.kxtger': '自定义',
  'wallethistory.index.8a5y51': '检索的时间范围不得超过12个月',
  'wallethistory.index.y6y6n4': '请选择',
  'wallethistory.index.7fu66b': '订单号',
  'wallethistory.index.1r786y': '广告ID',
  'wallethistory.index.8838lh': 'C2C订单号',
  'wallethistory.index.6xpeei': '输入交易订单号',
  'wallethistory.index.ediegq': '输入广告ID',
  'wallethistory.index.2s65sr': '输入C2C订单号',
  'wallethistory.index.b885tc': '请输入TxID',
  'wallethistory.index.0k1y2f': '冻结余额',
  'wallethistory.index.ind7f2': '商户账户',
  'wallethistory.index.vadizt': '未查询到该笔订单',
  'wallethistory.index.4u2nrc': '近7天',
  'wallethistory.index.t3mb83': '近30天',
  'wallethistory.index.sxz353': '近90天',
  'wallethistory.index.bce1l1': '交易订单号',
  'wallethistory.index.2idphi': 'C2C订单',
  'wallethistory.index.ck8goy': '记账金额类型',
  'wallethistory.index.32d322': '加盟币趣支付代理,请联系Telegram:',
  'navbar.index.p8cqf3': '一键买币',
  'navbar.index.075ejk': '充值',
  'navbar.index.u0jp51': '钱包',
  'navbar.index.n1qu62': '买币',
  'navbar.index.r0zkso': '转账',
  'navbar.index.5ypwz7': '提现',
  'navbar.index.5jxg12': '钱包历史记录',
  'navbar.index.6fwd19': '个人用户',
  'navbar.index.xr75kp': '总览',
  'navbar.index.fsd231': '推荐返佣',
  'navbar.index.n35329': '账户安全',
  'navbar.index.4sr56d': '身份认证',
  'navbar.index.l461w3': '收款方式',
  'navbar.index.dtm61i': '资金管理',
  'navbar.index.dfp47y': '订单查询',
  'navbar.index.xg8agy': '设置',
  'navbar.index.7r8gej': '退出登录',
  'navbar.index.f6enm3': '下载',
  'navbar.index.jft093': '扫描下载App',
  'navbar.index.e4hpuj': '更多下载选择',
  'components.revence-data.1o1v3x': '收支详情',
  'components.revence-data.xutv2h': '更多',
  'components.revence-data.96f84q': '状态',
  'components.revence-data.yy5177': '时间',
  'components.revence-data.2nd8n4': '币种',
  'components.revence-data.wx899o': '充值数量',
  'components.revence-data.q052s1': '转账网络',
  'components.revence-data.7c5t77': '地址',
  'components.revence-data.228zrf': '金额',
  'components.revence-data.e6yxx8': '至',
  'components.revence-data.5e8xk1': '备注',
  'components.revence-data.mh514x': '创建时间',
  'components.revence-data.8j5533': '订单编号',
  'components.revence-data.982243': '提币申请已提交',
  'components.revence-data.yn42bn': '系统处理中',
  'components.revence-data.wrk1uq': '已完成',
  'components.revence-data.u5bwcq': '提现金额',
  'components.revence-data.141gb1': '实到金额',
  'components.revence-data.lu095t': '手续费',
  'components.revence-data.q86g34': '进行中',
  'components.revence-data.qb2p5y': '成功',
  'components.revence-data.252etx': '失败',
  'components.revence-data.vfx53c': '审核中',
  'components.revence-data.k4c8ps': '暂无数据',
  'components.revence-data.k715gf': '充值详情',
  'components.revence-data.5pbm81': '提现详情',
  'components.revence-data.wjfj5b': '转账详情',
  'components.revence-data.5fo35v': '类型',
  'components.revence-data.76qs5l': '数量',
  'components.footer.v77lp7': '近期提币记录',
  'components.footer.7lynr7': '数字货币未到账？',
  'components.footer.3y1w82': '隐藏错误提示',
  'components.footer.imrq6j': '资产',
  'components.footer.ak8p8q': '查看全部',
  'components.footer.3s16r7': '交易对象',
  'components.footer.73i15s':
    '请注意，我们会在提币完成时给你发送邮件通知，为什么我的提现',
  'components.footer.s1m739': '还没到账？',
  'components.footer.92i2kf': '无',
  'components.footer.196oh4': '交易详情',
  'components.footer.p0bqf6': '转账金额',
  'components.footer.58menr': '收款人账户',
  'components.footer.8579va': '收入',
  'components.footer.tgfys9': '支出',
  'components.footer.4mnajt': '完成',
  'components.footer.18b1ms': '数字币地址转账',
  'components.footer.531kp1': '币趣用户转账',
  'wallethistory.index.5i78a8': '方向',
  'wallethistory.index.8w6804': '自定义时间',
  'wallethistory.index.47v114': '全部',
  'wallethistory.index.5ghw1q': '从',
  'wallethistory.index.2sphc2': '自定义',
  'wallethistory.index.529g8d': '检索的时间范围不得超过12个月',
  'wallethistory.index.h55bzf': '确认',
  'wallethistory.index.2ovnj2': '请选择',
  'wallethistory.index.hk6yeh': '订单号',
  'wallethistory.index.n3h46i': '广告ID',
  'wallethistory.index.z1r79x': 'C2C订单号',
  'wallethistory.index.uq1xwt': '输入交易订单号',
  'wallethistory.index.7g829o': '输入广告ID',
  'wallethistory.index.qqo536': '输入C2C订单号',
  'wallethistory.index.lergm7': '请输入TxID',
  'wallethistory.index.o22no8': '冻结余额',
  'wallethistory.index.4nyb38': '可用余额',
  'wallethistory.index.tj74nd': '未查询到该笔订单',
  'wallethistory.index.f898io': '近7天',
  'wallethistory.index.v4u435': '近30天',
  'wallethistory.index.8l028p': '近90天',
  'wallethistory.index.t7ec78': '记账金额类型',
  'wallethistory.index.mqe2y8': '交易订单号',
  'wallethistory.index.l4dp73': 'C2C订单',
  'wallethistory.index.l4d233': '暂无数据',
  'wallethistory.index.l423d3':
    '为了保护您的资产安全，更改或解绑验证后该账户将禁用支付服务、提币和 C2C 卖币24 小时。',
  'safetydialog.index.178bx6': '支付PIN码验证',
  'safetydialog.index.557581': '请输入支付PIN码',
  'safetydialog.index.2e2215': '切换至其他验证方式',
  'safetydialog.index.zj74uq': '发送验证码',
  'safetydialog.index.193173': '后发送重新',
  'safetydialog.index.284434': '请输入',
  'safetydialog.index.uo4t31': '生成的6位验证码',
  'safetydialog.index.4yhgsc': '的6位验证码',
  'safetydialog.index.kh189w': '切换至PIN码',
  'safetydialog.index.pnpoko': '选择一种验证器',
  'safetydialog.index.wks78g': '验证码必须为6位数字',
  'safetydialog.verification.j6a316': '安全验证不可用?',
  'login.verification.3yt211': '登录成功',
  'login.verification.h937w2': '请稍后再试',
  'texttool.index.8tkh16': '什么是防钓鱼码？',
  'texttool.index.8322uj':
    '防钓鱼码是你自己设置的一串字符，能够帮助你识别防冒币趣的网站或者邮件',
  'texttool.index.d4btk5': '防钓鱼码会出现在哪？',
  'texttool.index.85q6ja':
    '设置好防钓鱼码后，每一封币趣发给您的邮件都会带有这串字符。',
  'texttool.index.614p6n': '为了账户安全，币趣建议您经常更换钓鱼码',
  'message.index.nu55o6': '消息中心',
  'message.index.040p38': '隐藏已读通知',
  'message.index.45l897': '取消删除',
  'message.index.j53q1c': '逐个删除',
  'message.index.cr0g63': '清空全部',
  'message.index.3s11dy': '暂无通知',
  'message.index.5149es': '全部已读',
  'message.index.v8v63c': '确定清空所有消息吗?',
  'message.index.rs86ai': '确定删除这条消息吗?',
  'message.index.813ex2': '确定已读所有消息吗?',
  'message.index.6id33f': '全部通知',
  'message.index.3mx6r2': '系统通知',
  'withdrawal.index.sl37o9': '最小提现金额',
  'disableacc.index.4he22l': '删除账户',
  'disableacc.index.4600vj': '删除您的账户将导致以下结果：',
  'disableacc.index.1n1xyl': '您的账户信息与所有记录都将被清除，请问是否继续？',
  'activity.index.3v228s': '邀请好友 一起赚币',
  'activity.index.edb45q':
    '邀请方和受邀用户在C2C交易、首次充值、支付和高级认证中一起赚币。',
  'activity.index.yu66tq': '查看推荐规则',
  'activity.index.w3to2t': '邀请码',
  'activity.index.r5c8le': '复制',
  'activity.index.07k677': '推荐链接',
  'activity.index.7c2lsz': '邀请好友',
  'activity.index.0f7d7m': '受邀用户',
  'activity.index.tgo3f2': '推荐人返佣',
  'activity.index.k3pq4e': '受邀者返佣',
  'activity.index.iq3uti': '*数据统计时间以UTC+8时间为准。',
  'activity.index.gh832j':
    '邀请返佣将按小时记入您的钱包余额。如发生延误，处理时间可能长达72小时。如72小时后您仍未收到佣金，请联系客服寻求进一步帮助。',
  'activity.index.4xx2p5': '仅显示过去',
  'activity.index.3d4v15': '天的记录。如需要查看详细交易记录，请点击“导出”。',
  'activity.index.al57mt': '导出',
  'activity.index.g50728': '推荐码',
  'activity.index.2sxu6b': '立即报名。一起赚币',
  'activity.index.24767a': '您的收益',
  'activity.index.41k75q': '开始交易的好友',
  'activity.index.1srwpj': '好友数',
  'activity.index.ghhu65': '暂无记录',
  'activity.index.njgm7p': '是否要进行“导出”操作？',
  'activity.index.k3v982': '复制链接',
  'activity.index.yh2778': '复制成功',
  'activity.index.t4g7z4': '复制失败',
  'activity.index.ye8x52': '全部时间',
  'activity.index.e517a8': '昨日',
  'activity.index.k1q8v4': '本周',
  'activity.index.28s1pm': '本月',
  'activity.index.2x16v8': '点击链接，立即注册，即刻开始交易。',
  'activity.index.71420h': '一',
  'activity.index.66imlc': '用户ID',
  'activity.index.587i85': '邀请时间',
  'activity.index.15vt2o': '返佣级别',
  'activity.index.x27398': '返佣类型',
  'activity.index.f84ctg': '返佣金额',
  'activity.index.7ud5d7': '返佣币种',
  'tab.yet1-1': '如果您尚未安装，请先下载。',
  'tab.yet1-2': '打开App',
  'resigter.index.e142w4': '邀请码(选填)',
  'resigter.index.8o8gw4': '请输入推荐码',
  'activity.index.sdj13m': '一级',
  'activity.index.tcy327': '二级',
  'activity.index.eit3w1': '被邀请人',
  'activity.index.31bq7k': '支付',
  'activity.index.0i8xbr': '首充',
  'activity.index.7t1d28': '使用浏览器访问 {0} 注册BasicEx，享受多重礼遇！',
  'financial.index.a416j6': '理财',
  'financial.index.u17v8y': '简单、安全。搜索热门币种，立即赚取收益。',
  'financial.index.9wn75k': '预估理财资产',
  'financial.index.0aez31': '30日累计收益',
  'financial.index.7l6xk6': '历史',
  'financial.index.74sqjr': '申购',
  'financial.index.45m1g2': '活期',
  'financial.index.u45h04': '天',
  'financial.index.1lb333': '收起',
  'financial.index.lvk700': '查看更多',
  'financial.index.4i534j': '搜索币种',
  'financial.index.ic2158':
    '当前产品为定期理财，提前赎回将丧失所有收益，是否确认赎回？',
  'financial.index.h53vii': '返还本金',
  'financial.index.9ql3t3': '继续收益',
  'financial.index.7k4y73': '全部周期',
  'financial.index.9o8661': '定期',
  'financial.index.d4y434': '所有产品',
  'financial.index.7fe254': '当前持仓',
  'financial.index.cl022e': '预估年利率',
  'financial.index.rlc92n': '期限',
  'financial.index.ry7o62': '持仓数量',
  'financial.index.7x13ac': '固定年利率',
  'financial.index.j4adht': '累计收益',
  'components.redemptionmodal.f6wol5': '赎回',
  'components.redemptionmodal.8tdo21': '赎回金额',
  'components.redemptionmodal.1dyoq9': '最大',
  'components.redemptionmodal.i1tf10': '可赎回：',
  'components.redemptionmodal.c2f84y': '快速赎回',
  'components.redemptionmodal.313789': '赎回金额立即到账',
  'components.redemptionmodal.k5z838': '最大可赎回 {0}{1}',
  'components.subscriptionmodal.4z6146': '期限 ( 天 )',
  'components.subscriptionmodal.vu0s2x': '申购金额',
  'components.subscriptionmodal.2l381c': '可用保证金',
  'components.subscriptionmodal.hs6266': '没有足够的加密资产？',
  'components.subscriptionmodal.n4iiqu': '数量限制',
  'components.subscriptionmodal.k14498': '最少可投：',
  'components.subscriptionmodal.210l8f': '剩余额度：',
  'components.subscriptionmodal.6o1pe5': '概览',
  'components.subscriptionmodal.69kj53': '申购日期',
  'components.subscriptionmodal.e2tf8d': '起息日',
  'components.subscriptionmodal.crg825': '首次奖励发放日',
  'components.subscriptionmodal.d733lj': '固定年化利率，每日计息。',
  'components.subscriptionmodal.15rpj4': '收益不以法币计算。',
  'components.subscriptionmodal.2750lb': '可随时提取。',
  'components.subscriptionmodal.782584': '提前赎回将无法产生收益。',
  'components.subscriptionmodal.cn60q7': '我已阅读并同意',
  'components.subscriptionmodal.7njv30': '币趣理财服务协议',
  'components.subscriptionmodal.vk7tu8': '当前币种可用余额',
  'components.subscriptionmodal.ii6354': '当前可购买的最大额度',
  'components.subscriptionmodal.7585sb': '预估每日利息',
  'components.subscriptionmodal.94bpyq': '预估利息',
  'components.subscriptionmodal.8x2l76': '申购金额不得高于可用保证金',
  'components.subscriptionmodal.7pv85t': '最少可投{0} {1}',
  'components.subscriptionmodal.r8z1in': '剩余额度{0} {1}',
  'components.successmodal.c86btp': '申购成功',
  'components.successmodal.s5mxe3': '赎回已确认',
  'components.successmodal.n55j08': '正在等待处理',
  'components.successmodal.81qw5t': '预计赎回时间',
  'components.successmodal.p1lcda': '*统一生效时间以UTC+8为准',
  'financial.index.51d412': '赎回历史',
  'components.redemptionmodal.6w3q97': '如未全部赎回，则优先赎回收益。',
  'components.history.722wpc': '理财管理',
  'components.history.wrvmwf': '30日累计收益记录',
  'components.history.d27w35': '产品',
  'components.history.b37656': '申购时间',
  'components.history.821kxk': '年利率',
  'components.history.oq7jxy': '发放收益',
  'components.history.023us6': '发放时间',
  'components.historyred.73r9w9': '确认中',
  'components.historyred.fxsgdj': '已赎回',
  'components.historyred.l43b8v': '收益',
  'components.historyred.3lfq3n': '赎回时间',
  'components.historyred.246k93': '赎回成功',
  'financial.index.s1t66k': '预估每日收益',
  'financial.index.2g4uc2': '到期收益',
  'components.editmodal.bm5h27': '赎回详情',
  'components.editmodal.d6bc00': '到期时间',
  'wallethistory.index.pe3x7i': '收益中',
  'wallethistory.index.2343kc': '已关闭',
  'components.simple.756g85': '1.綜述',
  'components.simple.08c0iq':
    '1.1 鏈上賺幣服務(「本服務」)是Hola Limited及其關聯公司（統稱為「幣趣」）推出的透過參與鏈上數位貨幣或其他數位資產(「此數位資產」)質押並獲取獎勵的產品,並根據某些條款和條件允許贖回。由幣趣負責產品的設計管理及本服務相關營運服務。為使用本服務，您應閱讀並遵守本鏈上賺幣使用者協議(「本協議」)。請您務必審慎閱讀、充分理解並同意各條款內容。',
  'components.simple.u4s2bs':
    '1.2除非您已閱讀並接受本協議所有條款，否則您無權或不應該使用本服務。您接受本協議的行為，以及您使用本服務的行為即視為您已閱讀、瞭解並同意本協議，本協議對您具有法律約束力。',
  'components.simple.7kr04z':
    '1.3本協議應補充幣趣服務條款。如果本協議中規定的任何條款或規定與幣趣服務條款發生任何衝突或不一致，應透過優先考慮本協議來解決此類衝突或不一致。幣趣服務條款中未被本協議修改的所有其他規定仍具有完全之效力。',
  'components.simple.i612nw': '2.定義',
  'components.simple.pd935k':
    '2.1.鏈上賺幣是指在區塊鏈網路中鎖定並持有數位資產以參與其共識鏈上協議並獲得獎勵的做法。鏈上賺幣可能涵蓋各種區塊鏈網路和技術。所涉及的具體功能和技術可能會根據相關的區塊鏈網路而有所不同。鏈上賺幣可以包括不同的區塊鏈網路和技術，它主要涉及參與共識鏈上協議，例如權益證明（「PoS」），以保護網路並驗證交易。',
  'components.simple.ur1w4y':
    '2.2 本服務是實現適用區塊鏈網路的PoS鏈上協議的解決方案，涉及鏈上質押以獲得獎勵。',
  'components.simple.quu3go': '2.3 與服務相關的其他定義包括：',
  'components.simple.6o03hk':
    '本金：為使用本服務而存入用戶帳戶的一定數量之數字資產。',
  'components.simple.114i84':
    '贖回：質押的該數位資產贖回需合理時間/日數提取至帳戶。',
  'components.simple.2588q2': '獎勵計算日: 具有第4.4(a) 節中的意義。',
  'components.simple.131i2v':
    '服務費:指用戶在申購或參與本服務時須向幣趣支付的費用，其中包括但不限於驗證節點佣金費、上下鏈礦工費（gasfee）等。',
  'components.simple.5y670y':
    '質押：本服務中，幣趣將用戶一定數量的數位資產凍結，並接收代幣或其他數位資產',
  'components.simple.6rlo4p':
    '作為PoS。在質押後，用戶在贖回期限前將無法贖回或提取資產。',
  'components.simple.7w4en4': 'T日：以UTC+8地域時間計算的自然日。',
  'components.simple.x831kf':
    '可變質押率: 具有第4.4(c)條中的含義以及用戶在認購本服務期間幣趣平台上顯示和引用的含義。',
  'components.simple.e612g4': '3.使用本服務的資格',
  'components.simple.vzb4x4': '3.1 您同意並確認：',
  'components.simple.v6iq7u': 'a.幣趣全部或部分質押您的數位資產；',
  'components.simple.53c448': 'b.幣趣在適用網絡上充當驗證者； 和',
  'components.simple.48k24p':
    'c. 將與您質押的該數位資產相關的任何投票權委託給幣趣；',
  'components.simple.08kj10': 'd.幣趣可不時就服務指定，',
  'components.simple.v3c613': '認購服務所需的最低質押數位資產數量；',
  'components.simple.jnp1we': '任何贖回期；',
  'components.simple.fafb6a': '任何贖回限制；及/或',
  'components.simple.t2cfu8': '任何認購限制。',
  'components.simple.4zwvtu': '3.2 您亦了解並確認：',
  'components.simple.nuu7bv':
    '您已同意遵守幣趣服務條款，同時您有資格且不被禁止使用其中定義的「服務」；',
  'components.simple.21xq44':
    '如果您正在以個人身分註冊使用本服務，為了有效接受本協議，您必須年滿18歲，是具有完全民事權利能力和民事行為能力的個人，具有簽署本協議所有必需的權力和能力，本協議一經簽署對您具有約束力；',
  'components.simple.18143v':
    '如果您是代表法人實體註冊使用本服務，為了有效接受本協議，您聲明並保證（i）此類法律實體根據適用法律合法成立並有效存在;（ii）您獲得該法律實體的正式授權，有權代表其行事。',
  'components.simple.izihsy':
    '您具備使用本服務相應的風險識別能力和風險承受能力，擁有投資非保本類數位資產產品的經驗和相關知識；',
  'components.simple.4a2i9n':
    '為使用本服務，您具備在互聯網上進行相應操作的能力；',
  'components.simple.dl4sk2':
    '本協議與您所在國家或地區的法律沒有衝突，您保證將遵守所在國家或地區的法律；',
  'components.simple.l2eqy3':
    '您是您幣趣平台帳戶中數位資產的合法所有者，並保證這些數位資產的來源是合法的。',
  'components.simple.830685': '3.3 您亦同意並承諾：',
  'components.simple.oowst3':
    '您不會將本服務用於任何非法目的、進行、發起或促進任何形式的市場操縱、價格操縱、內線交易、市場扭曲、任何其他惡意的市場行為或其他形式的非法行為，包括但不限於非法賭博，洗錢，欺詐，勒索，敲詐勒索，資料洩露，恐怖主義融資，其他暴力活動或任何法律禁止的商業行為。',
  'components.simple.26iroe':
    '3.4您了解並接受使用本服務可能對您產生的風險和您可能因此而遭受的相關損失，包括但不限於：',
  'components.simple.gcf336':
    '您所持有的數位資產質押後因您無法對該等被凍結的數位資產進行交易等操作而引起的不可交易風險，您願意承擔數位資產質押帶來的資金風險及您可能遭受的經濟損失；',
  'components.simple.tc17i7':
    '幣趣在頁面展示用戶預期收益為預期理論收益而非幣趣所保證或承諾的實際收益，實際收益可能與預期收益不符合，您的最終收益將以您收到的實際收益為準，幣趣將根據質押相關操作所產生的實際收益減去幣趣代用戶進行質押相關操作的成本、費用和支出（包括但不限於伺服器費用、電費等）減去幣趣收取的質押相關手續費的方式計算用戶的實際收益，幣趣不會對您可能收到的實際收益作出任何保證或承諾；',
  'components.simple.0ph46i':
    '數位資產及其衍生性商品的高價格波動可能會在短時間內造成重大或全部損失;',
  'components.simple.pj8gsy':
    '可能會發生技術異常，這可能會延遲或阻止您使用本服務或進行相關交易等操作，這可能導致您的數位資產損失;',
  'components.simple.c3wp4v': '可能發生市場異常，這可能導致您的數位資產損失；',
  'components.simple.4642wm':
    '為了維持市場的整體健康，幣趣需要不時添加、刪除或更改政策、本協議條款或其他相關服務協議或條款的相關規定。此類行為可能有利於或損害像您一樣的個人客戶。',
  'components.simple.49x4f1': '4.使用本服務',
  'components.simple.19w0n6':
    '4.1您可以透過幣趣客戶端下達質押、贖回等操作指令。一旦操作指令完成後，您將無法取消、撤銷或修改指令。',
  'components.simple.3cix8w':
    '4.2質押成功後您將自動委託並授權幣趣對被鎖帳戶中的一定數量的該數位資產進行包括但不限於投票、數位資產增益行為。',
  'components.simple.fkx5je':
    '4.3一旦該數位資產被質押或鎖定，幣趣將向您發行代幣或其他數位資產作為獎勵。該數位資產的使用應遵守幣趣在幣趣平台不時公佈的規則和條款，幣趣有權隨時自行決定允許、考慮、修改或取消任何出於任何目的對該數位資產在幣趣平台的任何使用。',
  'components.simple.73e58y': '4.4 質押獎勵',
  'components.simple.1l6su3':
    'a.質押獎勵將根據特定鏈上協議的累積時間開始累積，這可能會根據您質押的區塊鏈或網路而有所不同。獎勵的累積將從您認購服務之日（稱為「獎勵計算日」）開始，並按照鏈上協議指定的累積時間表進行。質押獎勵將根據一年365天過去的天數來計算。請注意，您認購的第一天不會獲得任何質押獎勵；您的獎勵將從第二天開始累積，與鏈上協議確定的每日累積時間一致;',
  'components.simple.60u1s3':
    'b.質押獎勵將根據特定鏈上協議的分配時間表分配到您的資金帳戶，該分配時間表可能會根據您質押的區塊鏈或網路而有所不同。這些獎勵將在每個獎勵計算日的次日記入您的資金帳戶，前提是與鏈上協議指定的分配時間一致。請注意，分發的時間和頻率受鏈上協議制定的規則和準則的約束；',
  'components.simple.kjo2z0':
    'c.參考質押獎勵產生當日的可變質押利率計算。為避免疑問，這種&ldquo;可變質押率&rdquo;，即在鏈上收益情況下每天可能變化的APY，應在幣趣平台上公佈，並且可能會不時發生變化；',
  'components.simple.trfh0l': 'd. 計算並四捨五入至小數點後8 位。',
  'components.simple.8q8zh6':
    '為避免疑問，幣趣可能會保留部分鏈上獎勵。該金額可能與質押獎勵不同。幣趣不保證您將按照您認購時顯示的可變質押率獲得質押獎勵。關於您認購時可能回報的任何陳述(i)僅是指示性估計而不是保證，(ii)基於可變質押率，該利率可能會發生變化，並且(iii)可能高於或低於連鎖獎勵。',
  'components.simple.3e5int': '4.5 贖回規則',
  'components.simple.mp83y6':
    'a.贖回的數位資產到達使用者帳戶需遵守鏈上協議的兌換規則，具體時間可能會有所不同。請注意，贖回時間可能會因數位資產類型而異。您瞭解並同意，在贖回時間至實際到帳時間之間，您將無法對所贖回的數位資產進行任何回饋、轉帳或其他指示。',
  'components.simple.8q351n':
    'b. 在贖回日當天或之後，幣趣可能只允許每天贖回有限數量的數位資產。',
  'components.simple.u54482':
    'c.一旦您提交了贖回請求，您將不再獲得與您請求贖回數位資產相關的任何質押獎勵。為避免疑問，將數位資產贖回之行為不符合任何質押獎勵的條件。一旦下達和/或確認贖回訂單，該訂單將不能被取消、撤銷或修改。',
  'components.simple.30rpa8':
    'd. 您應能在提出贖回請求後的合理時間內收到數位資產。',
  'components.simple.7x1wdy':
    'e.所有贖回請求均由幣趣按照先進先出（FIFO）的原則處理，並在當天結束時（UTC+8收市）由幣趣處理。',
  'components.simple.zr617j':
    'f.幣趣保留對處理數位資產贖回收取服務費的權利，該服務費應在幣趣平台上公佈，並可由幣趣不時修改。',
  'components.simple.o87n4p': 'g. 您瞭解並承認，您在贖回期間不會獲得質押獎勵。',
  'components.simple.46u02b':
    '4.6您將能夠在幣趣平台上查看您的帳戶歷史記錄。您必須仔細查看您的帳戶歷史記錄，如果您發現任何您不認識或您認為不正確的條目或交易，請盡快告知幣趣。幣趣可以隨時糾正您帳戶歷史中的任何錯誤，並保留取消或撤銷任何交易的權利：',
  'components.simple.7wu1il':
    'a.涉及或源自明顯錯誤（即任何明顯的錯誤、遺漏或錯誤引用（無論是幣趣或任何第三方的錯誤），包括幣趣任何代表在考慮當前市場和當前廣告報價後的錯誤報價，或任何資訊、來源、官方、官方結果或其他的任何錯誤）；或',
  'components.simple.a45t8y': 'b. 反映我們合理認為是正確或公平的交易細節。',
  'components.simple.6o3ll0':
    '4.7根據適用法律法規，幣趣可能需要與第三方分享有關您的帳戶和使用幣趣平台的資訊。您確認、同意並允許我們有權揭露此類資訊。',
  'components.simple.g9h47v': '4.8 服務費',
  'components.simple.4ky6gb':
    'a.幣趣可就向您提供本服務收取相應的服務費。除非幣趣平台另有說明,幣趣收取的服務費為您在本服務項下的實際收益（包括利息和獎勵）的指定百分比，而幣趣將從您的實際收益中扣除相應的服務費作為服務費的支付方式。',
  'components.simple.bvm49h':
    'b.您同意並授權幣趣，幣趣有權從您帳戶中的資產扣除應付的服務費，否則幣趣有權不向您提供本協議項下服務。',
  'components.simple.ccy320': '5.暫停或取消您的認購',
  'components.simple.db7x1a':
    '5.1.根據幣趣服務條款，幣趣可以停止、暫停或取消您對幣趣平台的訪問，對任何幣趣帳戶施加限制，暫停或取消您認購本服務的能力，或延遲某些交易：',
  'components.simple.v196mj':
    'a. 您沒有依照幣趣的要求提供額外資訊來驗證您的身分或資金來源；',
  'components.simple.18id34':
    'b.幣趣依照法院命令，以遵守任何適用法律法規（包括反洗錢或製裁法）；',
  'components.simple.26trc8': 'c.幣趣遵守政府部門或機構的任何指示或指示；',
  'components.simple.z13es2':
    'd.幣趣合理地確定您向幣趣提供的任何資訊是錯誤的、不真實的、過時的或不完整的；或',
  'components.simple.5h7y44':
    'e. 您沒有依照幣趣的要求而提供合理滿意或及時要求的額外資訊。',
  'components.simple.79g0s2': '責任限制',
  'components.simple.43vw58':
    '6.1您同意並接受：幣趣在頁面上展示用戶預期收益為預期理論收益而非幣趣所保證或承諾的實際收益，實際收益可能與預期收益不符合或低於預期收益，您最終的收益以實際收益為準。幣趣對預期收益或您可能收到的實際收益不做任何承諾或保證。',
  'components.simple.ad2oq2':
    '6.2您同意並接受：在您贖回時，部分幣種的到帳時間可能不同，以幣種頁面顯示為準，幣趣對任何因到帳時間的存在及其差異而導致的損失不承擔任何責任。',
  'components.simple.474yoj':
    '6.3您同意並接受：幣趣有權自行決定隨時修訂本協議的內容，如您對本協議或其任何更改存在誤解或未及時閱讀本協議的修訂，幣趣對您因此而造成的任何損失不承擔任何責任。',
  'components.simple.r52ir9':
    '6.4您同意並接受：當幣趣系統平台因下列狀況無法正常運作或導致服務中斷，使您無法使用各項服務或無法正常下達指令或進行相關交易等操作時，包括但不限於失敗、延遲、中斷、停止、系統無回應、系統反應緩慢或其他無法正常使用本服務的情況，幣趣不承擔損害賠償責任，此狀況包括但不限於：',
  'components.simple.3438jn': '幣趣平台公告之系統停機維護期間；',
  'components.simple.3w0472': '電信設備故障不能進行資料傳輸的；',
  'components.simple.2zleu8':
    '因颱風、地震、海嘯、洪水、瘟疫流行、停電、戰爭、動亂、政府行為、恐怖攻擊等不可抗力或其他意外事件之因素，造成幣趣平台系統障礙不能執行業務的；',
  'components.simple.i2y243':
    '因駭客攻擊、電腦病毒侵入或發作、電信部門技術調整或故障、網站升級、銀行方面的問題、因政府管製而造成的暫時性關閉等影響網路正常經營的原因而造成的服務中斷或延遲；',
  'components.simple.gn8q3r':
    '幣趣系統平台因電腦系統遭到破壞、癱瘓或無法正常使用而造成的服務中斷或延遲；',
  'components.simple.c466p0':
    '因為產業現有技術力量無法預測或無法解決的技術問題所造成的損失；',
  'components.simple.j1764b':
    '因第三方的過失或延誤而對您或其他第三方造成的損失；',
  'components.simple.p66n4w':
    '因法律法規或政府、監管或執法指令的變更而對您或其他第三方造成的損失；',
  'components.simple.q6x7d8':
    '因其他不可預見、無法避免且無法克服的不可抗力或意外事件而對您或其他第三方造成的損失。',
  'components.simple.jk53lr':
    '您同意並接受：上述原因可能導致的異常成交，行情中斷，以及其他可能的異常情況，幣趣有權根據實際情況不執行您的指示。您了解並同意幣趣對上述情形而對您造成的任何損失（包括但不限於任何直接或間接損失、實際損失或可得利益損失等任何損失）不承擔任何責任。',
  'components.simple.18dj4m':
    '6.5您同意並接受：對於您因本協議3.3條所述的風險而遭受的任何損失（包括但不限於任何直接或間接損失、實際損失或可得利益損失等任何損失），幣趣不承擔任何責任。',
  'components.simple.m7quwg': '技術免責聲明',
  'components.simple.o7k2x3':
    '7.1幣趣可能會不時暫停您對幣趣帳戶、幣趣平台、本服務和/或任何其他幣趣服務的訪問，以進行定期和緊急維護。幣趣將盡合理努力確保及時處理幣趣平台上的交易，但幣趣對完成處理所需的時間不作任何陳述或保證，這取決於幣趣無法控制的許多因素。',
  'components.simple.e4dxgz':
    '7.2儘管幣趣已盡合理努力更新幣趣平台上的信息，但幣趣不就幣趣平台上的內容，包括與本服務及任何其他幣趣服務相關的信息作出任何明示或暗示的陳述、保證或擔保,是準確的、完整的或最新的。',
  'components.simple.ebq62o':
    '7.3您應對於取得使用本服務或任何其他幣趣服務所需的資料網路存取權限負責任。您應負責獲取和更新訪問和使用本服務、任何幣趣服務、幣趣平台及其任何更新所需的兼容硬體或設備負責任。幣趣不保證任何幣趣服務或其任何部分將在任何特定硬體或設備上運行。幣趣服務可能會因使用網路和電子通訊而發生故障和延遲。',
  'components.simple.50o29k': '強制措施',
  'components.simple.8vo15i':
    '您同意並接受：幣趣嚴格禁止不公平的交易行為。如果您參與以下行為，幣趣保留自行決定對您的帳戶實施高階主管控制權或關閉您的帳戶的權利：',
  'components.simple.716s6i':
    '參與市場操縱、價格操縱、內幕交易、市場扭曲、任何其他惡意的市場行為或任何其他惡意的市場行為;',
  'components.simple.y7tut2':
    '可能透過利用本服務的漏洞或其他不合理的手段損害其他用戶或幣趣;',
  'components.simple.syju60': '參與幣趣認為對市場有害的任何其他活動；',
  'components.simple.tvfc1s': '參與違反任何法律法規的活動;',
  'components.simple.a24892':
    '為了消除任何對市場整體健康的不利影響，幣趣保留自行決定採取以下措施的權利，包括但不限於關閉您的帳戶，限製或取消指示。您了解並同意幣趣對因採取上述措施而給您帶來的任何損失（包括但不限於任何直接或間接損失、實際損失或可得利益損失等任何損失）不承擔任何責任。',
  'components.simple.reg83l': '賠償責任',
  'components.simple.61co62':
    '9.1您應該採取一切可能的措施保證幣趣免受任何來自於您使用本服務或其他與您的幣趣帳戶相關的行為的損害，否則您應該對幣趣承擔賠償責任。',
  'components.simple.q08qt1':
    '9.2您在此確認並同意，對因您違反本協議的行為、您不正當使用幣趣的服務的行為、您違反任何法律法規或侵犯任何第三方權利的行為、經您授權使用您的幣趣帳戶或登入幣趣網站、客戶端、系統（包括用於提供任何幣趣服務的網路和伺服器，且無論該等網站、客戶端和系統是由幣趣運營，或者是為了幣趣而運營，或者為了為向您提供幣趣服務而運營）的任何第三方的作為或不作為而引起的任何索賠、要求或主張（包括一切合理的法律費用或其他費用或支出），您應該進行抗辯、賠償，並盡一切努力使幣趣免受傷害。',
  'components.simple.vsc184': '其它',
  'components.simple.71o7m1':
    '10.1本協議內容同時包括幣趣的各項制度規範、其它本協議附件中的協議或規則、幣趣可能不斷發布的關於本服務的其他相關協議、規則等內容。上述內容一經正式發布，即為本協議不可分割的組成部分，您同意您將認真閱讀該等協議或規則並應遵守該等協議或規則的相關規定，您同意該等協議或規則對您具有法律約束力。上述內容與本協議有衝突的，以本協議為準。',
  'components.simple.drg5f9':
    '10.2如有必要，幣趣有權自行決定隨時單方變更本協議的內容，且幣趣無需事前通知您。如果幣趣單方變更了本協議的內容，變更的內容將立即在幣趣的網站上公佈，屆時請您及時查看並認真閱讀上述網站上的最新資訊。在上述變更公佈後，您了解並同意如果您繼續使用本服務，則視為您已經同意對本協議的變更並且該等變更對您具有法律約束力。如果您不同意本協議的變更，您應該停止使用本服務。',
  'components.simple.8i03ph':
    '10.3您應該認真閱讀本協議以及本協議第10.1條和10.2條所提到的任何構成本協議的內容的其他相關協議、規則等內容。如果您對上述內容有任何疑問，請透過SupportCenter聯繫我們的客戶服務，幣趣將提供合理且必要的說明。除非幣趣有其他要求，否則您應該透過電子郵件的方式聯繫幣趣,並透過電子郵件的方式和幣趣之間進行文件的傳輸（「正式溝通」）。您也可以非正式地透過電話聯繫幣趣（非正式溝通）。除非雙方有任何其他約定，否則任何正式溝通都應該使用英語進行。若任何英文版的溝通和非英文版的溝通不一致，則以英文版的溝通內容為準。若任何正式溝通或非正式溝通的內容與本協議的內容不一致，以本協議的內容為準。',
  'components.simple.x726be':
    '10.4如本協議任何條款被認定為無效、違法或不可執行，本協議其餘條款的有效性、合法性和可執行性均不應受任何形式的影響或削弱。但是，如果本協議的任何條款在任何司法管轄區的任何適用法律項下無效、違法或不可執行，則就該司法管轄區而言，該等條款應被視為相應修改以符合該法律的最低要求，或者，如果出於任何原因該等條款不被視為已修改，則該等條款僅在該無效性、違法性或對可執行性限制的範圍內無效、違法或不可執行，本協議中其餘條款的合法性、有效性或可執行性或該等條款在其他法域法律下的合法性、有效性或可執行性均不應受任何形式的影響。',
  'components.simple.34r56h':
    '10.5幣趣未有或延遲行使本協議項下任何權利或補救權不應視作放棄該等權利或補救權或構成對本協議的確認。幣趣對本協議的確認必須透過書面形式作出否則無效。任何一次或部份行使任何權利或補救不應防礙進一步或在其他情況行使該等權利，或行使任何其他權利或補救權。本協議下規定的權利和補救權均為附加性質，不排除法律規定的任何權利或補救。',
  'components.simple.8yus3r':
    '10.6本條款、您對本服務的使用、由本條款直接或間接引發的任何類型或性質的任何權利主張、反訴或爭議應受英格蘭和威爾斯法律管轄，並依其解釋，無需考慮其衝突法規則。&nbsp;',
  'components.simple.lo454v': '10.7 管轄和爭議解決',
  'components.simple.567hub':
    'a.您確認並同意，如果發生任何爭議、爭論、分歧或權利主張，包括本條款的存在、有效性、解釋、履行、違反或終止或由本條款引起的或與之相關的任何爭議（「爭議」），雙方應先根據屆時有效的《香港國際仲裁中心調解規則》將爭議提交香港國際仲裁中心（「HKIAC」）。',
  'components.simple.8gqyqi':
    'b.若在上述8.7條第(i)款中規定的調解請求提交後的九十（90）日內未透過簽署和解協議的方式解決爭議，則該等爭議應提交HKIAC，根據提交仲裁通知時有效的《香港國際仲裁中心機構仲裁規則》透過仲裁最終解決。本仲裁條款適用的法律應為中華人民共和國香港特別行政區（「香港」）法律。',
  'components.simple.v5k5kn':
    'c.您同意仲裁地為香港。仲裁人的人數應為三（3）名。幣趣應指定一（1）名仲裁員，您應指定一（1）名仲裁員。第三位仲裁員應由HKIAC的主席指定。該等仲裁員應自由選擇，各方在選擇仲裁員時不受任何指定名單的限制。仲裁程序應以英文進行。',
  'components.simple.pu2327':
    'd.您同意幣趣不應被要求提供文件的一般發現信息，而僅可被要求提供與爭議結果相關且對爭議結果有實質意義的特定的、經識別的文件。',
  'components.simple.w86687':
    'e.任何仲裁裁決應為終局裁決，對各方均具有約束力，並可在任何有管轄權的法院被強制執行。',
  'components.simple.bw64il':
    '10.8未經幣趣事先書面同意，您不得全部或部分轉讓、更新或讓本協議及本協議下的權利義務。幣趣有權透過向您發出書面通知的方式轉讓、更替或讓與本協議項下的任何權利或義務，該通知自送達之日起生效。',
  'components.simple.137yu5':
    '10.9本協議所有條款的標題僅為閱讀方便，本身並無實際涵義，不能作為本協議意義解釋的依據。',
  'components.simple.co7ivn':
    '10.10若本協議內容與其他語言的翻譯版本的內容有衝突，以英文版的本協議內容為準。',
  'components.simple.4i87f9': '10.11 本協議的最終解釋權歸幣趣所有。',
  'login.indexnew.e6y77j': '使用二维码登录',
  'login.indexnew.kv2529': '二维码已失效',
  'login.indexnew.6dd1e5': '立即刷新',
  'login.indexnew.jk723f': '在App上确认',
  'login.indexnew.msql2b': '使用',
  'login.indexnew.1ls8gl': '币趣手机App',
  'login.indexnew.4p37cx': '扫描二维码',
  'login.index.518our': '获取二维码',
  'login.country.cpp4yr': '选择区号',
  'login.country.o4efb0': '搜索',
  // 未翻译
  'login.country.dbb7i9': '选择地区',
  'enforcement.index.glq4x4': '币趣法务',
  'enforcement.index.j6do82':
    '我们的愿景是解决加密货币和区块链的设计挑战，为下一阶段的主流应用设定标准。',
  'enforcement.index.ns090u': '冻结令和披露令',
  'components.disclosure.c31xn2': '币趣使用条款',
  'components.disclosure.7m8c62': '币趣法庭命令门户',
  'components.disclosure.qhl2v9': '（适用于冻结令/披露令）',
  'components.disclosure.7xrmo3': '请仅在持有法庭命令的情况下使用本页面',
  'components.disclosure.dcq7c7':
    '欢迎访问币趣法律冻结令/披露令门户。法定代表人可以使用该门户，提交与币趣官网加密货币交易平台用户账户相关的冻结令和披露令。请确保您的订单与平台用户有关，而不是与单独交易平台有关',
  'components.disclosure.q5b601': '请在下页的表格中填写信息并提交证明文件。',
  'components.disclosure.j6h429':
    '由于该门户与币趣特定团队关联，因此只能用于提交最终冻结令和披露令。任何其他法庭命令或诉讼程序，都不应通过这个专用门户提交，而是应该发送到币趣相关实体的注册办事处。币趣不会审查或接受通过此门户送达的其他材料。',
  'components.disclosure.d71ku2':
    '请确保您提交的命令已确认收到，并且您已收到提交命令的参考信息。币趣将审查成功提交的所有案例，但是如果您未检查审查情况，币趣概不负责。',
  'components.disclosure.23iexl':
    '相关命令最好只提交一次。重复提交只会拖延您案件的处理进程。',
  'components.disclosure.8nr8z1':
    '币趣开通本门户并非意在一一遵守提交的任何冻结令和披露令。币趣冻结用户ID（账户）的能力或者向第三方披露与用户ID（账户）相关信息的能力，受适用法律和法规以及币趣用户权利（和币趣对用户的责任）约束。\r\n              币趣将审查通过此门户成功提交的任何冻结令或披露令以便确定其能否得到遵守执行。币趣完全保留现在拥有的或可能拥有的反对任何冻结令或披露令的权利。',
  'components.disclosure.27z7cv':
    '根据涉及的法院和实体不同，币趣还有权反对做出冻结令或披露令的相关法院的管辖权。币趣开通该门户供上传特定命令，并不表明币趣接受相关法院的管辖权，而且币趣也不放弃已经拥有或可能拥有的反对相关法院管辖权的任何权利。',
  'components.disclosure.4gd7w7':
    '对于币趣能够遵守执行且无须聘请外部律师即可处理的冻结令和/或披露令，币趣通常要求冻结令涉及的每位用户ID（账户）支付$500的合理合规费用；同样地，币趣要求披露令涉及的每位用户ID（账户）支付$500的合理合规费用。如果同一用户ID（账户）在两种命令或联合命令中都有涉及，那么币趣与该用户ID（账户）相关的合理合规费用则为$1,000。\r\n              使用该门户提交冻结令和/或披露令，即表明您同意以上述规定为基础向币趣支付合理的合规费用（但不应影响币趣根据己方绝对酌情决定权，认为有必要获得外部法律建议时获得已支付法律费用返还的权利）',
  'components.disclosure.3675e8':
    '我们同意此门户网站的上述使用条款（我们可能在适当时间发布有关法律命令的指南）。',
  'components.disclosure.5nw311': '请同意免责声明',
  'components.disclosure.13563x': '同意并提出请求',
  'components.formbox.ibm33u': '提交请求',
  'components.formbox.42c25u': '是',
  'components.formbox.6775e9':
    '目前，我们积压了大量请求。为了加快问题解决速度，请不要重新提交凭据。同时，我们强烈建议您访问币趣常见问题页面，快速找到答案：',
  'components.formbox.wh3bb8': '非常感谢您的配合',
  'components.formbox.4j461y': '请选择您的问题(法律)',
  'components.formbox.r716w9': '您用来接收回复(以及任何披露信息)的电子邮件地址',
  'components.formbox.4ipunl': '您的姓名',
  'components.formbox.fz9724': '公司名称',
  'components.formbox.5m129h': '法院所在国家/地区',
  'components.formbox.1t32m3': '签发法院',
  'components.formbox.x5c5m4': '多方复审听证会的答复日期',
  'components.formbox.kl233z': '索赔编号/参考资料',
  'components.formbox.339ddn': '待冻结账号(持有人姓名/用户ID/账号电子邮件)',
  'components.formbox.l9w4t3': '保密要求(是否可以通知用户)',
  'components.formbox.e8n33x': '请输入您的邮箱',
  'components.formbox.6fk7qq': '主题',
  'components.formbox.h6mhj9': '描述',
  'components.formbox.3qhc3v': '附件',
  'components.waiting.to28je':
    '您的资料已提交审核，审核结果将发送到您预留的邮箱，请保持关注。',
  'home.index.13w8x6': 'BasicEx 币趣产品简介',
  'home.index.sf95h6': '币趣钱包',
  'home.index.916ckf':
    '安全便捷的多链钱包，您可一键完成 充值 / 提现 / 买卖等操作，也可向币趣钱包用户免费转账/收款。',
  'home.index.yrsun1': '支持多条公链，例如：TRC20 / ERC20 / BSC / POLYGON。',
  'home.index.089fo6': '支持 USDT / ETH / BTC / TRX 等多种加密货币。',
  'home.index.r2831g': '开通钱包',
  'home.index.i886e8': '区块链支付',
  'home.index.c372qd':
    '希望接受 USDT 支付的商家可以使用币趣支付，通过币趣提供的区块链支付处理系统来促进这一过程。',
  'home.index.y3x6w2':
    '这是一种去中心化的支付平台，允许商家接受 USDT 交易。这类支付的主要好处之一是交易成本比传统支付方式低得多，但还有许多其他好处。',
  'home.index.yvfa61':
    '一键快捷买卖加密货币，也可自选商家在线进行交易，注册成为币趣钱包用户即刻享有。',
  'home.index.1n42v0': '币趣C2C平台提供担保交易服务，以确保您的交易安全无忧。',
  'home.index.81h875':
    '支持多种 加密货币 / 法币，无T+1限制，完成实名认证即可进行在线交易。',
  'home.index.g1p541': '一键购买',
  'localeAll.234asd': '请选择充值币种',
  'components.header-info.j6esv2': '新手教程',
  'resigter.verification.w175qb': '验证码无效，请点击重新获取',
  'qrrouter.wxtipcom.8axip0': '点击右上角选择',
  'qrrouter.wxtipcom.2wm33j': '在浏览器中打开',
  'qrrouter.index.wvls92': '请使用币趣App扫码',
  'qrrouter.index.tt12fs': '正在打开币趣App...',
  'qrrouter.index.id86f1': '打开币趣App',
  'qrrouter.index.u63nz6': '如果您尚未安装，请先下载',
  'qrrouter.index.4dxm5b': '下载币趣App',
  'recharge.index.9z9w2e': '钱包地址',
  'recharge.index.2f8wh2': '请勿向合约地址充值，否则将无法找回',
  'payment.index.0n2eod': '{0} 张优惠券',
  'payment.index.6c163p': '优惠券',
  'payment.index.3uy4pw': '选择优惠券',
  'payment.index.i15zz2': '满',
  'payment.index.16l0wm': '可用',
  'payment.index.u7tek5': '到期',
  'payment.index.64iqff': '不可用',
  'payment.index.hkk670': '不使用优惠券',
  'payment.index.zd1b88': '查看优惠券记录',
  'payment.index.59376r': '已使用',
  'payment.index.jbd8h6': '暂无记录',
  'payment.index.17zzsj': '已过期',
  'payment.index.ww2c87': '暂无可用',
  'navbar.index.u61ns9': '网络不可用，请检查您的网络设置',
  'balance.coupon.1nmcyv': '未使用',
  'down.index.u22o39': '备用下载：',
  'down.index.w2bq03': '点击下载',
  'down.index.Tutorial1': '添加H5到主屏幕,',
  'down.index.Tutorial2': '查看教程',
  'login.indexnew.uc1wu1': '信任当前设备',
};
