export default {
  'login.form.title': '幣趣登入',
  'register.form.title': '幣趣註冊',
  'login.form.email': '郵箱',
  'login.form.phone': '手機號碼',
  'login.account': '還沒帳號？',
  'register.account': '已有帳號？',
  'login.register': '立即註冊',
  'register.login': '立即登入',
  'login.forget': '忘記密碼？',
  'login.pass': '密碼',
  'login.form.userName.errMsg': '電子郵箱不能為空',
  'login.form.phone.errMsg': '手機號碼不能為空',
  'login.form.userName.placeholder': '請輸入電子郵箱',
  'login.form.phone.userName.placeholder': '請輸入手機號碼',
  'login.form.password.errMsg': '密碼不能為空',
  'login.form.password.placeholder': '請輸入密碼',
  'login.form.code.errMsg': '驗證碼不能為空',
  'login.form.code.placeholder': '請輸入驗證碼',
  'login.form.code.sent': '發送驗證碼',
  'login.form.code.time': '秒後重試',
  'login.form.read': '我已閱讀並同意幣趣的',
  'login.form.ServiceAgreement': '服務條款',
  'login.form.and': '和',
  'login.form.PrivacyPolicy': '隱私政策',
  'login.form.login': '登入',
  'register.form.register': '註冊',
  'login.form.code.tip': '驗證碼必須為6位數字',
  'login.form.code.read': '請閱讀並同意服務條款和隱私政策',
  'register.password.different': '兩次輸入的密碼不一致',
  'register.form': '兩次輸入的密碼不一致',
  'register.email': '請輸入正確的郵箱',
  'register.phone': '請輸入正確的手機號碼',
  'register.password': '密碼需要8位以上字符組成，必須包含數字及字母大小寫',
  'forget.password': '重設密碼',
  'forget.input': '請輸入您的電子郵箱/手機號碼',
  'login.user': '電子郵箱/手機號碼',
  'forget.input-err': '電子郵箱/手機號碼不能為空',
  'forget.newPassword': '新密碼',
  'forget.newPasswordCopy': '確認密碼',
  'forget.success': '密碼修改成功',
  'forget.login': '請使用新密碼登錄',
  'ver.number': '安全驗證',
"ver.OTP_CODE": "谷歌驗證APP",
  "ver.EMAIL_CODE": "郵箱驗證碼",
  "ver.PHONE_CODE": "手機驗證碼",
  "ver.EMAIL": "郵箱",
  "ver.PHONE": "手機",
  "ver.PIN_CODE": "支付PIN碼",
  "ver.code-err": "驗證碼不能為空",
  "ver.welcome": "歡迎回來",
  "res.text1": "創建個人帳戶"
};
