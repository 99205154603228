import { App } from 'vue';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { BarChart, LineChart, PieChart, RadarChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
  GraphicComponent,
} from 'echarts/components';
import Chart from './chart/index.vue';
import Breadcrumb from './breadcrumb/index.vue';
import Echarts from './echarts/index.vue';
import WarningDialog from './wariningdialog/index.vue'
import SafetyDialog from './safetydialog/index.vue'
import VerificationAll from './safetydialog/verification.vue'
import BingVerification from './safetydialog/bingVerification.vue'
import SafetydialogForter from './safetydialogForter/index.vue'
import SuccessDialog from './successdialog/index.vue'
import Empty from './emty/index.vue'
import SetDialog from './setdialog/index.vue'
import Tooltips from './tooltips/index.vue'
import TextTool from './textTool/index.vue'
import Alert from './alert/index.vue'
import Pages from './pages/index.vue'
// Manually introduce ECharts modules to reduce packing size

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  PieChart,
  RadarChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
  GraphicComponent,
]);

export default {
  install(Vue: App) {
    Vue.component('Chart', Chart);
    Vue.component('Breadcrumb', Breadcrumb);
    Vue.component('Echarts', Echarts);
    Vue.component('WarningDialog', WarningDialog);
    Vue.component('SafetyDialog', SafetyDialog);
    Vue.component('SafetydialogForter', SafetydialogForter);
    Vue.component('SuccessDialog', SuccessDialog);
    Vue.component('Empty', Empty);
    Vue.component('SetDialog', SetDialog);
    Vue.component('VerificationAll', VerificationAll);
    Vue.component('BingVerification', BingVerification);
    Vue.component('Tooltips', Tooltips);
    Vue.component('TextTool', TextTool);
    Vue.component('Alert', Alert);
    Vue.component('Pages', Pages);
  },
};
