import { ref } from 'vue';
import { useCardId } from '@/store/index';
import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';

const DASHBOARD: AppRouteRecordRaw = {
  path: '/identityauthentication',
  name: 'IdentityAuthentication',
  component: DEFAULT_LAYOUT,
  redirect: `/identityauthentication/idindex`,
  meta: {
    locale: 'navbar.index.4sr56d',
    requiresAuth: true,
    icon: 'icon-user',
    order: 2,
    hideInMenu: false,
    activeMenu: 'IdentityAuthentication',
    title: '身份認證',
  },
  children: [
    {
      path: 'idIndex',
      name: 'IdIndex',
      component: () =>
        import('@/views/dashboard/identityauthentication/index.vue'),
      meta: {
        locale: 'menu.dashboard.workplace',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: 'personal',
      name: 'Personal',
      component: () =>
        import('@/views/dashboard/identityauthentication/personal.vue'),
      meta: {
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: 'idCard',
      name: 'IdCard',
      component: () =>
        import('@/views/dashboard/identityauthentication/idcard.vue'),
      meta: {
        requiresAuth: false,
        hideInMenu: true,
      },
    },
    {
      path: 'idSuccess',
      name: 'IdSuccess',
      component: () =>
        import('@/views/dashboard/identityauthentication/idsuccess.vue'),
      meta: {
        requiresAuth: false,
        hideInMenu: true,
      },
    },
    {
      path: 'verification',
      name: 'Verification',
      component: () =>
        import('@/views/dashboard/identityauthentication/verification.vue'),
      meta: {
        requiresAuth: false,
        hideInMenu: true,
      },
    },
    {
      path: 'remake',
      name: 'Remake',
      component: () =>
        import('@/views/dashboard/identityauthentication/remake.vue'),
      meta: {
        requiresAuth: false,
        hideInMenu: true,
      },
    },
    {
      path: 'uploaded',
      name: 'Uploaded',
      component: () =>
        import('@/views/dashboard/identityauthentication/uploaded.vue'),
      meta: {
        requiresAuth: false,
        hideInMenu: true,
      },
    },
  ],
};

export default DASHBOARD;
