<template>
  <a-modal
    v-model:visible="successDialogVisible"
    :footer="false"
    :header="false"
    :width="width"
    :height="height"
    :closable="false"
  >
    <div class="dialog-tips">
      <img v-if="flag !== 0" :src="getImage('success')" alt="success" />
      <p v-if="flag !== 0" class="tit">{{ title || $t("accountsecurity.index.6968fe") }}</p>
      <p v-if="flag !== 0 || message !== ' '">{{ message }}</p>
      <slot name="text"></slot>
      <div class="footer-btn">
        <a-button
          type="primary"
          size="large"
          :style="{ width: btnWidth, height: '40px' }"
          @click="handleSuccess"
          >{{ dialogSuccessBtnText || $t("fish.changefish.dteu66") }}</a-button
        >
      </div>
    </div>
  </a-modal>
</template>

<script lang="ts" setup>
  import { ref, toRefs, watch } from 'vue';
  import usePub from '@/utils/pub-use';

  const getImage = usePub;
  const props = defineProps({
    dialogSuccessVisible: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '320px',
    },
    height: {
      type: String,
      default: '310px',
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '你已开启了快捷提币，下次提币的时候将会生效',
    },
    dialogSuccessBtnText: {
      type: String,
      default: '',
    },
    flag: {
      type: Number,
    },
    btnWidth: {
      type: String,
      default: '220px',
    },
  });

  const refProps = toRefs(props);
  const emits = defineEmits(['update:dialogSuccessVisible', 'successClick']); // 此处需写'update'
  const successDialogVisible = ref(false);
  watch(refProps.dialogSuccessVisible, (val, old) => {
    successDialogVisible.value = val;
  });
  watch(successDialogVisible, (val, old) => {
    emits('update:dialogSuccessVisible', val); // 此处的update亦要写
  });

  const handleSuccess = () => {
    emits('successClick', true);
    successDialogVisible.value = false;
  };
</script>

<style lang="less" scoped>
  .dialog-tips {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 68px;
      height: 68px;
    }
    .tit {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
      font-size: 18px;
    }
    p {
      margin-top: 0;
      margin-bottom: 10px;
      font-size: 12px;
      color: rgba(102, 102, 102, 1);
    }
    .footer-btn {
      display: flex;
      justify-content: center;
      button {
        border-radius: 5px;
      }
      :deep(.arco-btn-secondary) {
        margin-right: 14px;
      }
    }
  }
</style>
