<template>
  <a-modal
    v-model:visible="setVisible"
    :footer="false"
    :header="false"
    title-align="start"
    width="500px"
    height="360px"
    :closable="false"
    :mask-closable="false"
    :body-style="{
      paddingLeft: '30px',
    }"
  >
    <div class="safe-header">
      <p>{{ title }}</p>
      <icon-close @click="handleCancel" />
    </div>
    <p class="header-tips">{{ message }}</p>
    <slot name="name"></slot>
    <slot name="language"></slot>
    <slot name="image"></slot>
    <slot name="letter"></slot>
    <div class="footer-btn">
      <a-button size="large" @click="handleCancel">{{
        dialogCloseBtnText || $t("address.batchaddaddress.5g5at6")
      }}</a-button>
      <a-button
        type="primary"
        size="large"
        :loading="isLoading"
        @click="handleSuccess"
        >{{ dialogSuccessBtnText || $t("fish.changefish.dteu66") }}</a-button
      >
    </div>
  </a-modal>
</template>

<script lang="ts" setup>
  import { ref, toRefs, watch } from 'vue';

  const props = defineProps({
    dialogSetVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '编辑昵称',
    },
    message: {
      type: String,
      default: '为您的个人资料设置自定义昵称。',
    },
    dialogCloseBtnText: {
      type: String,
      default: '',
    },
    dialogSuccessBtnText: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  });

  const refProps = toRefs(props);
  const emits = defineEmits(['update:dialogSetVisible', 'myClick']); // 此处需写'update'
  const setVisible = ref(false);
  watch(refProps.dialogSetVisible, (val, old) => {
    setVisible.value = val;
  });
  watch(setVisible, (val, old) => {
    emits('update:dialogSetVisible', val); // 此处的update亦要写
  });

  const handleCancel = () => {
    emits('myClick', false);
    setVisible.value = false;
  };

  const handleSuccess = () => {
    emits('myClick', true);
  };
</script>

<style lang="less" scoped>
  // 弹窗
  .header-tips {
    font-size: 12px;
    color: var(--color-text-3);
  }

  .footer-btn {
    display: flex;
    align-items: center;
    margin-top: 40px;
    button {
      width: 200px;
      height: 40px;
      border-radius: 5px;
    }
    :deep(.arco-btn-secondary) {
      margin-right: 36px;
    }
  }
  .safe-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px 0;
    font-size: 16px;
    & > .arco-icon {
      cursor: pointer;
      color: var(--color-text-3);
    }
    & > p {
      margin: 0;
    }
  }
</style>
