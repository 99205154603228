import axios from 'axios';
import qs from 'qs'
import type { RouteRecordNormalized } from 'vue-router';
import { UserState } from '@/store/modules/user/types';

export interface LoginData {
  username: string;
  password: string;
}

export interface LoginRes {
  token: string;
}
// 登录预检查
export function loginRRe(data: any) {
  return axios.post('/uc/security/login/precheck',data)
}
// 登录
export function login(data: any,params:any) {
  // axios.interceptors.request.use(
  //   (config: any) => {
  //     config.headers.Authorization = 'Basic cGMud2FsbGV0OnBjLndhbGxldA=='
  //     // config.headers['Content-Type'] =  'application/json'
  //     config.headers['Content-Type'] =  'application/x-www-form-urlencoded'
  //     return config;
  //   }
  // );
  const query = `?code=${params.code}&method=${params.method}&sessionId=${params.sessionId}&username=${params.username}`
  return axios.post<LoginRes>(`/auth/oauth2/token${query}`,qs.stringify(data));
}
// 注册预检查
export function resigterRRe(data: any) {
  return axios.post('/uc/security/register/precheck',data)
}
// 注册极验接口
export function resigterJy(data: any) {
  return axios.post('/uc/geetest/geeTestCheck',data)
}
// 注册
export function resigterZs(data:any) {
  return axios.post('/uc/customer/register',data)
}
// 忘记密码验证方式查询
export function getVerification( params:object ) {
  return axios.get('/uc/security/getVerificationMethod', { params } )
}
// 密码重置预检
export function resetpass( data:object ) {
  return axios.post('/uc/security/resetpwd/precheck', data )
}
// 密码重置-发送验证码
export function forgetPwd( params:object ) {
  return axios.get('/uc/message/forgetPwd/sendMsg', { params } )
}
// 密码重置-安全验证
export function forgetPwdCheck( data:object ) {
  return axios.post('/uc/customer/forgetPwdCheck', data )
}
// 密码重置-确定
export function resetPwd( data:object ) {
  return axios.post('/uc/customer/resetPwd', data )
}
// 客户个人信息查询
export function personalInfor() {
  return axios.get<UserState>('/uc/customer/profile')
}
// 查询当前用户绑定了哪些验证方式
export function getVerificationMethodApi() {
  return axios.get('/uc/customer/getVerificationMethod')
}
/**
 * 拖拽框验证
 */
 export function initGeetest (data:any) {
   return axios.post('/uc/geetest/geeTestCheck',data)
}
// 发送验证码
export function getCode() {
  return axios.get('')
}

// export function logout() {
//   return axios.post<LoginRes>('/api/user/logout');
// }

// export function getUserInfo() {
//   return axios.post<UserState>('/api/user/info');
// }

export function getMenuList() {
  return axios.post<RouteRecordNormalized[]>('/api/user/menu');
}
// 获取法币接口
export function currencyListAll() {
  return axios.get('/uc/common/currency/listAll');
}

// 登出
export function logout() {
  return axios.delete('/auth/token/logout');
}
// 24小时是否修改了密码
export function checkWithdrawAuth() {
  return axios.get('/uc/asset/withdrawal/checkWithdrawAuth');
}

// ios下载链接
export function infoIos() {
  return axios.get('/uc/app/download/ios/info')
}
// 获取是否跳转到App
export function getAvailable(params: any) {
  return axios.get(`/uc/app/available/get`, { params });
}