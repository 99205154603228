<template>
  <div class="safe-tips">
    <div class="safe-header">
      <p>安全验证</p>
    </div>
    <p>为了您的账户安全，请完成以下安全验证操作</p>
    <a-form ref="formRef" :model="formDialog" layout="vertical">
      <a-form-item
        v-if="msg?.GOOGLE"
        field="google"
        label="币趣/谷歌验证码"
        :hide-asterisk="true"
        :rules="[{ required: true, message: 'google is required' }]"
        :validate-trigger="['change', 'input']"
      >
        <a-input v-model="formDialog.google" :style="{ height: '50px' }" />
        <template #extra>
          <div>请输入谷歌验证器的6位验证码</div>
        </template>
      </a-form-item>
      <a-form-item
        v-if="msg?.EMAIL"
        field="email"
        label="邮箱验证码"
        :hide-asterisk="true"
        :rules="[{ required: true, validator: validateGetCode }]"
        :validate-trigger="['change', 'input']"
      >
        <a-input v-model="formDialog.email" :style="{ height: '50px' }"
          ><template #suffix>
            <a-button
              v-show="show"
              verification
              type="text"
              class="btn-safe"
              :loading="isLoading1"
              @click="sendCode(1)"
            >
              获取验证码</a-button>
            <a-button
              v-show="!show"
              type="text"
              class="btn-safe"
            >
              {{ count }} s 后获取</a-button
            >
          </template></a-input
        >
        <template #extra>
          <div>请输入发送到邮箱6位验证码</div>
          <!-- <div>请输入发送到******手机的6位验证码</div> -->
        </template>
      </a-form-item>
      <a-form-item
        v-if="msg?.PHONE"
        field="phone"
        label="手机验证码"
        :hide-asterisk="true"
        :rules="[{ required: true, validator: validateGetCode }]"
        :validate-trigger="['change', 'input']"
      >
        <a-input
          v-model="formDialog.phone"
          :style="{ height: '50px', width: '100%', borderRadius: '5px' }"
        >
          <template #suffix>
            <a-button
              v-show="hide"
              verification
              type="text"
              class="btn-safe"
              :loading="isLoading2"
              @click="sendCode(2)"
            >
              获取验证码</a-button>
            <a-button
              v-show="!hide"
              type="text"
              class="btn-safe"
            >
              {{ count }} s 后获取</a-button
            >
          </template>
        </a-input>
        <template #extra>
          <div>请输入发送到手机6位验证码</div>
          <!-- <div>请输入发送到******手机的6位验证码</div> -->
        </template>
      </a-form-item>
    </a-form>
  </div>

  <a-button
    class="safe-btn"
    type="primary"
    :disabled="isSafeDisabled"
    :loading="isLoading"
    size="large"
    @click="handleSuccess"
  >
    提交
  </a-button>
</template>

<script setup lang="ts">
  import { ref, reactive, toRefs, watch } from 'vue';
  import { forgetPwd } from '@/api/user';
  import { sendAntCodeMsgApi } from '@/api/setfish';
  import { stringify } from 'qs';

  const props = defineProps({
    dialogSafetyVisible: {
      type: Boolean,
      default: false,
    },
    ident: {
      type: String,
    },
    msg: {
      type: Object,
    },
    user: {
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  });

  const refProps = toRefs(props);

  const emits = defineEmits(['update:dialogSafetyVisible', 'confirmClick']); // 此处需写'update'
  const safeVisible = ref(false);
  watch(refProps.dialogSafetyVisible, (val, old) => {
    safeVisible.value = val;
  });
  watch(safeVisible, (val, old) => {
    emits('update:dialogSafetyVisible', val); // 此处的update亦要写
  });

  const handleSuccess = () => {
    emits('confirmClick', true);
  };

  type FormObject = {
    google: string;
    email: string;
    phone: string;
    method: string;
    emailSessionId?: string;
    phoneSessionId?: string;
  };
  const formDialog = reactive<FormObject>({
    google: '',
    email: '',
    phone: '',
    method: '',
    emailSessionId: '',
    phoneSessionId: '',
  });

  const isSafeDisabled = ref<boolean>(true);
  const validateGetCode = (value: any, callback: any) => {
    const regEmailPass = /^\d{6}$/;
    if (!regEmailPass.test(value)) {
      callback('验证码必须为6位数字');
      isSafeDisabled.value = true;
    } else {
      callback();
      if (value !== undefined) {
        isSafeDisabled.value = false;
      }
    }
  };

  const show = ref(true);
  const hide = ref(true);
  const timer = ref();
  const TIME_COUNT = ref<number>(30);
  const count = ref();
  // 发送验证码
  const Verification = (num: number) => {
    if (!timer.value) {
      count.value = TIME_COUNT.value;
      // eslint-disable-next-line no-unused-expressions
      num === 1 ? (show.value = false) : (hide.value = false);
      timer.value = setInterval(() => {
        if (count.value > 0 && count.value <= TIME_COUNT.value) {
          // eslint-disable-next-line no-plusplus
          count.value--;
        } else {
          // eslint-disable-next-line no-unused-expressions
          num === 1 ? (show.value = true) : (hide.value = true);
          clearInterval(timer.value);
          timer.value = null;
        }
      }, 1000);
    }
  };
  const isLoading1 = ref<boolean>(false)
  const isLoading2 = ref<boolean>(false)
  const sendCode = (val: number) => {
    if (props.ident === 'forterPass') {
      if (val === 1) {
        isLoading1.value = true
        forgetPwd({ method: 'EMAIL', userName: props.user }).then((res) => {
          isLoading1.value = false
          formDialog.emailSessionId = res.data.emailSessionId;
          Verification(1);
        });
      } else {
        isLoading2.value = true
        forgetPwd({ method: 'PHONE', userName: props.user }).then((res) => {
          isLoading2.value = false
          formDialog.phoneSessionId = res.data.phoneSessionId;
          Verification(2);
        });
      }
    }
  };

  defineExpose({
    formDialog,
  });
</script>

<style lang="less" scoped>
  .safe-tips {
    width: 400px;
    .safe-header {
      margin: 0 0 20px 0;
      font-size: 30px;
      font-weight: 550;
    }
    & > p {
      font-size: 16px;
      color: var(--color-text-1);
      margin-bottom: 30px;
    }
  }
  .btn-safe{
    padding: 0;
    color: rgb(var(--warning-5)) !important;
  }
  .safe-btn {
    width: 400px;
    height: 40px;
    border-radius: 5px;
    margin-top: 20px;
  }
</style>
