export default {
  'home.coin': 'Easily Manage Cryptocurrency Assets',
  'home.wallet': 'Cryptocurrency Wallet / BasicEx Pay / Buy Coins in One Click',
  'home.there': 'All in',
  'home.Basic': 'BasicEx',
  'home.userName': 'Register with Email/Phone Number',
  'home.down': 'Download Now',
  'home.free': 'Free ',
  'home.collection': 'Collection and Payment',
  'home.frees': '0 Transaction Fees',
  'home.sell': 'Buy/Sell BCNY, Convenient and Fast.',
  'home.pay': 'BasicEx users can transfer and receive funds without any fees.',
  'home.register': 'Register Wallet',
  'home.payment': 'BasicEx Pay',
  'home.introduce': 'Introduction',
  'home.tip1':
    'BasicEx Pay is a contactless and borderless cryptocurrency payment system launched by BasicEx. It is secure, reliable, and trustworthy.',
  'home.tip2':
    'Open payment services for your company, enabling online and offline sales with USDT and other mainstream cryptocurrencies.',
  'home.see': 'See More',
  'home.basicWallet': 'BasicEx Wallet',
  'home.chain': 'Multi-chain Wallet',
  'home.manage': 'Easily Manage Multi-chain Cryptocurrency Assets',
  'home.interface': 'Payment Gateway',
  'home.api': 'Platform-wide API/SDK Intelligent Payment Gateway',
  'home.C2C': 'C2C Market',
  'home.buy': 'Buy/Sell Cryptocurrency Assets in One Click',
  'home.help': 'Need Help',
  'home.chat1': '24/7 Chat Support',
  'home.chat2':
    'Get 24/7 chat support from friendly customer service representatives who are dedicated to serving you.',
  'home.chat3': 'Chat Online',
  'home.chat4': 'Frequently Asked Questions',
  'home.chat5':
    'View frequently asked questions to get detailed explanations of specific features.',
  'home.chat6': 'Learn More',
  'footer.about.text1': 'About Us',
  'footer.about.text2': 'Career Opportunities',
  'footer.about.text3': 'Business Contact',
  'footer.about.text4': 'Service Agreement',
  'footer.about.text5': 'Privacy Policy',
  'footer.about.text6': 'Risk Warning',
  'footer.about.text7': 'Announcement Center',
  'footer.about.text8': 'Cookie Preferences',
  'footer.product.text1': 'C2C Trading',
  'footer.service.text1': 'Become a Merchant',
  'footer.service.text2': 'Acceptance Merchant Partnership',
  'footer.service.text3': 'Agent Promotion',
  'footer.help.text1': 'Help Center',
  'footer.help.text2': 'Online Customer Support',
  'footer.help.text3': 'Suggestions and Feedback',
  'footer.help.text4': 'BasicEx Legal',
  'footer.self.text1': 'Reset Password',
  'footer.self.text2': 'Unlock Account',
  'footer.self.text3': 'Reset Phone Verification',
  'footer.self.text4': 'Modify Email',
  'footer.self.text5': 'Reset Google Authenticator',
  'footer.self.text6': 'Deposit Not Received',
  'footer.self.text7': 'Request to delete account',
  'footer.product.text': 'product',
  'footer.service.text': 'service',
  'footer.help.text': 'help',
  'footer.self.text': 'self-service',
  'down.down1': 'Start Trading Anytime & Anywhere',
  'down.down2': '',
  'down.down3': 'Download the BasicEx App',
  'down.down4': 'iOS Beta Version',
  'down.down4-1': 'To install the beta version, you need to',
  'down.down5': 'Android',
  'down.down6': 'Google Play',
  'down.down7': 'Google',
  'down.down8': 'Authenticator',
  'down.down9':
    'Google Authenticator generates a two-step verification code for you, your BasicEx account is more secure.',
  'down.down10': 'App Store',
  'down.down12': 'Google Play',
  'down.down13': 'Scan to Download',
  'down.footerText1': 'US MSB number',
};
