export default {
  path: 'https://basicex.com/activity',
  name: 'ActivityC',
  meta: {
    locale: 'navbar.index.fsd231',
    icon: 'icon-gift',
    requiresAuth: true,
    order: 0,
  },
};
