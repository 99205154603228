import localeLogin from '@/views/login/locale/zh-TW';
import localeHome from '@/views/down/locale/zh-TW';
import localeSettings from './zh-TW/setting';
import localeAll from './zh-TW/all';
import localePri from './zh-TW/pri';

export default {
  'menu.required': '请填写所有必填项',
  'menu.locale': '繁體中文',
  'menu.dark': '黑夜模式',

  'menu.balance': '餘額',
  'menu.recharge': '儲值',
  'menu.buycoins': '買幣',
  'menu.financial': '理財',
  'menu.transferaccounts': '轉賬',
  'menu.withdrawal': '提現',
  'menu.c2corder': 'C2C订单',
  'menu.payhis': 'PAY历史交易',
  'menu.loginOut': '登出',
  'menu.wallethistory': '钱包历史记录',
  'menu.title': 'BasicEx全球領先的加密貨幣支付服務商',

  'navbar.action.locale': '切换为繁體中文',
  'public.next': '下一步',
  'public.submit': '提交',
  ...localeSettings,
  ...localeLogin,
  ...localeHome,
  ...localeAll,
  ...localePri,
};
