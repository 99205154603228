import CryptoJS from 'crypto-js';
// 十六位十六进制数作为密钥
const SECRET_KEY = CryptoJS.enc.Latin1.parse('1234567890987654');
// 十六位十六进制数作为密钥偏移量
const SECRET_IV = SECRET_KEY;
// const SECRET_IV = CryptoJS.enc.Latin1.parse("1234567890987654");
export default function useCryptoJs() {
  /**
   * 加密方法
   * @param data
   * @returns {string}
   */

  function encryptData(data: any) {
    if (typeof data === 'object') {
      try {
        // eslint-disable-next-line no-param-reassign
        data = JSON.stringify(data);
      } catch (error) {
        console.log('encrypt error:', error);
      }
    }
    const dataHex = CryptoJS.enc.Utf8.parse(data);
    const encrypted = CryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
      iv: SECRET_IV,
      mode: CryptoJS.mode.CFB,
      padding: CryptoJS.pad.NoPadding,
    });
    return encrypted.ciphertext.toString();
  }

  /**
   * 解密方法
   * @param data
   * @returns {string}
   */
  function decryptData(data: any) {
    const encryptedHexStr = CryptoJS.enc.Hex.parse(data);
    const str = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    const decrypt = CryptoJS.AES.decrypt(str, SECRET_KEY, {
      iv: SECRET_IV,
      mode: CryptoJS.mode.CFB,
      padding: CryptoJS.pad.NoPadding,
    });
    const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
  }
  return {
    decryptData,
    encryptData,
  };
}
