import { defineStore } from 'pinia';

interface cardId {
    cardIdCode?: boolean;
}

const useCardId = defineStore('cardid', {

  state: (): cardId => ({
    cardIdCode:false,
  }),

  getters: {
    getUserList(state) {
        return state.cardIdCode;
    },
  },

  actions: {
    // changeState(num){
    //   this.$patch(state=>{cardIdCode:num})
    // }
  },
});

export default useCardId;
