import axios from 'axios';



// 获取支持的双因素验证
export function verificationList() {
  return axios.get(`/uc/account/verification/verification-methods`);
}

// 验证pin码或验证码是否正确
export function verifyCode(data: object) {
  return axios.post('/uc/account/verification/verifyCode', data);
}

// 发送验证码
export function sendCode(data: object) {
  return axios.post('/uc/account/verification/sendCode', data);
}