import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';

const DASHBOARD: AppRouteRecordRaw = {
  path: '/closeAccount',
  name: 'CloseAccount',
  component: DEFAULT_LAYOUT,
  redirect: '/closeAccount',
  meta: {
    locale: 'navbar.index.n35329',
    requiresAuth: true,
    icon: 'icon-safety',
    order: 1,
    hideInMenu: true,
    activeMenu: 'AccountSecurity',
    title: '賬戶安全',
  },

  children: [
    {
      path: '',
      name: 'CloseAccount',
      component: () =>
        import('@/views/dashboard/accountsecurity/disableacc/index.vue'),
      meta: {
        locale: 'menu.overview',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
  ],
};

export default DASHBOARD;
