export default {
  'home.coin': '加密资产 尽在掌控',
  'home.wallet': '加密货币钱包/币趣支付/一键买币',
  'home.there': '尽在',
  'home.Basic': 'BasicEx 币趣',
  'home.userName': '电子邮箱/手机号码注册',
  'home.down': '立即下载',
  'home.free': '免费',
  'home.collection': '收付款',
  'home.frees': '0手续费',
  'home.sell': '购买/出售BCNY,方便快捷。',
  'home.pay': '币趣用户转账/收款，均无需缴纳任何费用。',
  'home.register': '注册钱包',
  'home.payment': '币趣支付',
  'home.introduce': '介绍',
  'home.tip1':
    '币趣支付是币趣推出的一种无接触、无国界的加密货币支付系统，安全可靠，值得信赖。',
  'home.tip2':
    '为您的公司开启支付服务，以USDT和其他主流币种开展线上和线下销售。',
  'home.see': '查看更多',
  'home.basicWallet': 'BasicEx 币趣钱包',
  'home.chain': '多链钱包',
  'home.manage': '轻松管理多链货币资产',
  'home.interface': '支付接口',
  'home.api': '全平台API/SDK智能化支付接口',
  'home.C2C': 'C2C市场',
  'home.buy': '一键买/卖加密货币资产',
  'home.help': '需要帮助',
  'home.chat1': '24/7聊天支持',
  'home.chat2': '获取24/7的聊天支持，友好的客服代表竭诚为您提供服务。',
  'home.chat3': '在线聊天',
  'home.chat4': '常见问题',
  'home.chat5': '查看常见问题，获取特定功能的详细说明。',
  'home.chat6': '了解更多',
  'footer.about.text1': '关于我们',
  'footer.about.text2': '职业机会',
  'footer.about.text3': '商务联络',
  'footer.about.text4': '服务协议',
  'footer.about.text5': '隐私说明',
  'footer.about.text6': '风险提示',
  'footer.about.text7': '公告中心',
  'footer.about.text8': 'Cookie偏好设置',
  'footer.product.text1': 'C2C交易',
  'footer.service.text1': '成为商户',
  'footer.service.text2': '承兑商加盟',
  'footer.service.text3': '代理推广',
  'footer.help.text1': '帮助中心',
  'footer.help.text2': '在线客服',
  'footer.help.text3': '建议与反馈',
  'footer.help.text4': '币趣法务',
  'footer.self.text1': '重置密码',
  'footer.self.text2': '解禁账户',
  'footer.self.text3': '重置手机验证',
  'footer.self.text4': '修改邮箱',
  'footer.self.text5': '重置 Google authenticator',
  'footer.self.text6': '充值未到账',
  'footer.self.text7': '请求删除账户',
  'footer.product.text': '产品',
  'footer.service.text': '服务',
  'footer.help.text': '帮助',
  'footer.self.text': '自助服务',
  'down.down1': '随时随地开启',
  'down.down2': '交易',
  'down.down3': '下载币趣客户端，所有交易尽在手中。',
  'down.down4': 'iOS内测版',
  'down.down4-1': '安装内测版，需先',
  'down.down5': '下载自 Android',
  'down.down6': '下载自 Google Play',
  'down.down7': '谷歌',
  'down.down8': '验证器',
  'down.down9':
    '谷歌验证器会为您生成两步验证码。借助两步验证，让您的币趣账户更加安全。',
  'down.down10': '下载自 App Store',
  'down.down12': '下载自 Google Play',
  'down.down13': 'iOS&Android扫码下载',
  'down.footerText1': '美国MSB牌照编号',
};
