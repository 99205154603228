<template>
  <div>
    <div ref="myRef" :style="{ width: '100%', height: '500px' }"></div>
  </div>
</template>

<script lang="ts">
  import { ref, onMounted } from 'vue';
  import * as echarts from 'echarts';

  export default {
    props: {
      option: Object,
    },
    setup(props) {
      const myRef = ref<any>(null);

      onMounted(() => {
        setTimeout(() => {
          // eslint-disable-next-line no-use-before-define
          drawChart();
        }, 20);
      });

      // 绘制折线图
      const drawChart = () => {
        // 初始化echarts实例
        const Chart = echarts.init(myRef.value);
        // 父组件传来的实例参数
        Chart.setOption(props.option as never);
        window.addEventListener('resize', () => {
          Chart.resize();
        });
      };
      return {
        myRef,
        drawChart,
      };
    },
  };
</script>
