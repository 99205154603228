import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';

const DASHBOARD: AppRouteRecordRaw = {
  path: '/paymentmethod',
  name: 'PaymentMethod',
  component: DEFAULT_LAYOUT,
  redirect: '/paymentmethod/payindex',
  meta: {
    locale: 'navbar.index.l461w3',
    requiresAuth: true,
    icon: 'icon-Method',
    order: 3,
    hideInMenu: false,
    activeMenu: 'PaymentMethod',
    title: '收款方式',
  },
  children: [
    {
      path: 'payIndex',
      name: 'PayIndex',
      component: () => import('@/views/dashboard/paymentmethod/index.vue'),
      meta: {
        locale: 'menu.overview',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: 'bankcard',
      name: 'BankCard',
      component: () =>
        import('@/views/dashboard/paymentmethod/bankcard/index.vue'),
      meta: {
        locale: 'menu.overview',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: 'weChat',
      name: 'WeChat',
      component: () =>
        import('@/views/dashboard/paymentmethod/wechat/index.vue'),
      meta: {
        locale: 'menu.overview',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: 'aliPay',
      name: 'AliPay',
      component: () =>
        import('@/views/dashboard/paymentmethod/alipay/index.vue'),
      meta: {
        locale: 'menu.overview',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
  ],
};

export default DASHBOARD;
