<template>
  <div class="content">
    <a-spin :loading="loading" tip="LOADING..." :style="{width: '100%'}">
      <div class="left-pc">
        <a-form ref="formRef" :model="form" :style="{width:'100%',marginBottom: '8px'}" @submit-success="handleToNext">
          <!-- 谷歌 -->
          <p v-if="OTPList.method === OTP_CODE && payType !== 'OTP_CODE'" class="code-title">{{ $t(`ver.${OTPList.method}`) }}</p>
          <a-form-item 
            v-if="OTPList.method === OTP_CODE && payType !== 'OTP_CODE'"
            field="OTP_CODE"
            hide-label
            :rules="[{ required: true, message: $t('login.form.code.errMsg')},{validator: validateGetCode }]"
            :validate-trigger="['change', 'input','blur']"
          >
            <a-input  
              v-model="form.OTP_CODE"
              :class="inputOTPCol ? 'userFocus': 'userInput'"
              @focus="() => inputOTPCol = true" 
              @blur="() => inputOTPCol = false" 
            >
            </a-input>
            <template #extra>
              <p class="tip">{{$t('safetydialog.index.284434')}}{{ $t(`ver.${OTP_CODE}`) }}{{$t('safetydialog.index.uo4t31')}}</p>
            </template>
          </a-form-item>
          <!-- 邮箱 -->
          <p v-if="emailList.method === 'EMAIL' && payType !== 'EMAIL'" class="code-title">{{ $t(`ver.${emailList.method}_CODE`) }}</p>
          <a-form-item 
            v-if="emailList.method === 'EMAIL' && payType !== 'EMAIL'"
            field="EMAIL"
            hide-label
            :rules="[{ required: true, message: $t('login.form.code.errMsg')},{validator: validateGetCode }]"
            :validate-trigger="['change', 'input','blur']"
          >
            <a-input  
              v-model="form.EMAIL"
              :class="inputEmailCol ? 'userFocus': 'userInput'"
              @focus="() => inputEmailCol = true" 
              @blur="() => inputEmailCol = false" 
            >
              <template #suffix>
                  <a-button v-show="emailData.hideCode" type="text" class="codeBox" @click="handleSendCode('EMAIL')" >{{$t('safetydialog.index.zj74uq')}}</a-button>
                  <a-button v-show="!emailData.hideCode" disabled type="text" class="codeBox" 
                    >{{ emailData.count }}{{$t('safetydialog.index.193173')}}</a-button
                  >
                </template>
            </a-input>
            <template #extra>
              <p class="tip">{{$t('safetydialog.index.284434')}}{{ $t(`ver.${emailList.method}`) }}{{ emailList.account }}{{$t('safetydialog.index.4yhgsc')}}</p>
            </template>
          </a-form-item>
          <!-- 手机号 -->
          <p v-if="phoneList.method === 'PHONE' && payType !== 'PHONE'" class="code-title">{{ $t(`ver.${phoneList.method}_CODE`) }}</p>
          <a-form-item 
            v-if="phoneList.method === 'PHONE' && payType !== 'PHONE'"
            field="PHONE"
            hide-label
            :rules="[{ required: true, message: $t('login.form.code.errMsg')},{validator: validateGetCode }]"
            :validate-trigger="['change', 'input','blur']"
          >
            <a-input  
              v-model="form.PHONE"
              :class="inputPhoneCol ? 'userFocus': 'userInput'"
              @focus="() => inputPhoneCol = true" 
              @blur="() => inputPhoneCol = false" 
            >
              <template #suffix>
                  <a-button 
                  v-show="phoneData.hideCode" 
                  type="text" 
                  class="codeBox" 
                  @click="handleSendCode('PHONE')" >{{$t('safetydialog.index.zj74uq')}}</a-button>
                  <a-button 
                  v-show="!phoneData.hideCode" 
                  disabled 
                  type="text"
                  class="codeBox" 
                    >{{ phoneData.count }}{{$t('safetydialog.index.193173')}}</a-button
                  >
                </template>
            </a-input>
            <template #extra>
              <p class="tip">{{$t('safetydialog.index.284434')}}{{ $t(`ver.${phoneList.method}`) }}{{ phoneList.account }}{{$t('safetydialog.index.4yhgsc')}}</p>
            </template>
          </a-form-item>
          <!-- <p class="goRegister">安全验证不可用?</p> -->
          <!-- 确定按钮 -->
          <a-form-item v-if="!loading" hide-label>
            <a-button class="subBtn" html-type="submit" :loading="isLoading" type="primary">{{ $t('public.submit') }}</a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-spin>
  </div>
</template>

<script setup lang="ts">
  import { ref, reactive, toRefs, watch,computed } from 'vue';
  import { verificationList,verifyCode,sendCode } from '@/api/verification'
  import useCryptoJs from '@/hooks/cryptojs';
  import usePub from '@/utils/pub-use';
  import { normalizeProps, useMachine } from '@zag-js/vue';
  import * as pinInput from '@zag-js/pin-input';
  import { sendBingMsgApi } from '@/api/phone';
  import getArrDifference from '@/utils/diff';
  import { Notification } from '@arco-design/web-vue';
import { useI18n } from 'vue-i18n';
import TabItem from '../tab-bar/tab-item.vue';

  const { encryptData } = useCryptoJs();
  const { t } = useI18n()
  const props = defineProps({
    ident: {
      type: String,
    },
    payType: {
      type: String,
    },
  });
  const refProps = toRefs(props);
  const emits = defineEmits(['confirmClick']); // 此处需写'update'
  const safeVisible = ref(false);

  const validateGetCode = (value: any, callback: any) => {
    const regEmailPass = /^\d{6}$/;
    if (!regEmailPass.test(value)) {
      callback(t('safetydialog.index.wks78g'));
    } else {
      callback();
    }
  };

  const getImage = usePub;
  const form = reactive<any>({})
  const OTP_CODE = 'OTP_CODE' // 谷歌验证器标识
    // 输入框动画
  const inputOTPCol = ref(false)
  const inputEmailCol = ref(false)
  const inputPhoneCol = ref(false)
  // 获取支持的双因素验证列表
  const OTPList = reactive<any>({})
  const emailList = reactive<any>({})
  const phoneList = reactive<any>({})
  const allPayType = reactive<any>([])
  const loading = ref(true)
  const getVerificationList = () => {
    verificationList().then((res:any) => {
      if(res.code === '0000') {
        loading.value = false
        res.data.methods.forEach((item: any) => {
          if(item.method !== 'PIN_CODE') {
            allPayType.push(item.method)
          }
          if(item.method === OTP_CODE) {
            Object.assign(OTPList,item)
          }
          if(item.method === 'EMAIL') {  
            Object.assign(emailList,item) 
          }
          if(item.method === 'PHONE') {
            Object.assign(phoneList,item)
          }
        })
      }
    })
  }
  getVerificationList()
  
  // pin码的确认按钮
  const isLoading = ref(false)
  // 发送验证码
  const sessionIdList = reactive<any>({})
  const emailData = reactive<any>({
    timer: null,
    TIME_COUNT: 60,
    hideCode: true,
  });
  const phoneData = reactive<any>({
    timer: null,
    TIME_COUNT: 60,
    hideCode: true,
  });
  const handleSendCode = (v:string) => {
    if(v === 'EMAIL') {
      if(!emailData.timer){
        emailData.count = emailData.TIME_COUNT;
        emailData.hideCode = false;
        emailData.timer = setInterval(() => {
          if (emailData.count > 0 && emailData.count <= emailData.TIME_COUNT) {
            emailData.count -= 1;
          } else {
            emailData.hideCode = true;
            clearInterval(emailData.timer);
            emailData.timer = null;
          }
        }, 1000);
      }
      sendCode({
        receiverType: 'EMAIL',
        source: props?.ident
      }).then((res:any) => {
        if(res.code === '0000') {
          sessionIdList.EMAIL = res.data
        }
      })
    }else if(v === 'PHONE') {
      if(!phoneData.timer){
        phoneData.count = phoneData.TIME_COUNT;
        phoneData.hideCode = false;
        phoneData.timer = setInterval(() => {
          if (phoneData.count > 0 && phoneData.count <= phoneData.TIME_COUNT) {
            phoneData.count -= 1;
          } else {
            phoneData.hideCode = true;
            clearInterval(phoneData.timer);
            phoneData.timer = null;
          }
        }, 1000);
      }
      sendCode({
        receiverType: 'PHONE',
        source: props?.ident
      }).then((res:any) => {
        if(res.code === '0000') {
          sessionIdList.PHONE = res.data
        }
      })
    }
  }
  // 关闭弹窗清空输入框
  const formRef = ref()
  const errList = ref<any>([])
  const handleCloseModal = (v:string) => {
    safeVisible.value = false
    formRef.value.resetFields()
    formRef.value.clearValidate()
    errList.value.length = 0
    emailData.hideCode = true;
    phoneData.hideCode = true;
  }
  
  // 安全验证的确认按钮
  const arrList = ref<any>([])
  const emitsList = reactive<any>([])
  const results = ref<any>([])
  const handleToNext = async() => {
    arrList.value.length = 0
    results.value = allPayType.filter((item:any) => item !== props.payType)
    const toAxios = (arr: any) => {
      arr = Array.from(new Set(arr));
      arr.forEach((item:string) => {
        verifyCode({
          // eslint-disable-next-line no-nested-ternary
          sessionId: item === 'EMAIL'?sessionIdList.EMAIL:item === 'PHONE'?sessionIdList.PHONE:'',
            // eslint-disable-next-line no-nested-ternary
          code: item === 'EMAIL'?form.EMAIL:item === 'PHONE'?form.PHONE:form.OTP_CODE,
          method: item,
          source: props?.ident 
        })
        .then((res:any) => {
          if(res.code === '0000') {
            arrList.value.push(item)
            emitsList.push(res.data)
            errList.value = errList.value.filter((err:any) => err !== item)
            if(arr.length === arrList.value.length) { 
              emits('confirmClick', emitsList); 
            }
          }else {
            errList.value.push(item)
          }
        })       
      })
    }
    if(errList.value.length > 0) {
      toAxios(errList.value)
      return
    }
    toAxios(results.value)
  }

  defineExpose({
    handleCloseModal,
  })
</script>

<style lang="less" scoped>
 .content{

    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center; // 需要改
    align-items: center;
    .left-pc {
      width: 100%;
      .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: -10px;
        height: 60px;
        .arco-icon-close {
          cursor: pointer;
          font-size: 18px;
          color: var(--color-neutral-8);
        }
      }
      p {
        color: var(--color-neutral-8);
      }
      .tip{
        margin: 0 0 28px 0;
        font-size: 12px;
      }
      .userInput,.userFocus{
        width: 100%;
        height: 48px;
        background: var(--color-bg-1);
      }
      button{
        width: 100%;
        height: 48px;
      }
      .subBtn{
        margin-top: 40px;
      }
      .userFocus{
        border: 1px solid rgba(16, 147, 255, 1);
      }
      .userInput{
        border: 1px solid var(--color-neutral-2);
      }
      h1 {
        margin-bottom: 32px;
      }
      .code-title{
        font-size: 15px;
        color: var(--color-neutral-10);
        font-weight: 550;
      }
      .goRegister{
        color: rgba(16, 147, 255, 1);
        cursor: pointer;
        // margin-top: 10px;
        margin-bottom: 18px;
      }
    }
    .right-qrCode{
      width: 180px;
    }
    .codeBox {
      width: 120px;
      height: 100%;
      cursor: pointer;
      position: relative;
      left: 12px;
      border-radius: 3px;
      color: rgb(var(--warning-5)) !important;
    }
    .codeBox:hover{
      color: rgb(var(--warning-4));
    }
  }
  :deep(.arco-form-item){
    margin-bottom: -10px !important;
  }
</style>
