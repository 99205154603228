export default {
    "login.privacy.kc5k20": "隱私聲明-幣趣",
    "login.privacy.6tvu9q": "最後更新：2022 年 9 月 29 日",
    "login.privacy.w84kwf": "幣趣（“幣趣”、“我們”或“我們”）致力於保護客戶的隱私，我們以最嚴肅的態度履行我們的數據保護責任。",
    "login.privacy.h5v8f9": "本隱私聲明描述了幣趣如何通過引用本隱私聲明的幣趣網站和應用程序收集和處理您的個人信息。幣趣是指由幣趣網站（其域名包括但不限於",
    "login.privacy.jt2p1r": "）移動應用程序、客戶端、小程序和其他為提供幣趣服務而開發的應用程序，包括生態系統內獨立運營的平台、網站和客戶端。“幣趣運營者”是指運營幣趣的各方，包括但不限於提供幣趣服務並對該等服務負責的法人、非法人組織和團隊。本隱私聲明中使用的“幣趣”包括幣趣運營商。",
    "login.privacy.y2v430": "本隱私聲明適用於我們在幣趣和幣趣運營商的平台、網站和部門中開展的所有個人信息處理活動。",
    "login.privacy.136m95": "如果您是我們服務的客戶或用戶，則本隱私聲明與任何業務條款和其他合同文件一起適用，包括但不限於我們可能與您達成的任何協議。",
    "login.privacy.8d2hxu": "如果您不是我們服務的相關利益相關者、客戶或用戶，但正在使用我們的網站，則本隱私聲明也與我們的 Cookie 聲明一起適用於您。",
    "login.privacy.j38v8e": "因此，本通知應與我們的 Cookie 通知一起閱讀，後者提供了有關我們在網站上使用 cookie 的更多詳細信息。我們的 Cookie 通知可在",
    "login.privacy.bwm3z8": "此處",
    "login.privacy.6iqyl1": "訪問。",
    "login.privacy.7pp7ut": "1. Basicex 與您的關係",
    "login.privacy.j24gj3": "Hola Limited 是一家註冊於 2004 N Wahsatch Ave Colorado Springs 的公司，是在全球提供 BasicEx 服務時收集的個人信息的數據控制者。",
    "login.privacy.899gsg": "但是，根據您的合法居住地，某些其他幣趣實體可能會參與我們向您提供服務所必需的處理活動，例如了解您的客戶（“KYC”）活動。例如，如果您居住在法國，Basicex France SAS 將負責運行 KYC 檢查。這些幣趣實體可以充當您個人信息的控制者，並根據本隱私聲明使用這些信息。",
    "login.privacy.90x098": "2. 幣趣收集和處理哪些個人信息？為什麼幣趣要處理我的個人信息？我們使用個人信息的法律依據是什麼？",
    "login.privacy.9sy521": "幣趣收集和處理哪些個人信息？",
    "login.privacy.7461j8": "- 電子郵件地址;",
    "login.privacy.4too95": "- 名稱;",
    "login.privacy.33lk4c": "- 性別;",
    "login.privacy.3339bi": "- 出生日期;",
    "login.privacy.qhie5b": "- 家庭地址;",
    "login.privacy.cw62k4": "- 電話號碼;",
    "login.privacy.1u0160": "- 國籍;",
    "login.privacy.y6wwu0": "- 設備編號;",
    "login.privacy.q3j1v2": "- 您的視頻記錄和照片；",
    "login.privacy.n97555": "- 交易信息；",
    "login.privacy.mp8l0n": "- 用於將您的計算機連接到互聯網的互聯網協議 (IP) 地址；",
    "login.privacy.88f199": "- 您的設備或計算機的登錄名、電子郵件地址、密碼和位置；",
    "login.privacy.q8tmgw": "- 幣趣服務指標（例如，技術錯誤的發生、您與服務功能和內容的交互，以及您的設置偏好）；",
    "login.privacy.887248": "- 版本和時區設置；",
    "login.privacy.51168r": "- 交易歷史；",
    "login.privacy.7x7mre": "- 來自其他來源的信息：我們可能會從其他來源收到關於您的信息，例如來自征信機構的信用歷史信息；",
    "login.privacy.l61c4m": "- 有關您的活動的信息，我們可能出於營銷和廣告目的處理有關您的行為和活動的信息。",
    "login.privacy.t4t7ib": "為什麼幣趣要處理我的個人信息？",
    "login.privacy.g5blre": "- 交易服務。我們使用您的個人信息來處理您的訂單，並與您就訂單和服務進行溝通；",
    "login.privacy.iol4kr": "- 與您溝通。我們使用您的個人信息與您就幣趣服務進行溝通；",
    "login.privacy.bhw512": "- 我們收集和處理身份信息和敏感個人數據（詳見第一節），以遵守適用法律法規和反洗錢法律法規規定的了解您的客戶（“KYC”）義務；",
    "login.privacy.7h61j0": "- 提供、排除故障並改進幣趣服務。我們使用您的個人信息來提供功能、分析性能、修復錯誤並提高幣趣服務的可用性和有效性。",
    "login.privacy.tc88dj": "欺詐預防和信用風險。我們處理個人信息以防止和檢測欺詐和濫用行為，以保護我們的用戶、幣趣服務和其他人的安全。我們也可能使用評分方法來評估和管理信用風險。",
    "login.privacy.lxo82j": "法律義務；遵守我們在適用法律法規和反洗錢法律法規下的法律義務",
    "login.privacy.ngs86z": "我們的合法利益和我們用戶的利益，例如，當我們檢測並防止欺詐和濫用以保護我們的用戶、我們自己或他人的安全時；",
    "login.privacy.371qe3": "- 改善我們的服務。我們處理個人信息以改善我們的服務並為您提供更好的用戶體驗；",
    "login.privacy.rrenk2": "- 推薦和個性化。我們使用您的個人信息來推薦您可能感興趣的功能和服務，確定您的偏好並為您提供個性化的幣趣服務體驗；",
    "login.privacy.4s94lw": "我們使用個人信息的法律依據？",
    "login.privacy.d2n7p9": "當我們向您提供產品或服務，或與您就這些產品或服務進行溝通時，",
    "login.privacy.8b3j23": "合同的履行",
    "login.privacy.v8vr6v": "。這包括我們使用您的個人信息接受和處理訂單以及處理付款的情況。",
    "login.privacy.l08s6u": "法律義務；",
    "login.privacy.76y1f8": "遵守我們在適用法律法規和反洗錢法律法規下的法律義務。",
    "login.privacy.2262a5": "當我們徵求您的同意以出於我們向您傳達的特定目的處理您的個人信息時，您的同意。當您同意為特定目的處理您的個人信息時，您可以隨時撤回您的同意，我們將停止為該目的處理您的個人信息。撤回同意不影響撤回前基於同意的處理的合法性。",
    "login.privacy.76ht3q": "我們的合法利益",
    "login.privacy.24o658": "和我們用戶的利益，例如，當我們檢測並防止欺詐和濫用以保護我們的用戶、我們自己或他人的安全時；",
    "login.privacy.3nh31t": "這包括我們使用您的個人信息接受和處理訂單以及處理付款的情況。",
    "login.privacy.1d6w06": "遵守我們在適用法律法規和反洗錢法律法規下的法律義務",
    "login.privacy.538m2i": "我們改善服務的",
    "login.privacy.5qqw26": "合法利益；",
    "login.privacy.wu2u8m": "當我們徵求您的同意以出於我們向您傳達的特定目的處理您的個人資訊時，您的同意。當您同意為特定目的處理您的個人資訊時，您可以隨時撤回您的同意，我們將停止為該目的處理您的個人資訊。撤回同意不影響撤回前基於同意的處理的合法性。",
    "login.privacy.7c1542": "3. 儿童可以使用币趣服务吗？",
    "login.privacy.o3744e": "币趣不允許 18 歲以下的任何人使用币趣服務，也不會故意收集 18 歲以下兒童的個人資訊。",
    "login.privacy.62766e": "4. Cookie 和其他標識符呢？",
    "login.privacy.p36e61": "我們使用 cookie 和類似工具來增強您的使用者體驗、提供我們的服務、加強我們的行銷工作並了解客戶如何使用我們的服務，以便我們進行改進。根據您所在地區的適用法律，瀏覽器上的 cookie 橫幅將告訴您如何接受或拒絕 cookie。我們的 cookie 政策的副本可",
    "login.privacy.me8fdq": "在此處",
    "login.privacy.gvb101": "取得。",
    "login.privacy.om6043": "5. 币趣會分享我的個人資訊嗎？",
    "login.privacy.n7411y": "如果我們認為共享您的個人資料符合與您或我們之間的任何合同關係、適用法律、法規或法律程序，或受其要求，我們可能會與第三方（包括其他币趣實體）共享您的個人數據。在與其他币趣實體共享您的個人資訊時，我們將盡最大努力確保此類實體受本隱私聲明的約束，或遵循至少與本隱私聲明中所述的保護措施同等的做法。例如，如果您居住在法國，Basicex France SAS 將負責運行 KYC 檢查。",
    "login.privacy.3be551": "我們還可能與以下人員共享個人資訊：",
    "login.privacy.wxfkk9": "i.第三方服務提供商：",
    "login.privacy.l5dwy9": "我們聘請其他公司和個人代表我們履行職能。示例包括分析數據、提供行銷協助、處理付款、傳輸內容以及評估和管理信用風險。這些第三方服務提供商只能訪問執行其功能所需的個人資訊，但不得將其用於其他目的。此外，他們必須根據我們的合同協議並僅在適用的數據保護法允許的情況下處理個人資訊。",
    "login.privacy.03ph7w": "ii.法律機構：",
    "login.privacy.u71msj": "法律或法院可能要求我們向相關監管機構、執法機構和/或其他主管機構披露有關您的某些信息或我們可能與您進行的任何約定。我們將在法律規定我們有義務這樣做的範圍內向法律當局披露有關您的信息。我們可能還需要共享您的信息，以執行或應用我們的合法權利或防止欺詐。",
    "login.privacy.s3ag79": "iii.業務轉讓：",
    "login.privacy.egltmf": "隨著我們繼續發展業務，我們可能會出售或購買其他業務或服務。在此類交易中，用戶信息通常是轉讓的商業資產之一，但仍受任何先前存在的隱私聲明中所做承諾的約束（當然，除非用戶另行同意）。此外，在不太可能發生的情況下，Basicex 或其幾乎所有資產都被第三方收購，用戶信息將成為轉移的資產之一。",
    "login.privacy.8w483x": "iiii.保護币趣和其他人：",
    "login.privacy.0882m2": "當我們認為發布帳戶和其他個人資訊對於遵守法律或我們的監管義務是適當的時，我們會發布帳戶和其他個人資訊；執行或應用我們的使用條款和其他協議；或保護币趣、我們的使用者或其他人的權利、財產或安全。這包括與其他公司和組織交換信息以防止欺詐和降低信用風險。",
    "login.privacy.u0i119": "6. 個人資訊的國際傳輸",
    "login.privacy.j5rk66": "為促進我們的全球運營，币趣可能會將您的個人資訊轉移到歐洲經濟區（“EEA”）、英國和瑞士以外的地區。歐洲經濟區包括歐盟國家以及冰島、列支敦士登和挪威。歐洲經濟區以外的轉移有時被稱為“第三國轉移”。",
    "login.privacy.v8krs1": "我們可能會在我們遍布全球的附屬機構、第三方合作夥伴和服務提供商內部傳輸您的個人數據。如果我們打算將個人數據傳輸到 EEA 以外的第三國或國際組織。币趣採取適當的技術、組織和合同保障措施（包括標準合同條款），以確保此類傳輸符合適用的數據保護規則，除非個人資訊的傳輸目的地國家/地區已由歐盟委員會提供足夠水平的保護。",
    "login.privacy.5m9f17": "我們還依賴歐盟委員會的決定，他們承認歐洲經濟區以外的某些國家和地區確保對個人資訊提供足夠的保護。這些決定被稱為“充分性決定”。我們根據日本充分性決定將個人數據傳輸到日本。",
    "login.privacy.h7q0ka": "7. 我的信息有多安全？",
    "login.privacy.383i4y": "我們在設計系統時考慮到您的安全和隱私。我們已採取適當的安全措施，以防止您的信息意外丟失、以未經授權的方式使用或訪問、更改或披露。我們致力於通過使用加密協議和軟件在傳輸和存儲過程中保護您的個人資訊的安全。我們維護與收集、存儲和披露您的個人資訊相關的物理、電子和程序保護措施。此外，我們將訪問您的個人資訊的權限限制為那些有業務需要知道的員工、代理人、承包商和其他第三方。",
    "login.privacy.gw69m4": "我們的安全程序意味著我們可能會要求您驗證您的身份，以保護您免遭未經授權訪問您的帳戶密碼。我們建議為您的币趣帳戶使用其他線上帳戶未使用的唯一密碼，並在您使用完共享計算機後註銷。",
    "login.privacy.2t39my": "8. 關於廣告",
    "login.privacy.hos1v1": "為了讓我們為您提供最佳的使用者體驗，我們可能會出於定位、建模和/或分析以及行銷和廣告的目的與我們的行銷合作夥伴共享您的個人資訊。您有權隨時反對出於直接行銷目的處理您的個人資訊（請參閱下文第 9 节）。",
    "login.privacy.q1meb8": "9. 我有什麼權利？",
    "login.privacy.3nsijb": "根據適用法律",
    "login.privacy.dfog7r": "，如下所述，您擁有多項與您的隱私和個人資訊保護相關的權利。您有權要求訪問、更正和刪除您的個人資訊，並要求數據可移植性。您也可以反對我們處理您的個人資訊，或要求我們在某些情況下限制處理您的個人資訊。此外，當您同意我們為特定目的處理您的個人資訊時，您可以隨時撤回您的同意。如果您想行使您的任何權利，請通過",
    "login.privacy.403iph": "聯繫我們。這些權利在某些情況下可能會受到限制 - 例如，我們可以證明我們有處理您的個人數據的法律要求。",
    "login.privacy.5qenjk": "訪問權：",
    "login.privacy.yr1p1w": "您有權獲得有關您的個人資訊已被處理的確認信息，並有權獲得其副本以及與其處理相關的某些信息；",
    "login.privacy.w1vnb1": "更正權：",
    "login.privacy.wvx4mx": "您可以要求對不準確的個人資訊進行更正，也可以進行補充。您還可以隨時更改您帳戶中的個人資訊。",
    "login.privacy.9ozu8u": "刪除權：",
    "login.privacy.1xzk2i": "在某些情況下，您可以要求刪除您的個人資訊；",
    "login.privacy.f64j4q": "反對權：",
    "login.privacy.9368cj": "基於您的特定情況，您可以反對處理您的個人資訊。例如，您有權反對我們依賴合法利益的處理方式或出於直接行銷目的處理您的數據。",
    "login.privacy.4nb9mx": "限制處理的權利：",
    "login.privacy.do76t6": "在某些情況下，您有權暫時限制我們處理您的個人資訊，但必須有有效理由。如果需要進行法律索賠辯護或符合適用法律的其他例外情況，我們可能會繼續處理您的個人資訊。",
    "login.privacy.5etd9r": "攜帶權：",
    "login.privacy.ie4k6t": "在某些情況下，您可以要求以結構化、常用和機器可讀的格式接收您提供給我們的個人資訊，或者在可能的情況下，我們代表您將您的個人資訊直接傳達給另一個資料控制者。",
    "login.privacy.rni2et": "撤回您同意的權利：",
    "login.privacy.pa1i0e": "對於需要您同意的處理，您有權隨時撤回您的同意。行使此權利不影響基於撤回前所給予的同意處理的合法性。",
    "login.privacy.v41p5p": "向相關資料保護機構投訴的權利：",
    "login.privacy.nsno48": "我們希望能夠滿足您對我們處理您的個人資訊方式的任何疑問。但是，如果您有未解決的疑慮，您也有權向愛爾蘭資料保護委員會或您居住、工作或認為發生資料保護違規行為所在地的資料保護機構投訴。",
    "login.privacy.fuiu7u": "如果您對我們如何收集和處理您的個人資訊有任何疑問或異議，請聯繫",
    "login.privacy.o71e3y": "10. 币趣會保留我的個人資訊多久？",
    "login.privacy.l4i2u8": "我們會保留您的個人資訊，以便您繼續使用币趣服務，只要是為了實現本隱私聲明中所述的相關目的，以及法律可能要求的，例如出於稅收和會計目的，合規與反洗錢法，或以其他方式傳達給您。",
    "login.privacy.7f6wp3": "11. 聯繫方式",
    "login.privacy.3bhpoy": "您可以通過",
    "login.privacy.se4tue": "聯繫我們的數據保護專員，他將努力解決您在收集和處理您的個人資訊方面遇到的任何問題。",
    "login.privacy.ax6wwg": "12. 通知和修訂",
    "login.privacy.yq6115": "如果您對币趣的隱私有任何疑慮，請聯繫我們，我們將盡力解決。您還有權聯繫您當地的資料保護機構。",
    "login.privacy.jps2x6": "我們的業務會定期更改，我們的隱私聲明也可能會更改。您應該經常查看我們的網站以查看最近的更改。除非另有說明，否則我們當前的隱私聲明適用於我們擁有的有關您和您的帳戶的所有信息。",
    "login.service.r4q32g": "币趣使用條款",
    "login.service.l1xogv": "上次更新：2023年1月16日",
    "login.service.xnz39r": "本币趣使用條款由您(以下稱為“您”或“您的”)和币趣經營者(定義見下)訂立。您訪問、下載、使用或點擊“我同意”币趣(定義見下)提供的任何币趣服務(定義見下)，即表示您確認已經閱讀、理解並接受本使用條款(以下簡稱為“本使用條款”)規定的所有條款和條件，以及我們在",
    "login.service.6r4yx1": "公布的隱私聲明。此外，在使用币趣服務的部分功能時，您可能還需遵守適用於這些功能的特定附加條款和條件。",
    "login.service.wwkcj2": "請仔細閱讀本使用條款，因為其規範您對幣趣服務的使用。",
    "login.service.qtu4q7": "本使用條款包含一些重要條文，包括要求所有索賠通過具有法律約束力的仲裁解決的仲裁規定。",
    "login.service.24oyp4": "仲裁條款載於以下第十條“爭議解決：仲裁地點、仲裁、集體訴訟放棄”中。與任何資產一樣，數字貨幣（定義見下）的價值可能會出現大幅波動，並且存在著巨大的風險，即您可能會在購買、出售、持有或投資數字貨幣及其衍生品時產生經濟損失。您使用幣趣服務，即表示您確認並同意：",
    "login.service.15p135": "(1)您了解與數字貨幣及衍生品交易相關的風險；(2)您承擔與使用幣趣服務和進行數字貨幣及其衍生品交易有關的所有風險；(3)幣趣對此等風險或不利結果不承擔任何責任。",
    "login.service.s7dfg5": "您以任何身份訪問、使用或嘗試使用幣趣服務，即表示您確認接受並同意受本使用條款的約束。 如果您不同意，請勿訪問幣趣或使用幣趣服務。",
    "login.service.c3ne47": "一. 定義",
    "login.service.j085eu": "1. 幣趣：",
    "login.service.eqrn6b": "指幣趣網站（域名包括但不限於",
    "login.service.4shf43": "）, 移動應用程序, 客戶端, 小程序及其他應用程序等為提供幣趣服務所創建的生態環境, 包括幣趣生態中獨立運營的各平台, 網站和客戶端, 上述各平台相關使用條款與本使用條款內容不一致的, 以該等平台各自的適用條款約定為準。",
    "login.service.00g906": "2. 幣趣經營者：",
    "login.service.73x183": "指經營幣趣的各主體, 包括但不限於提供幣趣服務並為該等服務負責的法人, 非法人組織, 團隊等。為了指代方便, 除另有說明外, 本使用條款中提及“幣趣”、“我們”時均特指幣趣經營者。",
    "login.service.uzv4wj": "本使用條款項下, 幣趣經營者可能根據幣趣的業務調整而發生變更, 變更後的幣趣經營者與您共同履行本使用條款並向您提供服務, 幣趣經營者的變更不會影響您本使用條款項下的權益。此外, 幣趣經營者的範圍還有可能因為提供新的幣趣服務而擴大, 如您繼續使用幣趣服務的, 視為您同意新增的幣趣經營者與您共同履行本使用條款。發生爭議時, 您需根據您具體使用的服務及對您權益產生影響的具體行為對象確定與您履約的主體及爭議相對方。",
    "login.service.v38c3d": "3. 幣趣服務：",
    "login.service.7c6kl2": "指幣趣基於互聯網和/或區塊鏈技術, 以包括幣趣網站、移動應用程序、客戶端等在內的各種形態(包括未來技術發展出現的新的服務形態)向您提供的各項服務。幣趣服務包括但不限於目前幣趣生態中的數字資產的交易平台、幣趣開發者平台、幣趣法幣通道、幣趣錢包等目前提供的服務以及未來幣趣新開設的其他服務。",
    "login.service.k1vqo1": "4. 幣趣平台規則：",
    "login.service.5q7nep": "指包括在所有幣趣已經發布及後續發布的全部規則、解讀、公告、聲明、同意書等內容, 以及在幫助中心、產品或服務流程內發布的各類規則、實施細則、產品流程說明、公告等。",
    "login.service.yselbt": "5. 用戶：",
    "login.service.gok88o": "指所有訪問、下載或使用幣趣, 或使用幣趣服務, 並符合幣趣規定的資格和條件的個人、機構或組織; 如針對開發者、經銷商、做市商、數字貨幣交易所等主體有其他協議的, 應遵照該等其他協議約定執行。",
    "login.service.2b3c9w": "6. 數字貨幣：",
    "login.service.u2y63j": "指基於區塊鏈技術和加密技術產生的, 以去中心化形式發行、管理的, 有一定價值的加密令牌、數字令牌或加密貨幣。",
    "login.service.xs3qe8": "7. 數字資產：",
    "login.service.68dd1w": "指數字貨幣、前者的衍生產品或其他種類的、有一定價值的電子化資產。",
    "login.service.802vo9": "8. 幣趣帳戶：",
    "login.service.ri0cf6": "指幣趣為用戶開設的記錄用戶在幣趣上使用幣趣服務、進行交易、資產變化、基礎資料的基礎性虛擬帳戶, 包括主帳戶和子帳戶。幣趣帳戶是用戶在幣趣享有和行使各項權利的一個依據。",
    "login.service.4qjnf9": "9. 幣幣交易：",
    "login.service.b7f8lc": "指以數字貨幣交換數字貨幣的現貨交易。",
    "login.service.r1277b": "10. 法幣交易：",
    "login.service.741co2": "指以數字貨幣交換法幣,或以法幣交換數字貨幣的現貨交易。",
    "login.service.dexjke": "指幣趣在與其用戶建立商業關係或進行交易之前實施的“了解您的客戶”過程。作為這個過程的一部分,幣趣可以做任何其認為必要的事情,以識別用戶,驗證他們的身份,審查和調查用戶。",
    "login.service.8c3q6q": "二. 總則",
    "login.service.9by514": "1.效力範圍",
    "login.service.psimg2": "a. 主體範圍",
    "login.service.c5y445": "本使用條款由您和幣趣經營者共同締結,對您和幣趣經營者均具有合同效力。",
    "login.service.2n1ual": "b. 補充條款",
    "login.service.55ef51": "由於數字貨幣生態和幣趣生態的高速發展,您與幣趣經營者締結的本使用條款並不能完整羅列並覆蓋您與幣趣的所有權利與義務,現有約定不能保證完全符合未來發展的需求。因此",
    "login.service.2s9m2r": ",隱私聲明、幣趣平台規則以及您與幣趣另行締結的各項協議均為本使用條款的補充條款,與本使用條款不可分割且具有同等法律效力。如您使用幣趣服務,視為您同意上述補充條款。",
    "login.service.n85y6u": "c. 本使用條款的變更",
    "login.service.vcsy4o": "幣趣保留隨時自行決定、修改或變更本使用條款的權利。幣趣將通過更新網頁()上修訂條款和修改本頁顯示的[最新修訂日期]通知變更內容。",
    "login.service.l36fy5": "對本使用條款的任何及所有修改或變更將在網站上公布或發送給用戶後立即生效。因此,您繼續使用幣趣服務,即表示接受修改後的協議和規則。如果您不同意對本使用條款進行的任何修改,請立即停止使用幣趣服務。幣趣希望您經常查看本使用條款,以確保您理解適用於您訪問和使用幣趣服務的條款和條件。",
    "login.service.t3oybn": "d. 禁止使用範圍 您訪問和使用幣趣服務,即表示您聲明並保證您未被列入任何貿易或經濟制裁名單(例如聯合國安全理事會制裁名單),未被OFAC (美國財政部外國資產管制辦公室)指定為“特別指定國民”,或未被列入美國商務部的“拒絕交易對象名單”。幣趣保留選擇市場和司法管轄區經營業務的權利,並可自行決定在某些國家限制或拒絕提供幣趣服務。",
    "login.service.6k888b": "2. 幣趣的性質",
    "login.service.i4iis8": "幣趣為幣趣生態鏈的一個重要組成部分,主要作為全球性在線數字資產交易平台,向用戶提供各類與數字資產有關的交易平台服務、融資服務、技術服務和其他服務。根據以下第3條進一步詳細規定,用戶必須在幣趣註冊並開立一個帳戶,並在開始交易前存入數字資產。用戶可以申請提取數字資產,但須遵守本使用條款規定的限制條件。",
    "login.service.9iuq7h": "儘管幣趣一直致力於維護幣趣服務上所發佈的信息的準確性,但是幣趣不能、也不保證通過幣趣服務提供的內容的準確性、適用性、可靠性、完整性、執行性或合適性,也不對您使用這些內容可能直接或間接造成的任何損失或損害承擔任何責任。有關幣趣服務的信息可能會變更,而不會發出通知,並且提供這些信息的主要目的是幫助用戶做出獨立決定。幣趣不提供任何類型的投資或咨詢建議,也不對幣趣或其他通訊媒介上的信息的使用或解釋負責。所有使用幣趣服務的用戶都必須了解數字資產交易存在風險。幣趣希望所有用戶謹慎行事,並在力所能及的範圍內負責任地進行交易。",
    "login.service.hhnuh3": "3. 幣趣帳戶註冊與要求",
    "login.service.3w11cr": "a. 註冊",
    "login.service.c1xch7": "所有使用者在使用幣趣服務之前必須在",
    "login.service.63f38r": "www.basicex.com/zh-CN/register申請註冊一個幣趣帳戶。在註冊幣趣帳戶時，您必須提供本第3段中所述的信息或幣趣要求的其他信息，并接受本使用條款、隱私聲明和其他幣趣平台規則。幣趣可以自行決定拒絕為您開立幣趣帳戶。您同意在開立幣趣帳戶時提供完整且準確的信息，並同意及時更新您向幣趣提供的任何信息，以維持信息的完整性和準確性。每一名使用者（包括自然人、企業或法人實體）在任何時候只能保留一個主帳戶。但經幣趣同意可在主帳戶項下開立一個或多個子帳戶。依據本使用條款或補充條款的規定，在您使用部分幣趣服務時，我們可能要求您另行開設獨立於幣趣帳戶的其他專項帳戶。除本使用條款或補充條款另有規定外，註冊、使用、保護和管理該等交易帳戶應同等適用本條和第六條的規則。",
    "login.service.j259mq": "b. 資格",
    "login.service.jis7mf": "通過註冊使用幣趣帳戶，您聲明並保證您（a）如為個人，已經年滿18歲或達到根據適用法律訂立具有約束力合約的法定年齡；（b）是具有訂立本使用條款的完全法律行為能力和授權的個人、法人或其他組織；（c）以前未被中止或取消使用幣趣平台或幣趣服務的資格；（d）您目前沒有幣趣帳戶；（e）您並非居住於、位於受限地區或從受限地區以其他方式訪問幣趣平台或幣趣服務，或以其他方式代表居住或位於受限地區的個人或法人實體行事。就本使用條款而言，“受限區域”應包括美國、馬來西亞、安大略（加拿大）以及幣趣運營商根據本使用條款不時指定的區域的其他地點。（f）如果您擔任法律實體的雇員或代理人，並以此身份代表其簽訂本使用條款，您聲明並保證您擁有約束該法律實體，並代表該法律實體訪問和使用幣趣平台和幣趣服務的所有必要權利和授權（g）您使用幣趣平台和幣趣服務不會觸犯您或您所代表的法律實體的所適用的所有法律法規，包括但不限於反洗錢、反貪腐、反恐怖主義融資等相關規定。",
    "login.service.9dyn6c": "c. 用戶身份驗證",
    "login.service.35ijpn": "您在幣趣註冊幣趣帳戶，即表示您同意提供為進行身份驗證而要求提供的個人信息。這些信息將確認用戶身份，用於發現在幣趣進行的洗錢、恐怖主義融資、詐騙和其他金融犯罪活動的線索，或幣趣聲明的其他合法目的。我們將根據我們發佈的《隱私聲明》收集、使用和共享這些信息。除了提供這些信息外，為了遵守全球數據存儲的行業標準，您同意允許我們在您的幣趣帳戶存續期內以及帳戶關閉後的5年內保留這些信息的記錄。您還授權我們直接或通過第三方開展核實您的身份或保護您和/或我們免受金融犯罪（如詐騙）侵害所必需的調查。 我們要求提供的身份驗證信息可能包括但不限於您的：姓名、電子郵件地址、聯繫信息、電話號碼、用戶名、政府簽發的身份證件、出生日期以及在帳戶註冊時收集的其他信息。在提供這些要求的信息時，您確認這些信息真實準確。",
    "login.service.1082u2": "註冊後，您必須保證信息真實、完整，同時如有任何變化，及時予以更新。如果有任何理由懷疑您提供的任何信息是錯誤的、不真實、過時的或不完整，幣趣有權向您發送通知，要求更正，直接刪除相關信息，以及（視情況而定）終止向您提供的全部或部分幣趣服務。如果無法通過所提供的聯繫資料聯繫到您，您應對使用幣趣服務期間產生的幣趣的任何損失或費用承擔全部責任。您在此確認並同意，如有任何變化，您有義務隨時更新所有信息。通過註冊帳戶，您特此授權幣趣直接或通過第三方開展幣趣認為必要的調查，以核實您的身份或保護您、其他用戶和/或幣趣免遭詐騙或其他金融犯罪侵害，並根據調查結果採取幣趣認為必要的行動。 您亦確認並同意您的個人信息可能會被披露給徵信機構和欺詐預防或預防金融犯罪部門，這些機構可能會對我們的調查做出全面回應。",
    "login.service.l0s8g6": "d. 帳戶使用要求",
    "login.service.881f65": "幣趣帳戶只能由帳戶註冊人使用。幣趣保留暫停、凍結或取消帳戶註冊人以外的人士使用幣趣帳戶的權利。如果您懷疑或發現未經授權使用您的用戶名和密碼的情況，應立即通知幣趣。幣趣對您或任何第三方（無論是否經您授權）使用您的幣趣帳戶造成的任何損失或損害概不負責。",
    "login.service.3im2qq": "e. 帳戶安全",
    "login.service.q6w78a": "幣趣一直致力於維護用戶委托資金的安全，並對幣趣服務實施了行業標準保護。但是，個別用戶的行為會產生風險。您同意將您的訪問憑證（如用戶名和密碼）視為機密信息，不將此等信息透露給任何第三方。您亦同意獨自負責采取必要的安全措施保護您自己的幣趣帳戶和個人信息的安全。",
    "login.service.x72wd6": "您應獨自負責保管您的幣趣帳戶和密碼，同時對幣趣帳戶下的所有往來活動負責，幣趣對經授權或未經授權使用您的帳戶憑證造成的任何損失或後果概不承擔任何責任，包括但不限於信息披露、信息發布、通過點擊網站同意或提交各種規則和協議、在線續展協議等。",
    "login.service.1aktk8": "您創建幣趣帳戶，即表示您特此同意：",
    "login.service.c1234z": "i.如果您知道任何人未經授權使用您的幣趣帳戶和密碼或有任何其他違反安全規則的行為，您將立即通知幣趣；",
    "login.service.8n81bu": "ii. 您将严格遵守币趣的全部安全、认证、交易、收费、退出机制或程序;",
    "login.service.jm2tyq": "iii. 且每次访问结束时，您均会采取适当的步骤从币趣上注销退出。",
    "login.service.57k158": "三. 币趣服务",
    "login.service.hy1i75": "经完成币趣账户注册及认证手续后，您可按照本使用条款（包括币趣平台规则和其他单项协议）的规定，使用各项币趣服务，包括但不限于在币趣开展币币交易、法币交易、合约交易、杠杆交易，使用币趣宝服务、持仓返利服务，取得币趣公布的市场数据、研究和其他信息，参加币趣举办的用户活动等。币趣有权：",
    "login.service.4ks6s7": "1. 自行决定开展、修改或终止提供任何币趣服务;",
    "login.service.7lwag3": "2. 以及根据有关币趣平台规则，允许或禁止部分用户使用任何币趣服务。",
    "login.service.io1y17": "币趣拥有决定哪些数字资产在平台上上市的独家权力，并可自行决定不时从平台上添加或删除数字资产。币趣还可以更改每个数字资产的订单大小。对于该等添加、删除或修改，币趣可以（但没有义务）提前通知用户，币趣不对用户承担与该等添加、删除或修改有关的责任。",
    "login.service.qge6s8": "1. 服务使用指南",
    "login.service.7dx5l7": "a. 许可",
    "login.service.7gjx0v": "在您持續遵守本使用條款明確規定的條款和條件的前提下，币趣賦予您撤銷、有限、免特許權使用費、非獨占、不可轉讓且不可分許可的許可，以通過您的電腦或互聯網兼容設備為自己個人/內部目的訪問和使用币趣服務。您不得將币趣服務用於轉售或商業用途，包括代表他人或其他實體進行交易。上述所有使用行為均明令禁止，並構成嚴重違反本使用條款。币趣服務的內容編排、格式、功能和訪問權限應由币趣自行規定。币趣特此保留本使用條款未明確授予的所有權利。因此，特此禁止您以本使用條款未明確授權的任何方式使用币趣服務。",
    "login.service.3l80yx": "本使用條款僅授予訪問和使用币趣服務的有限許可。因此，您特此同意在您使用币趣服務時，币趣不會向您或任何其他人轉讓币趣服務或币趣任何知識財產中的所有權或知識產權。所有文本、圖形、用戶界面、視覺界面、照片、聲音、工藝圖、計算機代碼(包括HTML代碼)、程序、軟件、產品、信息和文件，以及包含在服務中或通過币趣服務提供的任何內容的設計、結構、選擇、協調、表達、“界面外觀”和安排，均由币趣經營者或其成員、母公司、許可方或關聯方獨家所有、控制和/或許可。",
    "login.service.73o78r": "币趣將擁有您通過電子郵件、币趣服務或其他方式提供的，關於币趣或币趣服務的任何反饋、建議、想法或其他信息或材料(“反饋”)。您將特此反饋的所有權利、所有權和利益以及所有相關的知識產權轉讓給币趣。您將無權並特此放棄根據任何反饋或根據任何反饋做出的任何修改提出確認或賠償要求。",
    "login.service.wq2eiq": "b. 限制",
    "login.service.5hg230": "當您使用币趣服務時，您同意並承諾遵守以下規定：",
    "login.service.d88094": "i.在使用币趣服務期間，您開展的所有活動都將符合適用法律、法規的要求，本使用條款，以及币趣的各種指導原則；",
    "login.service.cp936b": "ii.您使用币趣服務不會違反公共利益、公共道德或其他人的合法利益，包括采取任何會干擾、扰乱、負面影響或禁止其他用戶使用币趣服務的行動；",
    "login.service.d336o6": "iii.您同意不使用這些服務進行市場操縱(例如拉抬價格、沖銷交易、自我交易、抢先交易、塞單、幌騙或分層下單，無論法律是否禁止)；",
    "login.service.17jbft": "iv.除非獲得币趣的書面同意，否則禁止以下使用币趣數據的商業行為：",
    "login.service.73ch03": "1)使用币趣報價或行情公告牌信息的交易服務。",
    "login.service.07yk77": "2)利用币趣的任何市場數據的數據馈送或數據流服務。",
    "login.service.31xcj3": "3)對從币趣獲得的市場數據收費或以其他方式進行商業盈利(包括通過廣告或轉介費用)的任何其他網站/應用程序/服務。",
    "login.service.d6kp8c": "v.未經幣趣事先書面同意,您不得修改、複製、複印、拷貝、下載、存儲、進一步傳輸、傳播、轉讓、拆解、廣播、發布、移除或更改任何版權聲明或標籤,許可、再許可、出售、鏡像、設計、出租、租賃、私人標籤、授予財產或財產的任何部分的擔保權益、創作其衍生作品或以其他方式利用其任何部分。",
    "login.service.x77d8j": "vi.您不得(i)使用任何“深層鏈接”、“頁面抓取”、“機器人”、“蜘蛛”或其他自動設備、程序、腳本、算法或方法,或任何類似或同等的手動程序來訪問、獲取、複製或監控財產的任何部分,或以任何方式複製或繞過幣趣服務的導航結構或演示,以獲取或試圖通過幣趣服務非有意提供的任何方式獲取任何材料、文檔或信息;(ii)試圖通過黑客攻擊、密碼“挖掘”或任何其他非法或禁止手段,未經授權訪問財產的任何部分或功能,或連接到幣趣服務或任何幣趣服務器或通過該服務提供的任何幣趣服務的任何其他系統或網絡;(iii)探測、掃描或測試幣趣服務或連接到財產的任何網絡的漏洞,違反幣趣服務或連接到幣趣服務的任何網絡上的安全或認證措施;(iv)反向查找、跟踪或尋求跟踪幣趣服務的任何其他用戶或訪問者的任何信息;(v)采取對幣趣服務或幣趣的系統或網絡或連接到幣趣服務的任何系統或網絡的基礎設施施加不合理或不成比例的大負載的任何行動;(vi)使用任何設備、軟件或例行程序,以干擾幣趣服務的正常運行或幣趣服務上進行的任何交易,或任何其他人對幣趣服務的使用;(vii)偽造抬頭、冒充他人或以其他方式操縱標識符,以掩蓋您的身份或您發送給幣趣的任何消息或傳輸的來源,或(viii)以非法方式使用幣趣服務。",
    "login.service.2zi5xe": "您訪問幣趣服務,即表示您同意幣趣有權調查任何違反本使用條款的行為,單方面確定您是否違反本使用條款,並在不徵得您同意或事先未通知的情況下采取行動以適用相關法規。此等行動的例子包括但不限於：",
    "login.service.hwd27r": "1.阻止和關閉訂單請求;",
    "login.service.1uw8ge": "2.凍結帳戶;",
    "login.service.57mj94": "3.向當局報告事件;",
    "login.service.7gb4x1": "4.公佈被指控的違規行為和已經采取的行動;",
    "login.service.ns15wc": "5.刪除您發佈的任何違規信息。",
    "login.service.86o0pb": "2. 法幣交易",
    "login.service.254qma": "在進行法幣交易前,您應單獨閱讀並同意與幣趣合作的各OTC平台的《用戶協議》並遵守《法律聲明》、《隱私政策》、《P2P用戶交易規則》等其他法幣交易相關的專項幣趣平台規則以及該等合作方的業務規則,並經完成幣趣賬戶註冊及認證手續後開設幣趣OTC平台賬戶(如適用)。",
    "login.service.2j5xk2": "四. 責任",
    "login.service.o8r6vj": "1. 保證免責聲明",
    "login.service.5sv6ck": "在適用法律允許的最大範圍內，幣趣服務、幣趣的材料以及由幣趣或代表幣趣提供的任何產品、服務或其他項目均是在“現狀”和“可用”的基礎上提供的，同時幣趣明確放棄，您亦放棄，所有任何種類的其他保證，無論是明示的還是默示的，包括但不限於對於適銷性、特定用途適合性、所有權或不侵權的保證，或者因履行過程、交易過程或交易中使用而產生的保證。在不限制上述規定的情況下，幣趣對網站、幣趣服務或幣趣材料的準確性、完整性、可靠性、有效性、無錯誤或無病毒或其他有害成分不做任何聲明或保證。幣趣不保證任何訂單會被執行、接受、記錄或保持未完成狀態。除本使用條款規定的明確聲明、協議或規則外，您在此確認並同意，您在使用和獲取幣趣服務方面沒有依賴任何其他書面或口頭聲明或協議。在不限制上述規定的情況下，您特此理解並同意幣趣對以下原因引起的或與之相關的任何損失或損害概不負責：(A)數字資產價格數據的不準確性、缺陷或遺漏;(B)此類數據傳輸中的任何錯誤或延遲;(C)此等數據的中斷;(D)幣趣的定期或不定期維護，以及由此產生的服務中斷、服務變更;(E)其他用戶的行為、不作為或違反本使用條款的行為造成的任何損害;(F)其他第三方的不法行為或非幣趣授權行為造成的任何損害；以及(G)幣趣發布的免責聲明、幣趣平台規則中提及的其他免責事項。如果被您居住的司法管轄區的適用法律所禁止，則本使用條款所載默示保證免責聲明可能不適用。",
    "login.service.6t2642": "2. 損害免責聲明和責任限制",
    "login.service.y4fip6": "在適用法律允許的最大範圍內，幣趣、其隸屬機構以及各自的股東、成員、董事、高級職員、員工、律師、代理人、代表、供應商或承包商在任何情況下均對因幣趣服務、幣趣服務的任何履行或不履行或由幣趣及其隸屬機構提供或代表幣趣提供的其他產品、服務或其他項目引起的任何附帶、間接、特殊、懲罰性、後果性或類似的損害或責任(包括但不限於數據、信息、收入、利潤或其他業務或財務利益的損失)概不負責，無論是根據合同、法規、嚴格責任還是其他理論，即使幣趣已經被告知存在損害的可能性，除非最終司法裁定此等損害是因幣趣的重大過失、欺詐、故意不當行為或故意違法行為造成的。一些司法管轄區不允許排除或限制附帶或後果性損害，因此上述限制可能不適用於您。儘管有上述規定，在任何情況下，幣趣、其隸屬機構及其各自的股東、成員、董事、高級職員、員工、律師、代理人、代表、供應商或承包商因幣趣或其關聯方提供或代表幣趣或起關聯方提供的服務、服務的任何履行或不履行、或任何其他產品、服務或其他項目而產生的責任，無論是根據合同、法規、嚴格責任還是其他理論，均不會超過在引起責任索賠的事件發生之前的十二個月內您根據本使用條款支付給幣趣的費用金額。",
    "login.service.85gi4c": "3. 賠償",
    "login.service.r4u256": "您同意賠償幣趣經營者、其隸屬機構、承包商、許可方及其各自的董事、高級職員、員工和代理人，並使它們免受因以下原因引致或與以下原因相關的任何索賠、訴訟、程序、調查、要求、訴訟、成本、費用和損害(包括律師費、罰款或任何監管機構收取的罰款)：(i)您使用幣趣服務或與幣趣服務相關的行為；(ii)您違反幣趣服務條款或我們執行幣趣服務條款；或(iii)您在使用幣趣服務期間違反任何適用法律、法規或任何第三方的權利。如果根據幣趣服務條款，您有義務賠償幣趣經營者、其隸屬機構、承包商、許可人及其各自的董事、高級職員、員工或代理人，幣趣將有權自行控制任何訴訟或程序，並確定幣趣是否希望和解，如果願意，基於什麼條款和解。",
    "login.service.9115hn": "五. 公告",
    "login.service.52wq75": "請注意，幣趣所有官方公告、新聞、推廣、都將發布在",
    "login.service.8a6d07": ". 用戶承諾將定期且及時地查閱這些資料。如果用戶因對公告的無知或疏忽而遭受個人損失，幣趣將不承擔任何賠償責任。",
    "login.service.1p53hd": "六. 協議終止",
    "login.service.r4u3d2": "1. 暫停帳戶",
    "login.service.j2710v": "您同意幣趣有權以任何理由立即暫停您的幣趣帳戶(以及關聯實體或關聯方實益擁有的任何帳戶)，凍結或鎖定所有此等帳戶中的數字資產或資金，並暫停您對幣趣的訪問，包括因懷疑任何此等幣趣帳戶違反了本使用條款、我們的隱私聲明或任何適用法律法規。您同意幣趣對您的幣趣帳戶的任何永久或臨時修改，或暫停或終止訪問全部或部分服務不負任何責任。幣趣有權保存和使用與幣趣帳戶有關交易的數據或其他信息。上述帳戶控制也可適用於以下情況：",
    "login.service.1t128d": "1.該幣趣帳戶受到政府程序、刑事調查或其他未決訴訟的約束；",
    "login.service.fl4tmq": "2.我們發現幣趣帳戶中有異常活動；",
    "login.service.bctfn1": "3.我們檢測到對幣趣帳戶存在非法訪問；",
    "login.service.s6168d": "4.根據法院命令或監管/政府機關的命令，我們有採取該等措施的必要性",
    "login.service.51s186": "2. 撤銷帳戶",
    "login.service.ia0o87": "如果發生以下任何事件，幣趣有權通過取消您的幣趣帳戶直接終止本使用條款，並有權但無義務永久凍結(取消)您就帳戶授予幣趣的授權，並撤銷相應的幣趣帳戶：",
    "login.service.30h447": "i.幣趣終止向您提供服務後；",
    "login.service.jsq3ta": "ii.您被指認再次以任何其他人的名義直接或間接註冊為幣趣用戶；",
    "login.service.2eoyw9": "iii.您提供的信息不真實、不準確、過時或不完整；",
    "login.service.883e2o": "iv.在修改幣趣服務條款時，您通過申請註銷幣趣帳戶或其他方式表示您不願意接受修改後的條款；",
    "login.service.h8v45g": "v. 您請求終止服務;",
    "login.service.407057": "vi. 幣趣認為應該終止服務的任何其他情況。",
    "login.service.k1qyya": "如果您的幣趣帳戶被終止，滿足數據保留標準的帳戶和交易信息將安全保存5年。此外，如在帳戶終止過程中有交易尚未完成，幣趣有權告知您的交易對方當時的情況。您同意用戶發起的帳戶退出（GDPR或其他同等法規下的擦除權）也將受到上述終止協議的約束。如果幣趣被告知您幣趣帳戶中的任何數字資產或資金被盜或並非您合法擁有，幣趣可以但沒有義務對受影響的資金和您的幣趣帳戶進行管理控制。如果確實需要對部分或全部資金或帳戶進行管理控制，則幣趣可以繼續持有，直到糾紛得到解決，並且幣趣可以接受的爭議解決的證據已經以幣趣可以接受的形式提供給幣趣。幣趣不會參與任何此類糾紛或糾紛的解決。您同意幣趣對任何此類管控，或您在任何管控期間無法提取數字資產或資金或執行交易，概不負責。",
    "login.service.uhx61q": "3. 帳戶終止後的剩餘資金",
    "login.service.g9g6kb": "除以下第4款規定外，一旦幣趣帳戶關閉/撤銷，幣趣帳戶上的所有餘額（包括欠幣趣的費用和債務）將立即支付給幣趣。向幣趣支付所有未清費用後（如果有），用戶將有5個工作日時間從帳戶中提取所有數字資產或資金。",
    "login.service.1u39if": "4. 帳戶因欺詐、違法或違反本使用條款而終止後的剩餘資金",
    "login.service.cgppoj": "在因欺詐調查、違法調查或違反本使用條款導致幣趣帳戶暫停/關閉的情況下，幣趣保留對幣趣帳戶中所有數字資產、資金和用戶數據/信息的完全保管權，並且這些數字資產、資金和用戶數據/信息可能會移交給政府機關。",
    "login.service.awbqxl": "5. 休眠帳戶",
    "login.service.5689e1": "儘管本第六條有任何規定，幣趣可提供書面通知，要求您在通知發出後30天內平倉所有未平倉頭寸，並從幣趣帳戶中撤回所有數字資產。如果您未能這樣做，幣趣可以在不事先通知您的情況下，根據其絕對酌情權：",
    "login.service.63idug": "(a) 將您的幣趣帳戶視為休眠帳戶；",
    "login.service.kq3xa5": "(b) 平倉任何幣趣產品的任何未平倉頭寸；",
    "login.service.5u3n69": "(c) 將數字資產轉換為不同類型的數字資產（例如，從 BTC 轉換為 USDT）。為免生疑問，幣趣經營者均不對您因該等轉換而產生的任何利潤損失、稅務義務或任何其他損失、損害或費用承擔責任；",
    "login.service.r0i5p3": "(d) 在幣趣認為有合理必要的情況下，將該等休眠帳戶（包括其中包含的任何數字資產）轉讓給幣趣運營商的附屬機構、任何第三方托管人或單獨的錢包。在發生此類轉移的情況下，您有權在滿足幣趣驗證要求的前提下，從其中檢索您的數字資產，包括完成 KYC；",
    "login.service.971852": "(e) 向幣趣經營者、其關聯公司或任何第三方收取休眠帳戶費用，以支付其維護資產的成本，該費用應按月直接從休眠帳戶中提取；",
    "login.service.7nnt2b": "(f) 隨時關閉休眠帳戶，幣趣不對您因關閉休眠帳戶而產生的任何損失、損害或費用承擔責任，除非幣趣存在欺詐或故意違約行為。這些休眠帳戶中的任何資產將按照上文第5(d)段轉移。休眠帳戶關閉後，您將無法重新激活該帳戶（即如果您希望繼續使用幣趣服務，您需要註冊一個新的幣趣帳戶）。",
	"login.service.8293z2": "七. 非財務建議",
	"login.service.tx7u2l": "幣趣不是您的經紀人、中介、代理或顧問，在您使用幣趣服務進行的任何交易或其他決策或活動中，幣趣與您不存在信託關係或者信託義務。幣趣向您提供的任何通訊或信息均不作為或者也不應視為或解釋為投資建議、財務建議、交易建議或任何其他類型的建議。除本使用條款另行規定外，所有交易均基於您的訂單說明的參數和公布的交易執行程序自動執行，您應根據個人投資目標、財務狀況和風險承受能力來確定任何投資、投資策略或相關交易是否適合您，並獨自承擔任何損失或責任。關於您的具體情況，您應該諮詢法律或稅務專業人士的意見。幣趣不向您推薦購買、獲取、出售或持有任何數字資產。在做出購買、出售或持有任何數字資產的決定之前，您應該進行尽職调查，并在做出任何投資決定之前諮詢您的財務顧問。幣趣對您根據幣趣提供的信息做出的購買、出售或持有數字資產的決定概不負責。",
	"login.service.0h2h9e": "八. 遵守當地法律",
	"login.service.s378n2": "用戶有責任遵守與其在當地使用幣趣服務有關的當地法律及其他適用於該用戶的法律法規。用戶還必須根據當地法律考慮稅務的所有方面，包括預扣、代收、報告和匯款給其相應的稅務機關。",
	"login.service.ouw125": "幣趣服務的所有用戶均確認並聲明其資金來源合法，並非產生於非法活動；用戶同意幣趣依據相關法律或政府命令，要求用戶提供或另行收集必要的信息和資料，以驗證其資金來源和用途的合法性。",
	"login.service.9s87ei": "幣趣秉承與全球執法機構合作的態度，並將毫不猶豫地扣押、凍結、終止法律授權注銷或調查的用戶帳戶和資金。",
	"login.service.qcqh4g": "九. 隱私聲明",
	"login.service.v41yvu": "訪問幣趣服務需要提交部分個人身份信息。 請閱讀",
	"login.service.9t9leu": "公佈的幣趣隱私聲明,獲取幣趣與收集和使用個人身份信息有關的指引總結。",
	"login.service.8v6f17": "十. 爭議解決：仲裁地點、仲裁、集體訴訟放棄",
	"login.service.183p3x": "請仔細閱讀本條款,因為它涉及放棄某些提起法律訴訟的權利,包括集體訴訟。",
	"login.service.4ot7r5": "1. 請求通知和爭議解決期。",
	"login.service.1schi9": "請先聯繫幣趣！幣趣希望在不訴諸正式法律程序的情況下解決您的問題。如果您與幣趣有爭議,請聯繫幣趣,我們會給您一個票號。幣趣將嘗試儘快在內部解決您的爭議。雙方同意以真誠的態度協商解決爭議(有關討論應保密,並受適用規則的約束,以保護和解討論不被用作任何法律程序中的證據)。",
	"login.service.eqegh1": "如果爭議無法令人滿意地解決,而您希望對幣趣提出法律索賠,則作為向幣趣發出的一種提前通知形式,您同意以書面形式在“索賠通知”中闡明此類索賠的基礎。索賠通知必須(1)描述索賠或爭議的性質和依據,(2)列出尋求的具體救濟,(3)提供原始票號,以及(4)包括您的幣趣帳戶郵箱。索賠通知應提交到您與幣趣通信中提供的電子郵件地址或超鏈接。在您向幣趣提供索賠通知後,幣趣或您可以根據下文本條第2段的規定將索賠通知中提及的爭議提交仲裁。為避免疑問,將爭議提交幣趣內部解決並向幣趣交付索賠通知是啟動仲裁程序(或任何其他法律程序)的先決條件。仲裁期間,您或幣趣提出的任何和解協議的金額不得向仲裁員披露。",
	"login.service.64y25g": "2. 仲裁協議及適用法律",
	"login.service.rsfkm3": "您與幣趣經營者同意,根據上文第1段,您與幣趣(和/或幣趣經營者)之間因本使用條款或以任何方式與幣趣(和/或幣趣經營者)作為幣趣服務用戶的關係(無論是基於合同、侵權、法規、欺詐、虛假陳述或任何其他法律理論)產生的任何爭議、索賠或爭議,以及索賠是否發生在本使用條款有效期內或終止之後)將由強制性的、最終的、有約束力的個人(而非集體)仲裁決定,仲裁協議的例外情況下的規定除外。您與幣趣經營者進一步同意,仲裁員將擁有對其自身管轄權作出裁決的排他性權力,包括但不限於對仲裁協議的存在、範圍或有效性,或對任何索賠或反索賠的可仲裁性提出的任何異議。仲裁比法庭訴訟更加非正式。",
	"login.service.w7cx49": "在仲裁中沒有法官或陪審團，法院對仲裁裁決的審查是有限制的。",
	"login.service.lopr8n": "比起在法庭上，可能會有更多有限的發現。仲裁員必須遵守本協議，並可以裁定與法院相同的損害賠償和救濟（包括，如適用，律師費），但仲裁員不得裁定對仲裁雙方以外的任何人有利的宣告性或禁令性救濟。本條款中規定的仲裁協議在本使用條款終止後仍然有效。",
	"login.service.e3271x": "仲裁規則。在仲裁通知提交時，或按本第十條修改時，仲裁應遵循屆時生效的HKIAC適用的仲裁規則（HKIAC 規則）。仲裁將由香港國際仲裁中心（HKIAC）進行管理。除非雙方另有約定，否則只能根據HKIAC規則指定一名仲裁員。任何仲裁將以英語進行。無論仲裁以何種方式進行，仲裁員應出具一份合理的書面決定，足以說明該決定和裁決（如有）所依據的基本發現和結論。",
	"login.service.4un6jx": "對任何仲裁裁決的判決可以由對該裁決的當事人（或當事人的財產）有管轄權的任何法院作出。提交時間：任何針對幣趣運營商的仲裁必須在主張索賠的一方首先知道或合理地應該知道導致索賠的行為、不作為或違約之日起一（1）年內通過提交仲裁請求開始；對於未在該期限內提出的任何索賠，不得要求任何救濟。這一年的時效包括上文本條第1段規定的內部爭端解決程序。對於未在該期限內提出的任何索賠，不得要求任何救濟。",
	"login.service.968c2f": "如果適用法律禁止一年的索賠時效期，則任何索賠必須在適用法律允許的最短時間內提出。",
	"login.service.c55747": "程序；通知：在上文第1段規定的爭議解決期屆滿後，任何一方如欲申請仲裁，必須根據HKIAC規則向HKIAC提交請求。如果我們要求對您申請仲裁，我們會通過您提供的電子郵件地址或郵寄地址通知您。您同意，發送到此電子郵件或郵寄地址的任何通知均應被視為在所有目的下有效，包括但不限於確定送達是否充分。您有義務確保與幣趣存檔的電子郵件地址和/或郵寄地址是最新和準確的。仲裁地點：仲裁地點應為香港。聆訊地點：除非雙方另有約定，任何親自進行仲裁聆訊的地點均應為香港。適用法律：本使用條款（包括本仲裁協議）應受香港法律管轄，並根據香港法律進行解釋。保密性：當事人同意對仲裁進行保密。仲裁的存在、仲裁中提供的任何非公開信息，以及仲裁中提交的任何文件、命令或裁決（合稱為“保密信息”）不得向任何非仲裁一方披露，但仲裁庭、HKIAC、雙方、其律師、專家、證人、會計師和審計師、保險公司和再保險公司，以及進行仲裁所需的任何其他人士除外。儘管有上述規定，一方仍可以披露保密信息，如果披露是為了履行法律義務，保護或追求法律權利，或在善意法律訴訟中執行或質疑裁決。本保密條款在本使用條款終止以及根據本使用條款提起的任何仲裁終止後仍然有效。",
	"login.service.2rqx41": "3. 放棄集體訴訟",
	"login.service.0pv0r8": "您和幣趣同意，任何關於本使用條款或者作為幣趣服務的用戶與幣趣的關係的索賠（無論是否基於合同、侵權、法令、欺詐、欺詐、或任何其他法律理論，以及索賠出現在本使用條款有效期內或終止之後）均應當以個人名義對另一方提出仲裁，而不是作為拟议的集體訴訟或代表訴訟的原告或成員。您和幣趣進一步同意，在適用法律允許的範圍內，放棄以集體、共同、代表或私人總檢察長(private attorney general action)訴訟方式提起、審理或仲裁此類索賠的任何權利。未經包括幣趣在內的各方同意，不得將單個仲裁整合或合併為一個仲裁。",
	"login.service.krs9o1": "4.修改",
	"login.service.4d7331": "幣趣保留就雙方仲裁協議更新、修改、修訂、暫停或對第十條進行任何未來變更的權利，但須遵守適用法律。您在此同意並認可，您有責任確保您對本節的理解是最新的。根據適用法律，您繼續使用您的幣趣賬戶將被視為您接受關於雙方仲裁協議的第十條的任何修改。您同意，如果您反對對第十條的修改，幣趣可能會在您的賬戶關閉之前屏蔽您的賬戶訪問。在這種情況下，修改前的使用條款將在您的賬戶關閉之前保持完全有效。",
	"login.service.whxc56": "5. 可分割性",
	"login.service.fmn834": "如果本使用條款的任何部分因任何原因或在任何程度上被判定為無效或不可執行，本條款的其余部分將繼續有效和可執行，而無效或不可執行的部分將在法律允許的最大範圍內生效。",
	"login.service.06omkq": "十一. 雜項",
	"login.service.fheews": "1. 獨立當事方",
	"login.service.3cu418": "在履行本使用條款時，幣趣是獨立的承包商，而不是您的代理人。本使用條款不應被解釋為雙方之間聯合、合資、合夥或特許經營的事實或證據。",
	"login.service.lcxy1q": "2. 完整協議",
	"login.service.p5k4hz": "本使用條款構成雙方關於使用幣趣服務的完整協議，並將取代雙方之前的所有書面或口頭協議。雙方之間不使用交易或其他常規做法或交易方法來修改、解釋、補充或變更本使用條款的條款。",
	"login.service.yyj7ca": "3. 解釋與修訂",
	"login.service.dmd8r1": "幣趣保留隨時修改、修訂、修改和/或更改本使用條款的權利。所有變更在網站上公布時立即生效。您有責任定期查看我們網站/應用程序上的相關頁面，以便確認本使用條款的當前版本。如果您不同意任何此類修改，您的唯一補救措施是終止您對幣趣服務的使用並註銷您的帳戶。您同意，除非本使用條款另有明確規定，否則幣趣不對您或任何第三方對幣趣服務的任何修改或終止，或暫停或終止您對服務的訪問承擔責任。",
	"login.service.8066u8": "4. 語言及翻譯",
	"login.service.yo9e6g": "幣趣可自行決定將本使用條款翻譯為英語以外的其他語言。您同意，任何此類翻譯僅為您的方便，如果在英文文本和任何翻譯文本之間出現任何歧義、差異或遺漏，應以英文文本為準。",
	"login.service.10ard2": "5. 不可抗力",
	"login.service.9x4rp6": "幣趣對於由於幣趣無法合理控制的任何原因或狀況而導致的任何延遲或未按照本使用條款的要求履約不承擔任何責任。",
	"login.service.2p413q": "6. 可分割條款",
	"login.service.ozdvyl": "如果本使用條款的任何部分被認定為無效或不可執行，此無效或不可執行不會影響本使用條款的其他條款，這些條款將保持完全有效，同時無效或不可執行的部分將在最大範圍內有效。",
	"login.service.0ja5sf": "7. 轉讓",
	"login.service.820ovr": "未經幣趣事先書面同意，您不得轉讓或讓與使用幣趣服務的任何權利或您在本使用條款項下的任何權利或義務，包括因法律施行或與控制權變更相關的權利或義務。幣趣可以全部或部分轉讓或讓與其在本使用條款項下的任何或全部權利或義務，無需通知或獲得您的同意或批准。",
	"login.service.99s52x": "8. 放棄",
	"login.service.70064o": "一方未要求任何規定的履行不影響該方此後任何時候要求履行的權利，同時，一方放棄追究對方違反本使用條款或者本適用條款任何規定的行為或違規行為並不構成該方對任何對方後續違約或違規行為的放棄或對條款本身的放棄。",
	"login.service.q6jrf8": "9. 第三方網站免責聲明",
	"login.service.21274u": "幣趣服務中指向第三方網站的任何鏈接並不意味著幣趣認可其中提供的任何產品、服務、信息和免責聲明，同時幣趣亦不保證其中所載信息的準確性。您使用該等第三方產品及服務受到損失的，幣趣不承擔任何責任。此外，由於幣趣無法控制第三方網站的使用條款或隱私政策，因此您應該仔細閱讀和理解這些政策。",
	"login.service.pn33fq": "10. Apple Inc. 相關事項",
	"login.service.6pk847": "如您使用任何 Apple Inc. 設備，通過幣趣服務參加的任何商業活動或者獎勵活動，由幣趣提供，均與 Apple Inc. 無關。",
	"login.service.q74557": "11. 聯繫方式",
	"login.service.t35sxh": "關於幣趣更多信息，您可以參考幣趣網站上的公司和許可信息。如果您對本使用條款有任何疑問，請隨時通過我們的客戶支持團隊聯繫幣趣要求澄清，網址"
}
