<template>
  <div class="empty-status">
    <img
      v-if="!activity"
      :src="getImage('noneMessage')"
      alt="noneMessage"
      :style="{ width, height }"
    />
    <img
      v-else
      :src="getImage('noData')"
      alt="noneMessage"
      :style="{ width, height, marginBottom: '40px' }"
    />
    <p :style="{ top: top }">{{
      message || $t('wallethistory.index.l4d233')
    }}</p>
  </div>
</template>

<script lang="ts" setup>
  import usePub from '@/utils/pub-use';

  const getImage = usePub;
  const props = defineProps({
    message: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '80px',
    },
    top: {
      type: String,
      default: '70%',
    },
    width: {
      type: String,
      default: '80px',
    },
    activity: {
      type: Boolean,
      default: false,
    },
  });
</script>

<style lang="less" scoped>
  .empty-status {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > p {
      position: absolute;
      text-align: center;
      width: 100%;
      // top: 70%;
      color: var(--color-text-3);
    }
  }
</style>
