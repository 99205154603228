<template>
  <a-modal
    v-model:visible="safeVisible"
    title-align="start"
    :hide-cancel="true"
    :bordered="false"
    :footer="false"
    :header="false"
    :closable="false"
    width="480px"
    :mask-closable="false"
    :body-style="{
      paddingLeft: '30px',
      paddingRight: '30px',
    }"
  >
    <div v-if="pinTrue" class="useerror">
      <div class="pinCode">
        <h1>{{ $t('safetydialog.index.178bx6') }}</h1>
        <icon-close @click="handleCloseModal('pin')" />
      </div>
      <div class="text1">
        <span class="tiptext">{{ $t('safetydialog.index.557581') }}</span>
        <span></span>
      </div>
      <div>
        <div ref="ref" v-bind="api.rootProps" class="input">
          <input class="inputPin" v-bind="api.getInputProps({ index: 0 })" />
          <input class="inputPin" v-bind="api.getInputProps({ index: 1 })" />
          <input class="inputPin" v-bind="api.getInputProps({ index: 2 })" />
          <input class="inputPin" v-bind="api.getInputProps({ index: 3 })" />
          <input class="inputPin" v-bind="api.getInputProps({ index: 4 })" />
          <input class="inputPin" v-bind="api.getInputProps({ index: 5 })" />
        </div>
      </div>
      <a-button
        class="btn"
        type="primary"
        :loading="isLoading"
        @click="onMyClickSetPin"
        >{{ $t('wallethistory.index.h55bzf') }}</a-button
      >
      <p @click="() => (otherMethod = true)">{{
        $t('safetydialog.index.2e2215')
      }}</p>
      <!-- <div class="tipcontext"
        >忘记PIN码？</div
      > -->
    </div>
    <div v-else class="content">
      <div class="left-pc">
        <div class="title">
          <h1>{{ $t('ver.number') }}</h1>
          <icon-close @click="handleCloseModal('ver')" />
        </div>
        <h3>{{
          verData.method === OTP_CODE
            ? $t(`ver.${verData.method}`)
            : $t(`ver.${verData.method}_CODE`)
        }}</h3>
        <a-form
          ref="formRef"
          :model="form"
          :style="{ width: '100%', marginBottom: '8px' }"
          @submit-success="handleToNext"
        >
          <a-form-item
            field="userName"
            hide-label
            :rules="[
              { required: true, message: $t('login.form.code.errMsg') },
              { validator: validateGetCode },
            ]"
            :validate-trigger="['change', 'input', 'blur']"
          >
            <a-input
              v-model="form.userName"
              :class="inputCol ? 'userFocus' : 'userInput'"
              @focus="() => (inputCol = true)"
              @blur="() => (inputCol = false)"
              @press-enter="handleToNext"
            >
              <template v-if="verData.method !== OTP_CODE" #suffix>
                <a-button
                  v-show="timeData.hideCode"
                  type="primary"
                  class="codeBox"
                  @click="handleSendCode"
                  >{{ $t('safetydialog.index.zj74uq') }}</a-button
                >
                <a-button
                  v-show="!timeData.hideCode"
                  disabled
                  class="codeBox"
                  type="primary"
                  >{{ timeData.count
                  }}{{ $t('safetydialog.index.193173') }}</a-button
                >
              </template>
            </a-input>
            <template #extra>
              <p v-if="verData.method === OTP_CODE" class="tip"
                >{{ $t('safetydialog.index.284434')
                }}{{ $t(`ver.${verData.method}`)
                }}{{ $t('safetydialog.index.uo4t31') }}</p
              >
              <p v-else class="tip"
                >{{ $t('safetydialog.index.284434')
                }}{{ $t(`ver.${verData.method}`) }}{{ verData.account
                }}{{ $t('safetydialog.index.4yhgsc') }}</p
              >
            </template>
          </a-form-item>
          <a-form-item hide-label>
            <a-button html-type="submit" :loading="isLoading" type="primary">{{
              $t('public.submit')
            }}</a-button>
          </a-form-item>
        </a-form>
        <p
          v-if="showSelectPin"
          class="goRegister"
          @click="() => (pinTrue = true)"
          >{{ $t('safetydialog.index.kh189w') }}</p
        >
        <p
          v-if="verList.length > 0"
          class="goRegister"
          @click="() => (otherMethod = true)"
          >{{ $t('safetydialog.index.2e2215') }}</p
        >
        <!-- <p class="goRegister">安全验证不可用?</p> -->
      </div>
    </div>
  </a-modal>
  <a-modal
    v-model:visible="otherMethod"
    :hide-cancel="true"
    :bordered="false"
    :footer="false"
    :header="false"
    :closable="false"
    width="400px"
    :body-style="{
      padding: '20px',
    }"
  >
    <div class="otherBox">
      <div class="header"
        ><icon-close @click="() => (otherMethod = false)"
      /></div>
      <p>{{ $t('safetydialog.index.pnpoko') }}</p>
      <div
        v-for="item in verList"
        :key="item.method"
        class="two-meth"
        @click="handleSelectMethod(item.method)"
      >
        <img :src="getImage(`login-${item.method}`)" :alt="item.method" />
        <p> {{ $t(`ver.${item.method}`) }}</p>
      </div>
    </div>
  </a-modal>
</template>

<script setup lang="ts">
  import { ref, reactive, toRefs, watch, computed, onBeforeUnmount } from 'vue';
  import { verificationList, verifyCode, sendCode } from '@/api/verification';
  import useCryptoJs from '@/hooks/cryptojs';
  import usePub from '@/utils/pub-use';
  import { normalizeProps, useMachine } from '@zag-js/vue';
  import * as pinInput from '@zag-js/pin-input';
  import { sendBingMsgApi } from '@/api/phone';
  import getArrDifference from '@/utils/diff';
  import { Notification } from '@arco-design/web-vue';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();
  const { encryptData } = useCryptoJs();
  const props = defineProps({
    dialogSafetyVisible: {
      type: Boolean,
      default: false,
    },
    ident: {
      type: String,
    },
  });
  const refProps = toRefs(props);
  const emits = defineEmits(['update:dialogSafetyVisible', 'confirmClick']); // 此处需写'update'
  const safeVisible = ref(false);
  watch(refProps.dialogSafetyVisible, (val, old) => {
    safeVisible.value = val;
  });
  watch(safeVisible, (val, old) => {
    emits('update:dialogSafetyVisible', val); // 此处的update亦要写
  });

  const validateGetCode = (value: any, callback: any) => {
    const regEmailPass = /^\d{6}$/;
    if (!regEmailPass.test(value)) {
      callback(t('safetydialog.index.wks78g'));
    } else {
      callback();
    }
  };

  // new
  const getImage = usePub;
  const form = reactive<any>({});
  const verData = reactive<any>({});
  const showLoading = ref<boolean>(true);
  const verList = reactive<any>([]);
  const pinTrue = ref(true);
  const otherMethod = ref<boolean>(false);
  const OTP_CODE = 'OTP_CODE'; // 谷歌验证器标识
  // 输入框动画
  const inputCol = ref(false);
  const timeData = reactive<any>({
    timer: null,
    TIME_COUNT: 60,
    hideCode: true,
  });
  // 封装PIN
  const [state, send] = useMachine(
    pinInput.machine({
      mask: true,
      id: String(props?.ident),
      placeholder: '',
      type: 'numeric',
      value: ['', '', '', '', '', ''],
      otp: true,
    })
  );
  const api = computed(() =>
    pinInput.connect(state.value, send, normalizeProps)
  );
  api.value.focus();
  // 关闭弹窗清空输入框
  const formRef = ref();
  const handleCloseModal = (v: string) => {
    safeVisible.value = false;
    if (v === 'pin') {
      api.value.clearValue();
    } else {
      formRef.value.resetFields();
      formRef.value.clearValidate();
      timeData.hideCode = true;
    }
  };

  // 获取支持的双因素验证列表
  const showSelectPin = ref(false);
  const getVerificationList = () => {
    verList.length = 0;
    verificationList().then((res: any) => {
      showLoading.value = false;
      if (res.code === '0000') {
        res.data.methods.forEach((item: any) => {
          if (item.method === 'PIN_CODE') {
            pinTrue.value = true;
            showSelectPin.value = true;
          } else {
            verList.push(item);
            pinTrue.value = false;
            showSelectPin.value = false;
          }
          if (item.method === res.data.last) {
            Object.assign(verData, item);
          }
        });
      }
    });
  };
  getVerificationList();

  // pin码的确认按钮
  const isLoading = ref(false);
  const onMyClickSetPin = () => {
    if (!api.value.isValueComplete) return;
    isLoading.value = true;
    verifyCode({
      sessionId: '', // pin码不传
      code: encryptData(api.value.valueAsString),
      method: 'PIN_CODE',
      source: props?.ident, // 以防钓鱼吗为例  === ident
    }).then((res: any) => {
      isLoading.value = false;
      if (res.code === '0000') {
        handleCloseModal('pin');
        emits('confirmClick', [res.data]);
      }
    });
  };
  // 发送验证码
  const sessionId = ref();
  const handleSendCode = () => {
    clearInterval(timeData.timer);

    // if (!timeData.timer) {
    timeData.count = timeData.TIME_COUNT;
    timeData.hideCode = false;
    timeData.timer = setInterval(() => {
      if (timeData.count > 0 && timeData.count <= timeData.TIME_COUNT) {
        timeData.count -= 1;
      } else {
        timeData.hideCode = true;
        clearInterval(timeData.timer);
        timeData.timer = null;
      }
    }, 1000);
    // }
    sendCode({
      receiverType: verData.method,
      source: props?.ident,
    }).then((res: any) => {
      if (res.code === '0000') {
        sessionId.value = res.data;
      }
    });
  };
  onBeforeUnmount(() => {
    clearInterval(timeData.timer);
  });

  // 安全验证的确认按钮
  const handleToNext = () => {
    // if (!sessionId.value) {
    //   Notification.error({
    //     content: t('resigter.verification.w175qb'),
    //     duration: 3000,
    //   });
    //   return;
    // }
    isLoading.value = true;
    verifyCode({
      sessionId: sessionId.value,
      code: form.userName,
      method: verData.method,
      source: props?.ident,
    }).then((res: any) => {
      isLoading.value = false;
      if (res.code === '0000') {
        handleCloseModal('ver');
        emits('confirmClick', [res.data]);
      }
    });
  };
  // 切换验证方式
  const handleSelectMethod = (v: any) => {
    if (v !== 'PIN_CODE') {
      pinTrue.value = false;
    } else {
      pinTrue.value = true;
      timeData.hideCode = true;
    }
    otherMethod.value = false;
    form.userName = '';
    const result = verList.filter((item: any) => item.method === v);
    Object.assign(verData, result[0]);
  };

  defineExpose({
    getVerificationList,
  });
</script>

<style lang="less" scoped>
  .useerror {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p,
    .tipcontext {
      width: 100%;
      display: flex;
      justify-content: left;
      cursor: pointer;
      color: rgba(16, 147, 255, 1);
    }
    .tipcontext {
      margin: 0 4px 24px 4px;
    }
    .pinCode {
      width: 100%;
      padding: 0 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .arco-icon-close {
        cursor: pointer;
        font-size: 18px;
        color: var(--color-neutral-8);
      }
    }
    .text1 {
      margin-bottom: 0px;
      font-size: 12px;
      color: rgba(252, 165, 43, 1);
      width: 100%;
      padding: 0 4px;
      display: flex;
      justify-content: space-between;
      .tiptext {
        color: var(--color-neutral-8);
      }
      span:nth-child(2) {
        cursor: pointer;
      }
    }
    .input {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .inputPin {
        width: 55px;
        height: 55px;
        line-height: 55px;
        text-align: center;
        margin: 18px 6px 45px 6px;
        border: none;
        background: var(--color-fill-2);
        border-radius: 5px;
      }
      .inputPin:focus {
        border: 1px solid rgb(var(--arcoblue-5));
        outline: none;
      }
    }
    .btn {
      margin-top: -20px;
      width: 100%;
      height: 48px;
      font-size: 16px;
      border-radius: 5px;
      margin-bottom: 15px;
    }
  }
  .content {
    padding: 0 20px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center; // 需要改
    align-items: center;
    .left-pc {
      width: 384px;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .arco-icon-close {
          cursor: pointer;
          font-size: 18px;
          color: var(--color-neutral-8);
        }
      }
      p {
        color: var(--color-neutral-8);
      }
      .tip {
        margin: 0 0 28px 0;
      }
      .userInput,
      .userFocus {
        width: 100%;
        height: 48px;
        background: var(--color-bg-1);
      }
      button {
        width: 100%;
        height: 48px;
      }
      .userFocus {
        border: 1px solid rgba(16, 147, 255, 1);
      }
      .userInput {
        border: 1px solid var(--color-neutral-2);
      }
      h1 {
        margin-bottom: 32px;
      }
      .goRegister {
        color: rgba(16, 147, 255, 1);
        cursor: pointer;
        // margin-top: 10px;
        margin: 8px 0;
      }
    }
    .right-qrCode {
      width: 180px;
    }
    .codeBox {
      width: 120px;
      height: 100%;
      cursor: pointer;
      position: relative;
      left: 12px;
      border-radius: 3px;
    }
  }
  .otherBox {
    width: 100%;
    padding-bottom: 30px;
    .header {
      text-align: right;
      .arco-icon-close {
        font-size: 20px;
        cursor: pointer;
        color: var(--color-neutral-8);
      }
    }
    & > p {
      font-size: 22px;
      font-weight: 550;
      margin: 0;
    }
    .two-meth {
      display: flex;
      align-items: center;
      padding-left: 20px;
      width: 100%;
      height: 60px;
      background: var(--color-fill-2);
      border-radius: 4px;
      margin-top: 12px;
      cursor: pointer;
      &:hover {
        background: var(--color-fill-3);
      }
      img {
        width: 30px;
        height: 30px;
        margin-right: 12px;
      }
      p {
        font-weight: 550;
      }
    }
  }
</style>
