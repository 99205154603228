<template>
  <a-modal
    v-model:visible="safeVisible"
    title-align="start"
    :hide-cancel="true"
    :bordered="false"
    :footer="false"
    :header="false"
    :closable="false"
    :mask-closable="false"
  >
    <div class="content">
      <div class="left-pc">
        <div class="title">
          <h1>{{ $t('ver.number') }}</h1>
          <icon-close @click="handleCloseModal('ver')" />
        </div>
        <a-form
          ref="formRef"
          :model="form"
          :style="{ width: '100%', marginBottom: '8px' }"
          @submit-success="handleToNext"
        >
          <!-- 谷歌 -->
          <h3 v-if="OTPList.method === OTP_CODE && payType !== 'OTP_CODE'">{{
            $t(`ver.${OTPList.method}`)
          }}</h3>
          <a-form-item
            v-if="OTPList.method === OTP_CODE && payType !== 'OTP_CODE'"
            field="OTP_CODE"
            hide-label
            :rules="[
              { required: true, message: $t('login.form.code.errMsg') },
              { validator: validateGetCode },
            ]"
            :validate-trigger="['change', 'input', 'blur']"
          >
            <a-input
              v-model="form.OTP_CODE"
              :class="inputOTPCol ? 'userFocus' : 'userInput'"
              @focus="() => (inputOTPCol = true)"
              @blur="() => (inputOTPCol = false)"
            >
            </a-input>
            <template #extra>
              <p class="tip"
                >{{ $t('safetydialog.index.284434') }}{{ $t(`ver.${OTP_CODE}`)
                }}{{ $t('safetydialog.index.uo4t31') }}</p
              >
            </template>
          </a-form-item>
          <!-- 邮箱 -->
          <h3 v-if="emailList.method === 'EMAIL' && payType !== 'EMAIL'">{{
            $t(`ver.${emailList.method}_CODE`)
          }}</h3>
          <a-form-item
            v-if="emailList.method === 'EMAIL' && payType !== 'EMAIL'"
            field="EMAIL"
            hide-label
            :rules="[
              { required: true, message: $t('login.form.code.errMsg') },
              { validator: validateGetCode },
            ]"
            :validate-trigger="['change', 'input', 'blur']"
          >
            <a-input
              v-model="form.EMAIL"
              :class="inputEmailCol ? 'userFocus' : 'userInput'"
              @focus="() => (inputEmailCol = true)"
              @blur="() => (inputEmailCol = false)"
            >
              <template #suffix>
                <a-button
                  v-show="emailData.hideCode"
                  type="primary"
                  class="codeBox"
                  @click="handleSendCode('EMAIL')"
                  >{{ $t('safetydialog.index.zj74uq') }}</a-button
                >
                <a-button
                  v-show="!emailData.hideCode"
                  disabled
                  class="codeBox"
                  type="primary"
                  >{{ emailData.count
                  }}{{ $t('safetydialog.index.193173') }}</a-button
                >
              </template>
            </a-input>
            <template #extra>
              <p class="tip"
                >{{ $t('safetydialog.index.284434')
                }}{{ $t(`ver.${emailList.method}`) }}{{ emailList.account
                }}{{ $t('safetydialog.index.4yhgsc') }}</p
              >
            </template>
          </a-form-item>
          <!-- 手机号 -->
          <h3 v-if="phoneList.method === 'PHONE' && payType !== 'PHONE'">{{
            $t(`ver.${phoneList.method}_CODE`)
          }}</h3>
          <a-form-item
            v-if="phoneList.method === 'PHONE' && payType !== 'PHONE'"
            field="PHONE"
            hide-label
            :rules="[
              { required: true, message: $t('login.form.code.errMsg') },
              { validator: validateGetCode },
            ]"
            :validate-trigger="['change', 'input', 'blur']"
          >
            <a-input
              v-model="form.PHONE"
              :class="inputPhoneCol ? 'userFocus' : 'userInput'"
              @focus="() => (inputPhoneCol = true)"
              @blur="() => (inputPhoneCol = false)"
            >
              <template #suffix>
                <a-button
                  v-show="phoneData.hideCode"
                  type="primary"
                  class="codeBox"
                  @click="handleSendCode('PHONE')"
                  >{{ $t('safetydialog.index.zj74uq') }}</a-button
                >
                <a-button
                  v-show="!phoneData.hideCode"
                  disabled
                  class="codeBox"
                  type="primary"
                  >{{ phoneData.count
                  }}{{ $t('safetydialog.index.193173') }}</a-button
                >
              </template>
            </a-input>
            <template #extra>
              <p class="tip"
                >{{ $t('safetydialog.index.284434')
                }}{{ $t(`ver.${phoneList.method}`) }}{{ phoneList.account
                }}{{ $t('safetydialog.index.4yhgsc') }}</p
              >
            </template>
          </a-form-item>
          <p class="goRegister">{{ $t('safetydialog.verification.j6a316') }}</p>
          <!-- 确定按钮 -->
          <a-form-item hide-label>
            <a-button html-type="submit" :loading="isLoading" type="primary">{{
              $t('public.submit')
            }}</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </a-modal>
</template>

<script setup lang="ts">
  import { ref, reactive, toRefs, watch } from 'vue';
  import { verificationList, verifyCode, sendCode } from '@/api/verification';
  import usePub from '@/utils/pub-use';
  import { useI18n } from 'vue-i18n';

  const props = defineProps({
    dialogSafetyVisible: {
      type: Boolean,
      default: false,
    },
    ident: {
      type: String,
    },
    payType: {
      type: String,
      default: 'all',
    },
  });
  const { t } = useI18n();
  const refProps = toRefs(props);
  const emits = defineEmits(['update:dialogSafetyVisible', 'confirmClick']); // 此处需写'update'
  const safeVisible = ref(false);
  watch(refProps.dialogSafetyVisible, (val, old) => {
    safeVisible.value = val;
  });
  watch(safeVisible, (val, old) => {
    emits('update:dialogSafetyVisible', val); // 此处的update亦要写
  });

  const validateGetCode = (value: any, callback: any) => {
    const regEmailPass = /^\d{6}$/;
    if (!regEmailPass.test(value)) {
      callback(t('safetydialog.index.wks78g'));
    } else {
      callback();
    }
  };

  const getImage = usePub;
  const form = reactive<any>({});
  const OTP_CODE = 'OTP_CODE'; // 谷歌验证器标识
  // 输入框动画
  const inputOTPCol = ref(false);
  const inputEmailCol = ref(false);
  const inputPhoneCol = ref(false);
  // 获取支持的双因素验证列表
  const OTPList = reactive<any>({});
  const emailList = reactive<any>({});
  const phoneList = reactive<any>({});
  const allPayType = reactive<any>([]);
  const getVerificationList = () => {
    verificationList().then((res: any) => {
      if (res.code === '0000') {
        res.data.methods.forEach((item: any) => {
          if (item.method !== 'PIN_CODE') {
            allPayType.push(item.method);
          }
          if (item.method === OTP_CODE) {
            Object.assign(OTPList, item);
          }
          if (item.method === 'EMAIL') {
            Object.assign(emailList, item);
          }
          if (item.method === 'PHONE') {
            Object.assign(phoneList, item);
          }
        });
      }
    });
  };
  getVerificationList();

  // pin码的确认按钮
  const isLoading = ref(false);
  // 发送验证码
  const sessionIdList = reactive<any>({});
  const emailData = reactive<any>({
    timer: null,
    TIME_COUNT: 60,
    hideCode: true,
  });
  const phoneData = reactive<any>({
    timer: null,
    TIME_COUNT: 60,
    hideCode: true,
  });
  const handleSendCode = (v: string) => {
    if (v === 'EMAIL') {
      if (!emailData.timer) {
        emailData.count = emailData.TIME_COUNT;
        emailData.hideCode = false;
        emailData.timer = setInterval(() => {
          if (emailData.count > 0 && emailData.count <= emailData.TIME_COUNT) {
            emailData.count -= 1;
          } else {
            emailData.hideCode = true;
            clearInterval(emailData.timer);
            emailData.timer = null;
          }
        }, 1000);
      }
      sendCode({
        receiverType: 'EMAIL',
        source: props?.ident,
      }).then((res: any) => {
        if (res.code === '0000') {
          sessionIdList.EMAIL = res.data;
        }
      });
    } else if (v === 'PHONE') {
      if (!phoneData.timer) {
        phoneData.count = phoneData.TIME_COUNT;
        phoneData.hideCode = false;
        phoneData.timer = setInterval(() => {
          if (phoneData.count > 0 && phoneData.count <= phoneData.TIME_COUNT) {
            phoneData.count -= 1;
          } else {
            phoneData.hideCode = true;
            clearInterval(phoneData.timer);
            phoneData.timer = null;
          }
        }, 1000);
      }
      sendCode({
        receiverType: 'PHONE',
        source: props?.ident,
      }).then((res: any) => {
        if (res.code === '0000') {
          sessionIdList.PHONE = res.data;
        }
      });
    }
  };
  // 关闭弹窗清空输入框
  const formRef = ref();
  const errList = ref<any>([]);
  const handleCloseModal = (v: string) => {
    safeVisible.value = false;
    formRef.value.resetFields();
    formRef.value.clearValidate();
    errList.value.length = 0;
    emailData.hideCode = true;
    phoneData.hideCode = true;
  };

  // 安全验证的确认按钮
  const arrList = ref<any>([]);
  const emitsList = reactive<any>([]);
  const results = ref<any>([]);
  const handleToNext = async () => {
    arrList.value.length = 0;
    results.value = allPayType.filter((item: any) => item !== props.payType);
    const toAxios = (arr: any) => {
      arr = Array.from(new Set(arr));
      arr.forEach((item: string) => {
        verifyCode({
          sessionId:
            // eslint-disable-next-line no-nested-ternary
            item === 'EMAIL'
              ? sessionIdList.EMAIL
              : item === 'PHONE'
              ? sessionIdList.PHONE
              : '',
          code:
            // eslint-disable-next-line no-nested-ternary
            item === 'EMAIL'
              ? form.EMAIL
              : item === 'PHONE'
              ? form.PHONE
              : form.OTP_CODE,
          method: item,
          source: props?.ident,
        }).then((res: any) => {
          if (res.code === '0000') {
            arrList.value.push(item);
            emitsList.push(res.data);
            errList.value = errList.value.filter((err: any) => err !== item);
            if (arr.length === arrList.value.length) {
              emits('confirmClick', emitsList);
            }
          } else {
            errList.value.push(item);
          }
        });
      });
    };
    if (errList.value.length > 0) {
      toAxios(errList.value);
      return;
    }
    toAxios(results.value);
  };

  defineExpose({
    handleCloseModal,
  });
</script>

<style lang="less" scoped>
  @media (min-width: 769px) {
    .content {
      width: 440px;
    }
  }
  @media (max-width: 768px) {
    .content {
      width: @modalApp;
    }
  }
  .content {
    padding: 0 10px 30px 10px;
    margin: 0 auto;
    display: flex;
    justify-content: center; // 需要改
    align-items: center;
    .left-pc {
      width: 100%;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: -10px;
        height: 60px;
        .arco-icon-close {
          cursor: pointer;
          font-size: 18px;
          color: var(--color-neutral-8);
        }
      }
      p {
        color: var(--color-neutral-8);
      }
      .tip {
        margin: 0 0 28px 0;
      }
      .userInput,
      .userFocus {
        width: 100%;
        height: 48px;
        background: var(--color-bg-1);
      }
      button {
        width: 100%;
        height: 48px;
      }
      .userFocus {
        border: 1px solid rgba(16, 147, 255, 1);
      }
      .userInput {
        border: 1px solid var(--color-neutral-2);
      }
      h1 {
        margin-bottom: 32px;
      }
      h3 {
        margin-bottom: 6px;
      }
      .goRegister {
        color: rgba(16, 147, 255, 1);
        cursor: pointer;
        // margin-top: 10px;
        margin-bottom: 18px;
      }
    }
    .right-qrCode {
      width: 180px;
    }
    .codeBox {
      width: 120px;
      height: 100%;
      cursor: pointer;
      position: relative;
      left: 12px;
      border-radius: 3px;
    }
  }
  :deep(.arco-form-item) {
    margin-bottom: -20px !important;
  }
</style>
